/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Webinars from "../../../assets/images/v2/icon_webinars.svg";
import Grievance from "../../../assets/images/v2/icon_grievance-redressal.svg";
import onlinePayments from "../../../assets/images/v2/icon_online-payments.svg";
import { Link as RouterLink } from "react-router-dom";

const HeadingText = styled("div")({
  color: "#0A0A0A",
  backgroundColor: "transparent",
  padding: 1,
  textTransform: "uppercase",
  opacity: "0.8",
  fontWeight: "400",
  fontFamily: "inherit",
  fontSize: "30px",
});
const CourseCard = styled("div")({
  backgroundColor: "#f6e18f",
  paddingTop: 38,
  textAlign: "center",
  justifyContent: "center",
  border: "1px solid rgba(10, 10, 10, 0.1)",
  boxShadow: "0px 2px 4px -2px rgba(10, 10, 10, 0.2)",
  width: "100%",
  minHeight: "180px",
});
const Course = styled("div")({
  // border: "2px solid #fcc907",
  padding: "2px",
  boxShadow: "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",
});
const CourseType = styled("div")({
  color: "white",
  opacity: 0.8,
  textTransform: "uppercase",
  fontWeight: 500,
});
const CourseDuration = styled("div")({
  color: "white",
  opacity: 0.8,
  fontWeight: 500,
});
function Information() {
  return (
    <Container sx={{ mt: 0, mb: 3 }}>
      <div className="courses-container-v2">
        <div className="heading-container">
          <h2>programmes offered</h2>
        </div>
        <div className="courses-card-v2">
          <div className="row">
            <div className="col-md-4">
              <Link to="/academics/courses/ba-llb">
                <div className="trapezoid">
                  <div className="inner-content">
                    <h3>B.A LL.B</h3>
                    <h4>Honours</h4>
                    <h5>5 Year Course</h5>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-4">
              <Link to="/academics/courses/ba-llb">
                <div className="trapezoid">
                  <div className="inner-content">
                    <h3>B.B.A LL.B</h3>
                    <h4>Honours</h4>
                    <h5>5 Year Course</h5>
                  </div>
                </div>
              </Link>
            </div>{" "}
            <div className="col-md-4">
              <Link to="/academics/courses/ba-llb">
                <div className="trapezoid">
                  <div className="inner-content">
                    <h3>B.Com LL.B</h3>
                    <h4>Honours</h4>
                    <h5>5 Year Course</h5>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-4">
              <Link to="/academics/courses/unitary-llb">
                <div className="trapezoid">
                  <div className="inner-content">
                    <h3>unitary LL.B</h3>
                    {/* <h4>Honours</h4> */}
                    <h5>3 Year Course</h5>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-4">
              <Link to="/academics/courses/llm">
                <div className="trapezoid">
                  <div className="inner-content">
                    <h3>LL.M</h3>
                    <h4>(Criminal Law)</h4>
                    <h5>2 Year Course</h5>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-4">
              <Link to="/academics/courses/llm">
                <div className="trapezoid">
                  <div className="inner-content">
                    <h3>LL.M</h3>
                    <h4>(Commercial Law)</h4>
                    <h5>2 Year Course</h5>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="row quick-links">
          <div className="col-md-4"></div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-3">
                {localStorage.getItem("token") ? (
                  <RouterLink to="/e-learning-and-question-papers">
                    <a href="">
                      <span>study materials</span>
                    </a>
                  </RouterLink>
                ) : (
                  <RouterLink to="/student/sign-in">
                    <a href="">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="122"
                        height="80"
                        viewBox="0 0 122 80"
                        fill="none"
                      >
                        <rect
                          y="79.3398"
                          width="74"
                          height="30"
                          rx="5"
                          transform="rotate(-90 0 79.3398)"
                          fill="#F6E763"
                        />
                        <rect
                          x="33"
                          y="79.3398"
                          width="74"
                          height="30"
                          rx="5"
                          transform="rotate(-90 33 79.3398)"
                          fill="#35BBED"
                        />
                        <rect
                          opacity="0.9"
                          x="94.4536"
                          y="79.2469"
                          width="73.6668"
                          height="29.9271"
                          rx="5"
                          transform="rotate(-116.804 94.4536 79.2469)"
                          fill="#F6E763"
                        />
                        <rect
                          x="65.5952"
                          y="22.1243"
                          width="29.9271"
                          height="5.06459"
                          transform="rotate(-26.804 65.5952 22.1243)"
                          fill="white"
                        />
                        <rect
                          x="69.125"
                          y="29.1105"
                          width="29.9271"
                          height="5.06459"
                          transform="rotate(-26.804 69.125 29.1105)"
                          fill="white"
                        />
                        <rect
                          x="88.6396"
                          y="67.739"
                          width="29.9271"
                          height="5.06459"
                          transform="rotate(-26.804 88.6396 67.739)"
                          fill="white"
                        />
                      </svg>
                      <span>study materials</span>
                    </a>
                  </RouterLink>
                )}
              </div>
              <div className="col-md-3">
                <RouterLink to="/webinars?type=Webinar">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="101"
                    height="74"
                    viewBox="0 0 101 74"
                    fill="none"
                  >
                    <rect x="41" y="62" width="20" height="12" fill="black" />
                    <rect
                      x="0.5"
                      y="0.5"
                      width="100"
                      height="61"
                      rx="9.5"
                      fill="white"
                      stroke="black"
                    />
                    <line y1="44.5" x2="101" y2="44.5" stroke="black" />
                    <circle cx="50.5" cy="16.5" r="7.5" fill="#35BBED" />
                    <path
                      d="M38 36.5C38 29.5964 43.5964 24 50.5 24C57.4036 24 63 29.5964 63 36.5V37H38V36.5Z"
                      fill="#35BBED"
                    />
                    <circle cx="14" cy="53" r="2" fill="black" />
                    <line x1="32" y1="73.5" x2="69" y2="73.5" stroke="black" />
                  </svg>
                  <span>webinars</span>
                </RouterLink>
              </div>
              <div className="col-md-3">
                <RouterLink to="/grievance">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="99"
                    height="66"
                    viewBox="0 0 99 66"
                    fill="none"
                  >
                    <circle cx="12.5" cy="12.5" r="12.5" fill="#F6E763" />
                    <circle cx="12.5" cy="53.5" r="12.5" fill="#35BBED" />
                    <rect x="33.5" y="5.5" width="65" height="14" rx="7" stroke="black" />
                    <rect x="33.5" y="47.5" width="65" height="14" rx="7" stroke="black" />
                    <circle cx="8" cy="8" r="1" fill="black" />
                    <circle cx="8" cy="51" r="1" fill="black" />
                    <circle cx="17" cy="8" r="1" fill="black" />
                    <circle cx="17" cy="51" r="1" fill="black" />
                    <path
                      d="M7 14C7.49529 15.0041 8.13782 16.2697 9 17C9.80273 17.68 11.476 18.5495 12.5 18.5C14.221 18.4167 16.4157 17.1987 17.3406 15.8975C17.6324 15.4869 18.372 14.5121 18.5 14"
                      stroke="black"
                      stroke-linecap="round"
                    />
                    <path
                      d="M18.5 60.5039C18.0047 59.4998 17.3622 58.2342 16.5 57.5039C15.6973 56.824 14.024 55.9544 13 56.0039C11.279 56.0872 9.08425 57.3052 8.15944 58.6064C7.8676 59.017 7.12803 59.9918 7 60.5039"
                      stroke="black"
                      stroke-linecap="round"
                    />
                    <path d="M42 12H73.0161" stroke="#C7C7C7" stroke-linecap="round" />
                    <path d="M41 54H61" stroke="#C7C7C7" stroke-linecap="round" />
                  </svg>
                  <span>grievance redressal</span>
                </RouterLink>
              </div>
              <div className="col-md-3">
                <a href="https://forms.eduqfix.com/snlawclg/add" rel="noreferrer" target="_blank">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="105"
                    height="57"
                    viewBox="0 0 105 57"
                    fill="none"
                  >
                    <rect x="13" width="92" height="50" rx="5" fill="#F6E763" />
                    <line x1="13" y1="12.5" x2="105" y2="12.5" stroke="black" />
                    <rect y="7" width="92" height="50" rx="5" fill="#35BBED" />
                    <path d="M8 43H39.0161" stroke="#37474F" stroke-linecap="round" />
                    <path d="M8 50H29" stroke="#37474F" stroke-linecap="round" />
                    <line y1="19.5" x2="92" y2="19.5" stroke="black" />
                  </svg>
                  <span>online payments</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Information;
