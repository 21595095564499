/* eslint-disable import/prefer-default-export */

import { gql } from "@apollo/client";

export const DOWNLOADS = gql`
  query DownloadsDatas($filterInput: FilterDownloadsInput!) {
    downloadDatas(filterInput: $filterInput) {
      totalCount
      downloads {
        id
        title
        file_type
        created_by
        status
        user {
          id
          name
          username
          password
        }
      }
    }
  }
`;
