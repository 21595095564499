import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Moment from "react-moment";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function PPTCard({ image, pptUrl, action }) {
  const handleClick = () => {
    window.open(pptUrl, "_blank");
  };
  return (
    <MKBox onClick={handleClick} sx={{ cursor: "pointer" }}>
      <Card className="ppt-Card">
        <MKBox position="relative" borderRadius="lg" mx={2} mt={0}>
          <MKBox component="img" src={image} alt="ppt-thumbnail" borderRadius="lg" />
          <MKBox
            borderRadius="lg"
            width="100%"
            position="absolute"
            left={0}
            top={0}
            sx={{
              backgroundImage: `url(${image})`,
              transform: "scale(0.94)",
              filter: "blur(12px)",
              backgroundSize: "cover",
            }}
          />
        </MKBox>
        <MKBox p={3} mt={-1} textAlign="center">
          <MKBox mt={1}>
            {/* <div className="events-date">
              <i className="fas fa-calendar" mr={4} />
            </div> */}
          </MKBox>
          {action.type === "external" ? (
            <MKTypography
              component={MuiLink}
              href={action.route}
              target="_blank"
              rel="noreferrer"
              variant="gradient"
              size="small"
              color="dark"
              sx={{ fontWeight: "bold" }}
            >
              {action.name}
            </MKTypography>
          ) : (
            <MKBox className="ppt-details">
              <MKTypography
                component={Link}
                to={action.route}
                variant="h6"
                fontWeight="bold"
                size="small"
                color={action.color ? action.color : "dark"}
              >
                {action.name}
              </MKTypography>
            </MKBox>
          )}
        </MKBox>
      </Card>
    </MKBox>
  );
}

PPTCard.propTypes = {
  thumbnail: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  pptUrl: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default PPTCard;
