/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Moment from "react-moment";
import { withStyles } from "@material-ui/core/styles";
import loader from "assets/images/loader.gif";
// Material Kit 2 React examples

import MKBox from "components/MKBox";
// import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";
import innerCover from "assets/images/banners/alumni.webp";
import { useQuery } from "@apollo/client";
import * as React from "react";
import { ONE_EVENT } from "../../graphql/query/events";

const styles = () => ({
  playIcon: {
    height: 38,
    width: 38,
  },
});

function EventVideos() {
  const [eventvideo] = React.useState(window.location.pathname.split("/")[2]);
  const [eventsDatas, setEventsDatas] = React.useState([]);
  const { loading, error, data } = useQuery(ONE_EVENT, {
    variables: {
      oneEventId: parseFloat(eventvideo),
    },
  });

  React.useEffect(() => {
    if (loading === false) {
      if (error) {
        setEventsDatas([]);
      } else {
        setEventsDatas(data.oneEvent);
      }
    }
  }, [data, loading, error]);
  return (
    <>
      <MKBox bgColor="white">
        <MKBox
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${innerCover})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
          className="innerCover"
        >
          <Container>
            <Grid
              container
              item
              xs={12}
              lg={8}
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              sx={{ mx: "auto", textAlign: "center" }}
            >
              <MKTypography
                variant="h1"
                color="white"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Events
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
        <MKBox component="section" py={{ xs: 6, sm: 12 }}>
          <Container>
            {loading ? (
              <img alt="loader" src={loader} className="loader" />
            ) : (
              <Grid container item xs={12} justifyContent="center" mx="auto">
                {/* <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
                  <MKAvatar
                    src={eventsDatas.thumbnail_image}
                    alt="Burce Mars"
                    size="xxl"
                    shadow="xl"
                  />
                </MKBox> */}
                <Grid container justifyContent="center" py={2}>
                  <Grid item xs={12} md={12} mx={{ xs: "auto", sm: 6, md: 1 }}>
                    <MKBox display="flex" justifyContent="center" alignItems="center" mb={1}>
                      <MKTypography variant="h3">{eventsDatas.event_name}</MKTypography>
                    </MKBox>
                    <Grid>
                      <MKTypography className="text-j" fontWeight="normal" color="text">
                        {eventsDatas.description}
                      </MKTypography>
                    </Grid>
                    {eventsDatas.length !== 0 && eventsDatas.vedio_link ? (
                      <Grid display="flex" justifyContent="center" mt={2}>
                        <Grid item xs={12} lg={4}>
                          <iframe
                            title="video"
                            id="video"
                            width="380"
                            height="240"
                            src={`https://www.youtube.com/embed/${eventsDatas.vedio_link}`}
                            frameBorder="0"
                            allow="accelerometer, autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            className="webinarPlayer"
                          />
                        </Grid>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Container>
        </MKBox>
      </MKBox>
    </>
  );
}

export default withStyles(styles)(EventVideos);
