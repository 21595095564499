/* eslint-disable import/prefer-default-export */

import { gql } from "@apollo/client";

export const CONGRATULATIONS = gql`
  query Congratulations($congratulationsFilter: CongratulationsFilterInput!) {
    congratulations(congratulationsFilter: $congratulationsFilter) {
      totalCount
      congratulations {
        id
        type
        image
        title
      }
    }
  }
`;
