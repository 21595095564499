/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";
import { Link } from "react-router-dom";
// prop-types is library for typechecking of props
import PropTypes from "prop-types";
import Moment from "react-moment";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function WebinarCard({ image, date, action }) {
  return (
    <Link to={action.route}>
      <Card>
        <MKBox position="relative" borderRadius="lg" mx={2} mt={0}>
          <MKBox
            component="img"
            src={image}
            alt="img"
            borderRadius="lg"
            width="100%"
            position="relative"
            zIndex={1}
            sx={{ objectFit: "cover" }}
            className="webinarCard"
          />
          <MKBox
            borderRadius="lg"
            width="100%"
            position="absolute"
            left={0}
            top={0}
            sx={{
              backgroundImage: `url(${image})`,
              transform: "scale(0.94)",
              filter: "blur(12px)",
              backgroundSize: "cover",
            }}
          />
        </MKBox>
        <MKBox p={3} mt={-1} textAlign="center">
          {date && date != 'Invalid date' ?
            <MKBox mt={1}>
              <div className="events-date">
                <i className="fas fa-calendar" mr={4} /> <Moment format="D/MMM/YYYY">{date}</Moment>
              </div>
            </MKBox> : null}
          {action.type === "external" ? (
            <MKTypography
              component={MuiLink}
              href={action.route}
              target="_blank"
              rel="noreferrer"
              variant="gradient"
              size="small"
              color="dark"
              sx={{ fontWeight: "bold" }}
            >
              {action.name}
            </MKTypography>
          ) : (
            <MKBox className="webinar-details">
              <MKTypography
                component={Link}
                to={action.route}
                variant="h6"
                fontWeight="bold"
                size="small"
                color={action.color ? action.color : "dark"}
              >
                {action.name}
              </MKTypography>
            </MKBox>
          )}
        </MKBox>
      </Card>
    </Link>
  );
}

// Typechecking props for the DefaultReviewCard
WebinarCard.propTypes = {
  image: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default WebinarCard;
