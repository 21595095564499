import * as React from "react";
import sign from "../../../assets/images/sign.png";
import TestimonialImg from "../../../assets/images/v2/testmonials-group.webp";
import Slider from "react-slick";
import Nlist from "../../../assets/images/logos/n-lsit.png";
import SCC from "../../../assets/images/logos/scc.png";
import Manupatra from "../../../assets/images/logos/manupatra.png";

const logos = [
  { src: Nlist, href: "https://nlist.inflibnet.ac.in/" },
  { src: SCC, href: "https://www.scconline.com/" },
  { src: Manupatra, href: "https://www.manupatrafast.com/?t=desktop" },
  
];

function Testimonials() {
  const settings = {
    dots: false,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <>
      <div className="container" id="testimonial">
      <div className="heading-container1">
        <h1 className="heading">testimonials</h1>
        <div className="yellow-line"></div>
      </div>
        <div className="row content-section g-5">
          <div className="col-md-3">
            <img src={TestimonialImg} className="img-fluid" alt="SNLC" />
          </div>
          <div className="col-md-9">
            <div className="heading-section">
              <h5>Honourable Mr. Justice V. R. Krishna Iyer</h5>
              <h6>Retired Judge, Supreme Court of India</h6>
            </div>
            <p className="py-3">
              "Law lives awfully in the villages of India. That is why I am proud that this law
              College in the rural areas of Poothotta serves the rule of law better than big Law
              Colleges in the City. You have a fine library which is a great asset for the rural
              community. If the villages become law conscious and the villagers become aware of the
              law of the country Law India becomes a powerful legal community and united fraternity.
              I wish the Poothotta Law College a great future ahead and convert the rural wonder of
              Poothotta a strong legal power which will guardian the integrity and humanity. Of
              course of the divinity of India that is Bharat. Not the verbal vigour of the
              Constitution but the paramount power of the clauses of the Constitution that makes the
              country strong and its people a truly socialist democratic Republic. I will be glad to
              be the Chief Patron of your College. But emphatically insist that you will sacrifice
              all your strength for the paramount purpose of making India the great country you have
              inherited from your ancestors."
            </p>
            <img src={sign} className="sign" alt="" />
          </div>
        </div>
      </div>
      <div className="logo-slider-container">
        <Slider {...settings}>
          {logos.map((logo, index) => (
            <a
              key={index}
              href={logo.href}
              target="_blank"
              rel="noopener noreferrer"
              className="logo-box"
            >
              <img src={logo.src} alt="" />
            </a>
          ))}
        </Slider>
      </div>
    </>
  );
}

export default Testimonials;
