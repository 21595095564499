/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { Grid, Card, Box, TextField, Alert, AlertTitle } from "@mui/material";

// Material Kit 2 React components
import MKBox from "components/MKBox";

import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { styled } from "@mui/material/styles";
import innerCover from "assets/images/banners/banner-contact.webp";
import * as Yup from "yup";
import { useFormik } from "formik";
// Routes
import { REGISTER_ALUMINI } from "graphql/mutation/alumini";
// Image
import { useMutation } from "@apollo/client";
import { useState } from "react";
import SnackbarAlert from "../../common/SnackbarAlert";
import { fi } from "date-fns/locale";
import AlumniQR from '../../../assets/images/alumni_qr.jpg';

function AluminiForm() {
  const [success, setSuccess] = useState(false);
  const [showSnackBarAlert, setShowSnackBarAlert] = useState({
    status: false,
    type: "success",
    message: null,
  });
  const ErrorMessage = styled(Box)(
    () => `
        color: red;
        font-size:15px
        
    `
  );
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-])|(\\([0-9]{2,3}\\)[ \\-])|([0-9]{2,4})[ \\-])?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    degree: Yup.string().required("Degree is required"),
    year: Yup.string().required("Year is required"),
    email: Yup.string().email("Invalid email address").required("Email is required"),
    phone: Yup.string()
      .required(" Phone Number is required")
      .matches(phoneRegExp, "Phone Number is not valid")
      .test("len", "10 digits phone Number is required", (value) =>
        value ? value.length === 10 : null
      ),
    address: Yup.string().required("Address is required"),
    designation: Yup.string().required("Designation is required"),
    // file: Yup.mixed().required("File is required"),
    file: Yup.mixed()
      .required("File is required")
      .test(
        'fileType',
        'Please upload jpeg/jpg/png file',
        (value) => {
          console.log('valuevalue', value)
          if (!value) return true; // No file provided, validation passes
          const supportedFormats = ['image/jpeg', 'image/jpg', 'image/png']; // Example supported file formats
          return supportedFormats.includes(value.type);
        }
      ).test(
        'FILE_SIZE',
        'Uploaded file is too big.Max size is 60Kb',
        (value) => !value || (value && value.size <= 60000)
      ),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      degree: "",
      year: "",
      email: "",
      phone: "",
      address: "",
      paymentId: "",
      file: "",
      designation: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      const { name, degree, year, email, phone, address, paymentId, file, designation } = values;
      console.log("valuesvalues", values);

      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        aluminiForm({
          variables: {
            registerInput: { name, degree, year, email, phone, address, paymentId, file: reader.result, designation }
          },
        });
      };

      resetForm();
    },
  });

  const [aluminiForm] = useMutation(REGISTER_ALUMINI, {
    onCompleted: (data) => {
      setSuccess(true);

      // setShowSnackBarAlert({
      //   status: true,
      //   type: "success",
      //   message: "Alumni registration successfully completed.",
      // });
    },
    onError: (error) => {
      setShowSnackBarAlert({
        status: true,
        type: "error",
        message: error.message,
      });
    },
  });

  const fileInputChange = (e) => {
    formik.setFieldValue("file", e.target.files[0]);
  };
  return (
    <>
      <SnackbarAlert
        showSnackBarAlert={showSnackBarAlert}
        setShowSnackBarAlert={setShowSnackBarAlert}
      />
      <MKBox
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${innerCover})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
        className="innerCover"
      >
        <MKTypography
          variant="h1"
          color="white"
          sx={({ breakpoints, typography: { size } }) => ({
            [breakpoints.down("md")]: {
              fontSize: size["2xl"],
            },
          })}
        >
          Alumni Registration Form
        </MKTypography>
      </MKBox>
      <Card
        sx={{
          p: { xs: 3, lg: 8 },
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        {success ? (
          <MKBox mt={4} textAlign="center">
            <Alert severity="success" variant="filled" fullWidth>
              <AlertTitle>Success</AlertTitle>
              Alumni registration successfully completed.
            </Alert>
          </MKBox>
        ) : (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12} md={9}>
                <MKBox component="form" onSubmit={formik.handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        fullWidth
                        label="Name"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.name && Boolean(formik.errors.name)} />
                      <ErrorMessage>{formik.touched.name && formik.errors.name}</ErrorMessage>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <MKInput
                        fullWidth
                        label="Degree"
                        name="degree"
                        value={formik.values.degree}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.degree && Boolean(formik.errors.degree)} />
                      <ErrorMessage>{formik.touched.degree && formik.errors.degree}</ErrorMessage>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        fullWidth
                        label="Year of Graduation"
                        name="year"
                        value={formik.values.year}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.year && Boolean(formik.errors.year)} />
                      <ErrorMessage>{formik.touched.year && formik.errors.year}</ErrorMessage>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        fullWidth
                        label="Designation"
                        name="designation"
                        value={formik.values.designation}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.designation && Boolean(formik.errors.designation)} />
                      <ErrorMessage>{formik.touched.designation && formik.errors.designation}</ErrorMessage>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        fullWidth
                        label="Email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.email && Boolean(formik.errors.email)} />
                      <ErrorMessage>{formik.touched.email && formik.errors.email}</ErrorMessage>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        fullWidth
                        label="Phone"
                        name="phone"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.phone && Boolean(formik.errors.phone)} />
                      <ErrorMessage>{formik.touched.phone && formik.errors.phone}</ErrorMessage>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        fullWidth
                        label="Address"
                        name="address"
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.address && Boolean(formik.errors.address)} />
                      <ErrorMessage>{formik.touched.address && formik.errors.address}</ErrorMessage>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        fullWidth
                        label="Payment ID(Optional)"
                        name="paymentId"
                        value={formik.values.paymentId}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        size="small"
                        name="file"
                        label="Attach Payment Screenshot | format: JPG/JPEG/PNG"
                        type="file"
                        onChange={(e) => {
                          fileInputChange(e);
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onBlur={formik.handleBlur}
                        id="standard-basic" />
                      <ErrorMessage>{formik.touched.file && formik.errors.file}</ErrorMessage>
                    </Grid>
                  </Grid>
                  <MKBox mt={4} textAlign="center">
                    <MKButton type="submit" color="warning">
                      Submit
                    </MKButton>
                  </MKBox>
                </MKBox>
              </Grid>
              <Grid item xs={12} md={3}>
                <img className="img-fluid" src={AlumniQR}/>
              </Grid>
            </Grid>
          </>
        )}
      </Card>
    </>
  );
}

export default AluminiForm;