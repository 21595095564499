// @mui material components
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { withStyles } from "@material-ui/core/styles";
import TablePagination from "@mui/material/TablePagination";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import loader from "assets/images/loader.gif";
import ExampleCard from "pages/Presentation/components/ExampleCard";
// Images
import innerCover from "assets/images/banners/banner-gallery.webp";
import { useQuery } from "@apollo/client";
import * as React from "react";
import { GALLERIES } from "graphql/query/gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const styles = (theme) => ({
  card: {
    display: "flex",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 151,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  playIcon: {
    height: 38,
    width: 38,
  },
});
function Gallery() {
  const [rowsPerPage, setRowsPerPage] = React.useState(12);
  const [totalCount, setTotalCount] = React.useState(0);
  const [galleryDatas, setGalleryDatas] = React.useState([]);
  const [galleryImages, setGalleryImages] = React.useState([]);
  const [offset, setOffset] = React.useState(0);
  const [isOpen, setIsOpen] = React.useState(0);
  const [status, setStatus] = React.useState(false);
  const [photoIndex, setPhotoIndex] = React.useState(0);
  const { loading, error, data } = useQuery(GALLERIES, {
    variables: {
      filterInput: {
        sortColumn: "sort_date",
        sortOrder: "desc",
        offset,
        limit: rowsPerPage,
        status: true,
      },
    },
  });

  React.useEffect(() => {
    if (loading === false) {
      if (error) {
        setGalleryDatas([]);
      } else {
        setGalleryDatas(data.getGalleries.galleries);
        setTotalCount(data.getGalleries.totalCount);
      }
    }
  }, [data, loading, error]);

  const handleChangePage = (event, newPage) => {
    setOffset(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setOffset(0);
  };
  const handleSubmit = (item) => {
    setIsOpen(true);
    setStatus(!status);
    if (item.galleryImages.length === 0) {
      galleryImages.push(item.thumbnail);
      setGalleryImages(galleryImages);
    } else {
      setGalleryImages(Object.values(item.galleryImages).map((galImg) => galImg.img_link));
    }
  };
  const modalCloseEvent = () => {
    setPhotoIndex(0);
    setGalleryImages([]);
    setIsOpen(false);
  };

  return (
    <>
      <MKBox
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${innerCover})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
        className="innerCover"
      >
        <MKTypography
          variant="h1"
          color="white"
          sx={({ breakpoints, typography: { size } }) => ({
            [breakpoints.down("md")]: {
              fontSize: size["2xl"],
            },
          })}
        >
          Gallery
        </MKTypography>
      </MKBox>
      <Card
        sx={{
          p: { xs: 4, lg: 8 },
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Container>
          {loading ? (
            <Grid container spacing={2}>
              <Grid item md={6} sx={{ display: "flex", justifyContent: "center" }}>
                <img alt="loader" src={loader} className="loader" sx={{ width: "60px" }} />
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={3}>
              {galleryDatas.length === 0 ? (
                <Grid container spacing={2}>
                  <Grid item md={12} mt={3} sx={{ display: "flex", justifyContent: "center" }}>
                    <MKTypography variant="h3">No Result Found</MKTypography>
                  </Grid>
                </Grid>
              ) : null}

              {galleryDatas.map((gallery) => (
                <Grid item xs={12} sm={6} lg={3} key={gallery.id}>
                  <ExampleCard
                    image={gallery.thumbnail}
                    name={gallery.title}
                    display="grid"
                    className="gallery-list"
                    onClick={() => handleSubmit(gallery)}
                  />
                  {gallery.galleryImages.length !== 0 ? (
                    <MKButton
                      type="button"
                      color="warning"
                      className="gallery-btn"
                      onClick={() => handleSubmit(gallery)}
                    >
                      See more
                    </MKButton>
                  ) : null}
                </Grid>
              ))}
              {!!isOpen && (
                <Lightbox
                  mainSrc={galleryImages[photoIndex]}
                  nextSrc={galleryImages[(photoIndex + 1) % galleryImages.length]}
                  prevSrc={
                    galleryImages[(photoIndex + galleryImages.length - 1) % galleryImages.length]
                  }
                  onCloseRequest={() => modalCloseEvent()}
                  onMovePrevRequest={() =>
                    setPhotoIndex((photoIndex + galleryImages.length - 1) % galleryImages.length)
                  }
                  onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % galleryImages.length)}
                />
              )}
            </Grid>
          )}
        </Container>
        {galleryDatas.length ? (
          <TablePagination
            rowsPerPageOptions={[12, 24, 36, 48, 60]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={offset}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
      </Card>
    </>
  );
}

export default withStyles(styles)(Gallery);
