import { gql } from "@apollo/client";

const SISTER_CONCERN = gql`
  query SisterConcern($sisterConcernFilter: SisterFilterInput!) {
    sisterConcern(sisterConcernFilter: $sisterConcernFilter) {
      totalCount
      sisterConcern {
        id
        institution_name
        description
        image
        website
        phone
        email
        createdBy
        createdAt
        sortOrder
        status
        user {
          id
          name
          username
          password
        }
      }
    }
  }
`;
export default SISTER_CONCERN;
