/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import Logout from "pages/common/Logout";
import loader from "assets/images/loader.gif";
// Routes

// Images
import bgImage from "assets/images/bg-college.webp";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";

import * as React from "react";
// Material Kit 2 React examples
// import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";
import { useQuery } from "@apollo/client";
import { SEMESTERS } from "../../../graphql/query/subjects";

function Semesters() {
  const [singleCourse] = React.useState(
    window.location.pathname.split("+")[1].replace(/%20/g, " ").toString()
  );
  const [type] = React.useState(
    window.location.pathname.split("/")[2].split("+")[0].replace("%20", " ").toString()
  );
  const [semesterDatas, setSemesterDatas] = React.useState([]);
  const { loading, error, data } = useQuery(SEMESTERS, {
    variables: {
      semesterInput: {
        course: singleCourse,
      },
    },
  });
  React.useEffect(() => {
    if (loading === false) {
      if (error) {
        setSemesterDatas([]);
      } else {
        // Create a copy of the array before sorting
        // const sorted = [...data?.getUniqueSemesters?.Subjects].sort((a, b) => {
        //   return a.semester - b.semester;
        // });
        setSemesterDatas([...data?.getUniqueSemesters?.Subjects].sort((a, b) => {
          return a.semester - b.semester;
        }));
      }
    }
  }, [data, loading, error]);

  return (
    <>
      <MKBox
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
        className="innerCover"
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Select Semester
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Logout />

        <MKBox component="section" py={12}>
          <Container>
            {loading ? (
              <img alt="loader" src={loader} className="loader" />
            ) : (
              <Grid container spacing={2} alignItems="center" justifyContent="center">
                {semesterDatas.map((sem) => (
                  <Grid item xs={12} lg={2} md={4} m={1} sx={{ borderRadius: 10 }}>
                    <Button>
                      <FilledInfoCard
                        variant="gradient"
                        color="light"
                        title={`Sem ${sem.semester}`}
                        action={{
                          type: "internal",
                          route: `/e-learning-and-question-paper+type/${type}/${singleCourse}/${sem.semester}`,
                        }}
                      />
                    </Button>
                  </Grid>
                ))}
              </Grid>
            )}
          </Container>
        </MKBox>
      </Card>
    </>
  );
}

export default Semesters;
