/* eslint-disable import/prefer-default-export */

import { gql } from "@apollo/client";

export const WEBINAR = gql`
  query Webinar($webinarFilter: WebinarFilterInput!) {
    webinar(webinarFilter: $webinarFilter) {
      totalCount
      webinar {
        id
        title
        description
        thumbnail_image
        date
        status
        content_type
        upload_ppt
        createdAt
        updatedAt
        user {
          id
          name
        }
        webinarVideos {
          id
          video_link
        }
      }
    }
  }
`;
