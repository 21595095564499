/* eslint-disable import/prefer-default-export */

import { gql } from "@apollo/client";

export const QUESTION_PAPER = gql`
  query QuestionPapers($questionFilter: QuestionPaperFilterInput!) {
    questionPapers(questionFilter: $questionFilter) {
      totalCount
      questionPaper {
        id
        subjectId
        type
        title
        year
        month
        file
        createdBy
        createdAt
        status
        subjects {
          id
          name
          code
          course
          semester
        }
      }
    }
  }
`;
