/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import Moment from "react-moment";
import { withStyles } from "@material-ui/core/styles";
import MKBox from "components/MKBox";
import parse from "html-react-parser";
// import MKAvatar from "components/MKAvatar";
import loader from "assets/images/loader.gif";
import MKTypography from "components/MKTypography";
import bgImage from "assets/images/banners/webinars.webp";
import { useQuery } from "@apollo/client";
import * as React from "react";
import { ONE_WEBINAR } from "../../graphql/query/webinarVideos";

const styles = () => ({
  playIcon: {
    height: 38,
    width: 38,
  },
});

function WebinarVideos() {
  const [webinarvideo] = React.useState(window.location.pathname.split("/")[2]);
  const [webinarDatas, setWebinarDatas] = React.useState([]);
  const { loading, error, data } = useQuery(ONE_WEBINAR, {
    variables: {
      oneWebinarId: parseFloat(webinarvideo),
    },
  });

  React.useEffect(() => {
    if (loading === false) {
      if (error) {
        setWebinarDatas([]);
      } else {
        setWebinarDatas(data.oneWebinar);
      }
    }
  }, [data, loading, error]);
  return (
    <>
      <MKBox bgColor="white">
        <MKBox
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
          className="innerCover"
        >
          <Container>
            <Grid
              container
              item
              xs={12}
              lg={8}
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              sx={{ mx: "auto", textAlign: "center" }}
            >
              <MKTypography
                variant="h1"
                color="white"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                {webinarDatas?.content_type}
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
        <Card
          sx={{
            p: { xs: 2, lg: 8 },
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          {loading ? (
            <Grid container spacing={2}>
              <Grid item md={6} sx={{ display: "flex", justifyContent: "center" }}>
                <img alt="loader" src={loader} className="loader" sx={{ width: "60px" }} />
              </Grid>
            </Grid>
          ) : (
            <Grid container item xs={12} justifyContent="center" mx="auto">
              <Grid item xs={12}>
                <MKBox display="flex" justifyContent="center" alignItems="center" mb={1}>
                  <MKTypography variant="h3" sx={{ textTransform: "capitalize" }}>
                    {webinarDatas.title}
                  </MKTypography>
                </MKBox>
                <MKBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mb={2}
                  className="text-j"
                >
                  <EventAvailableIcon /> &nbsp;
                  <Moment format="MMM-D-YYYY">{webinarDatas.date}</Moment>
                </MKBox>
              </Grid>

              <MKBox textAlign="center" mb={3}>
                <img src={webinarDatas.thumbnail_image} alt="Burce Mars" size="xxl" shadow="xl" />
              </MKBox>

              <Grid container xs={12} justifyContent="center" alignItems="center">
                <MKTypography className="text-j" fontWeight="normal" color="text">
                  {webinarDatas.description ? parse(webinarDatas.description) : ''}
                </MKTypography>
              </Grid>
              <Grid container spacing={4} mt={1} justifyContent="center">
                {webinarDatas.length !== 0 && webinarDatas.webinarVideos
                  ? webinarDatas.webinarVideos.map((webinar) => (
                    webinar.video_link ?
                      <Grid item xs={12} lg={4}>
                        <iframe
                          title="video"
                          id="video"
                          width="380"
                          height="240"
                          src={`https://www.youtube.com/embed/${webinar.video_link}`}
                          frameBorder="0"
                          allow="accelerometer, autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          className="webinarPlayer"
                        />
                      </Grid> : null
                  ))
                  : null}
              </Grid>
            </Grid>
          )}
        </Card>
      </MKBox>
    </>
  );
}

export default withStyles(styles)(WebinarVideos);
