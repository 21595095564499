/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
// Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
import innerCover from "assets/images/banners/banner-academics.webp";
import downloadIcon from "assets/images/folder.png";

function Coursellm() {
  return (
    <>
      <MKBox
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${innerCover})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
        className="innerCover"
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              LL.M (Criminal Law) &<br /> LL.M ( Commercial Law
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: { xs: 4, lg: 8 },
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <div className="container">
          <p className="text-j">
            Candidates who have passed the LL.B. examination (5 year / 3 year course) with a minimum
            of 50% marks from the Universities in Kerala or other Universities recognised by the
            Universities in Kerala as equivalent thereto are eligible for admission. No rounding off
            of percentage of marks to the nearest higher integer is permitted. Candidates appearing
            / appeared for the regular Final Year LL.B. examination can also apply for the Entrance
            Examination. Such candidates become eligible for admission only if they produce the
            Provisional / Degree Certificate of the qualifying examination and the mark lists of all
            parts of the qualifying examination at the time of admission.
          </p>
          <p className="text-j">
            Age : There is no upper age limit for admission to the LL.M Course.
          </p>
          <h1 className="text-center main-head">FEE DETAILS AS ON 18.05.2022</h1>
        </div>
        <div className="row my-4 d-flex justify-content-center">
          <div className="col-md-5 mb-3">
            <Card>
              <CardContent sx={{ xs: 4, lg: 8 }}>
                <h4 className="text-center">Merit Fee</h4>
                <ul className="text-number">
                  <li>I year - Rs 58500</li>
                  <li>II - Rs 46000</li>
                </ul>
              </CardContent>
            </Card>
          </div>
          <div className="col-md-5">
            <Card>
              <CardContent sx={{ xs: 4, lg: 8 }}>
                <h4 className="text-center">Management Fee</h4>
                <ul className="text-number">
                  <li>I Year - Rs 68500</li>
                  <li>II Year - Rs 56000</li>
                </ul>
              </CardContent>
            </Card>
          </div>
        </div>
        <div className="container">
          <h1 className="text-center main-head mb-4">Syllabus </h1>
        </div>
        <div className="row">
          <div className="col-md-4 mb-4 mt-2">
            <a
              href="https://drive.google.com/file/d/1EAYs4AcQK9cL56xl_StKIEsDRWVt1rDB/view?usp=sharing"
              target="_blank"
              rel="noreferrer"
              download
            >
              <Card className="text-center">
                <CardContent sx={{ p: 5 }}>
                  <h4>LLM Syllabus</h4>
                  <img src={downloadIcon} width="50" height="50" alt="" />
                </CardContent>
              </Card>
            </a>
          </div>
        </div>
      </Card>
    </>
  );
}
export default Coursellm;
