import NotFound from '../../assets/images/illustrations/no_data_found.png'
function index() {
    const url = new URL(window.location.href)
    const fileUrl = url.searchParams.get('file');
    return (
        <>
            {fileUrl ?
                <iframe style={{ width: '100%' }} src={fileUrl} title="Visiting Faculty" id="iframe-desktop" frameborder="0"></iframe>
                :
                <center>
                    <img className="img-fluid" src={NotFound} />
                </center>
            }
        </>
    )
}
export default index