/* eslint-disable import/prefer-default-export */

import { gql } from "@apollo/client";

export const MENUS = gql`
  query Commitee($committeeFilter: CommitteeFilterInput!) {
    commitee(committeeFilter: $committeeFilter) {
      functionalCommittee {
        id
        committeeName
        type
        logo
        createdAt
        createdBy
      }
    }
  }
`;

export const WEBSITE_MENUS = gql`
  query GetWebsiteMenus {
    getWebsiteMenus {
      menus {
        id
        menu_title
        page_url
        slug
        childMenu {
          id
          menu_title
          page_url
          childMenu {
            id
            menu_title
            page_url
          }
        }
      }
      top_menus {
        id
        menu_title
        menu_type
        page_url
      }
    }
  }
`;

export const BOTTOM_MENUS = gql`
  query GetBottomMenus {
    getBottomMenus {
      id
      menu_title
      menu_type
      page_url
      slug
      sort_order
      status
    }
  }
`;
