// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom"; // Material Kit 2 React examples
// Images
import innerCover from "assets/images/banners/banner-iqac.webp";
// import iqac from "assets/images/iqac-logo.webp";
import * as React from "react";
import QuickLinks from "./quick_links/QuickLinks";
import { useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { GET_PAGE_DATAS } from "graphql/query/dynamicPages";
import parse from "html-react-parser";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DownloadIcon from "../../assets/images/icons/download-icon.svg";

function DynamicPages() {
  const [pageDatas, setPageDatas] = React.useState(null);
  // const [childDatas, setChildDatas] = React.useState([])

  const {
    loading,
    error,
    data,
    refetch: oneRefetch,
  } = useQuery(GET_PAGE_DATAS, {
    variables: {
      slug: null,
    },
  });

  React.useEffect(() => {
    if (loading === false) {
      if (error) {
        // setPageDatas(null);
      } else {
        setPageDatas(null);
        const datas = data?.getDynamicPageContents?.[0];
        setPageDatas(datas);
      }
    }
  }, [data, loading, error]);

  const location = useLocation();

  React.useEffect(() => {
    const { pathname } = location;
    const splitArray = pathname.split("slug=");
    setPageDatas(null);
    oneRefetch({
      slug: splitArray[1],
    });
  }, [location.pathname]);

  return (
    <>
      <MKBox
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${innerCover})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
        className="innerCover"
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              {/* IQAC */}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: { xs: 4, lg: 4 },
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Container>
          <div className="row common-box justify-content-between">
            <div className="col-md-8 left-content-area">
              {loading === false ? (
                <>
                  <div>
                    <div className="row align-items-center">
                      <div className="col">
                        <h1 className="iqac-title-left">{pageDatas?.category_title}</h1>
                      </div>
                      <div className="col-5">
                        <div className="heading-sideline"></div>
                      </div>
                    </div>

                    {pageDatas?.page_content ? parse(pageDatas?.page_content) : null}
                    {pageDatas?.page_type === "Image" ? (
                      <>
                        <div className="row my-4">
                          <div className="col-md-12">
                            <img className="iqac-survey-img" src={pageDatas?.file} alt="" />
                          </div>
                        </div>
                      </>
                    ) : null}
                    {pageDatas?.page_type === "Document" &&
                      (pageDatas?.document_link || pageDatas?.file) ? (
                      <>
                        <div className="row my-4">
                          <div className="col-md-12">
                            <div className="col-md-4">
                              <a
                                href={
                                  pageDatas?.upload_type === "Link"
                                    ? '/file-preview?file=' + pageDatas?.document_link
                                    : '/file-preview?file=' + pageDatas?.file
                                }
                                target="_blank"
                              >
                                <div className="reports-box">
                                  <img src={DownloadIcon} className="downloadIcon" />{" "}
                                  <Typography variant="h4" component="h3">
                                    {pageDatas?.title}
                                  </Typography>
                                  {pageDatas?.sub_title ? (
                                    <Typography
                                      variant="h6"
                                      className="iqac-download-link"
                                      component="h3"
                                    >
                                      {pageDatas?.sub_title}
                                      <span>
                                        <ArrowForwardIosIcon />
                                      </span>
                                    </Typography>
                                  ) : null}
                                </div>
                              </a>
                            </div>
                            {/* <img className="iqac-survey-img" src={pageDatas?.file} alt="" /> */}
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                  <div>
                    <div className="row g-4">
                      {pageDatas?.childPages?.map((childCategory) => (
                        <>
                          {/* <div key={childCategory?.slug}> */}
                          {childCategory?.category_title ? (
                            <h2>{childCategory?.category_title}</h2>
                          ) : null}
                          {childCategory?.page_type === "Document" &&
                            childCategory?.title !== "" ? (
                            <div className={`col-md-4 ${childCategory?.lock_status === true ? 'dynamic-button-disaled' : ''}`}>
                              <a
                                href={
                                  childCategory?.upload_type === "Link" ? '/file-preview?file=' + childCategory?.document_link : childCategory?.upload_type === 'Redirect to another page'
                                    ? childCategory?.document_link
                                    : '/file-preview?file=' + childCategory?.file
                                }
                                target="_blank"
                              >
                                <div className="reports-box">
                                  <Typography variant="h4" component="h3">
                                    {childCategory?.title}
                                  </Typography>
                                  {childCategory?.sub_title ? (
                                    <Typography
                                      variant="h6"
                                      className="iqac-download-link"
                                      component="h3"
                                    >
                                      {childCategory?.sub_title}
                                      <span>
                                        <ArrowForwardIosIcon />
                                      </span>
                                    </Typography>
                                  ) : null}
                                </div>
                              </a>
                            </div>
                          ) : null}
                          {childCategory?.page_type === "Image" ? (
                            <div className="row my-5">
                              <div className="col-md-10">
                                <img className="iqac-survey-img" src={childCategory?.file} alt="" />
                              </div>
                            </div>
                          ) : null}
                          {childCategory?.page_type === "Content"
                            ? childCategory?.page_content
                              ? parse(childCategory?.page_content)
                              : null
                            : null}

                          {childCategory?.childPages.length > 0 ? (
                            <div className="row g-4">
                              {childCategory?.childPages?.map((item, index) => (
                                <>
                                  {item?.page_type === "Document" ? (
                                    <div className={`col-md-4 my-4 ${item?.lock_status === true ? 'dynamic-button-disaled' : ''}`} key={index}>
                                      <a
                                        href={
                                          item?.upload_type === "Link" ? '/file-preview?file=' + item?.document_link : item?.upload_type === 'Redirect to another page'
                                            ? item?.document_link
                                            : '/file-preview?file=' + item?.file
                                        }
                                        target="_blank"
                                      >
                                        <div className="reports-box">
                                          <Typography variant="h4" component="h3">
                                            <span>
                                              {" "}
                                              <img
                                                src={DownloadIcon}
                                                className="downloadIcon"
                                              />{" "}
                                            </span>{" "}
                                            {item?.title}
                                          </Typography>
                                          {item?.sub_title ? (
                                            <Typography
                                              variant="h6"
                                              className="iqac-download-link"
                                              component="h3"
                                            >
                                              {item?.sub_title}
                                              <span>
                                                <ArrowForwardIosIcon />
                                              </span>
                                            </Typography>
                                          ) : null}
                                        </div>
                                      </a>
                                    </div>
                                  ) : null}
                                  {/* {item?.page_type === "Image" ?
                                    <div className="row">
                                      <div className="col-md-10">
                                        <img className="iqac-survey-img" src={item?.file} alt="" />
                                      </div>
                                    </div>
                                    : null} */}
                                </>
                              ))}
                            </div>
                          ) : null}
                        </>
                      ))}
                    </div>
                  </div>
                </>
              ) : (
                <p>Loading...</p>
              )}
            </div>
            <QuickLinks />
          </div>
        </Container>
      </Card>
    </>
  );
}
export default DynamicPages;
