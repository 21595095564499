import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { WEBSITE_MENUS } from "../../../graphql/query/menu";
import headerImage from "assets/images/v2/snlc-header.svg";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import SettingsPhoneIcon from "@mui/icons-material/SettingsPhone";
import parse from "html-react-parser";

const TopMenu = styled("div")({});
const TopQuickLinks = styled("div")({
  // backgroundColor: "#35BBED60",
});

function NavbarHeader() {
  const [websiteMenus, setWebsiteMenus] = useState([]);
  const { loading, error, data } = useQuery(WEBSITE_MENUS, {});
  const navigate = useNavigate();
  const handleLogoClick = () => {
    navigate("/");
  };
  useEffect(() => {
    if (loading === false) {
      if (error) {
        setWebsiteMenus([]);
      } else {
        setWebsiteMenus(data.getWebsiteMenus);
      }
    }
  }, [data, loading, error]);

  return (
    <>
      <TopMenu>
        <Grid
          container
          alignItems="start"
          justifyContent="center"
          sx={{ "@media (max-width: 600px)": { display: "none" } }}
        >
          <Grid
            item
            lg={7}
            className="logoSection"
            onClick={handleLogoClick}
            style={{ cursor: "pointer" }}
          >
            <img src={headerImage} alt="Logo" cursor="pointer" className="img-fluid desktop-logo" />
          </Grid>
          <Grid item lg={5}>
            <Grid
              container
              sx={{
                justifyContent: "space-between",
                alignItems: "end",
                "@media (max-width: 600px)": {
                  alignItems: "center",
                },
              }}
            >
              <Grid item lg={12}>
                <TopQuickLinks className="topMenulinks">
                  <Grid container alignItems="center" justifyContent="center">
                    {websiteMenus?.top_menus?.map((topMenus) => (
                      <Grid item lg={3}>
                        {topMenus?.page_url.includes("http") ? ( //Check http- If exist, redirect to another page
                          <a href={topMenus?.page_url} target="_blank">
                            {parse(topMenus?.menu_title)}
                          </a>
                        ) : (
                          <Link to={topMenus?.page_url}>{parse(topMenus?.menu_title)}</Link>
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </TopQuickLinks>
              </Grid>
              <Grid item lg={5} mt={2}>
                <button className="admissionCta">
                  <span>
                    {" "}
                    For Admissions &nbsp;
                    <SettingsPhoneIcon />
                    &nbsp;&nbsp;&nbsp;
                    <a href="tel:9249544766">9249544766</a>, <a href="tel:9249544866">9249544866</a>
                  </span>
                </button>
              </Grid>
              <Grid item lg={4} pr={5}>
                <div className="top-cta-links">
                  <a href="mailto:snlcpoothotta@gmail.com">snlcpoothotta@gmail.com</a>
                  <a href="">0484 - 2794377</a>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </TopMenu>
      {!loading ? (
        <MKBox>
          <DefaultNavbar websiteMenus={websiteMenus} transparent relative light center />
        </MKBox>
      ) : null}
    </>
  );
}
export default NavbarHeader;
