/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
import innerCover from "assets/images/banners/banner-academics.webp";

function Rules() {
  return (
    <>
      <MKBox
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${innerCover})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
        className="innerCover"
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Rules of Discipline
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 4,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <h5 className="rules-discipline">
          The smooth functioning of the College depends upon the observance of discipline by the
          students. During the stay at the college, all students are required to abide by the rules
          and regulations of the college. Non-adherence to these rules makes the students liable to
          disciplinary action which may range from warning to rustication. Following are some of the
          rules of discipline :-
        </h5>
        <ol className="text-j">
          <li>
            Students must attend lectures, moot court, tutorials etc as per the time table. Students
            whose attendance and/or progress in various test and examinations is not satisfactory
            are likely to lose their term. Prolonged absence even on the ground of ill health may
            also lead to loss of term. Defaulters will not be sent up for University Examinations.
          </li>
          <li>
            Disruption of teaching, examination, administrative work, curricular or extracurricular
            activities will amount to indiscipline and will be liable for strict action and
            punishment. The conduct of the students in the classes and in the premises of the
            college shall be such as will cause no disturbance to teachers, fellow students or other
            classes.
          </li>
          <li>
            Every student shall wear the uniform while coming to the college. No students without
            wearing the prescribed uniform will be allowed to attend the classes.
          </li>
          <li>
            No society or association shall be formed in the college and no person should be invited
            to the college without the prior permission of the Principal. Strict action will be
            taken against the defaulters.
          </li>
          <li>
            No student is allowed to display any notice / circular / poster / Banner in the college
            premises without the prior permission of the Principal. All types of demonstrations,
            processions or shouting in the college premises are prohibited and students involved in
            them are liable to be expelled from the college.
          </li>
          <li> No student is allowed to conduct any political activity in the college premises</li>
          <li>
            {" "}
            Outsiders are not permitted in the college premises without the prior permission of the
            Principal.
          </li>
          <li>
            All meetings, cultural programmes debates etc organized in the college premises must be
            held in presence of faculty members and with the prior permission of the Principal.
          </li>
          <li>
            Strict action will be taken against the students damaging College property and will be
            required to compensate the damage.
          </li>
          <li>
            Strict action will be taken against the students involved in mal practices at
            college/university examinations.
          </li>
          <li>Smoking is Strictly Prohibited in the college campus.</li>
          <li>
            Use of Mobile phones is strictly prohibited in the college premises. Defaulters will be
            punished and their instrument confiscated.
          </li>
          <li>Students must not loiter on the college premises while the classes are at work.</li>
          <li>
            Students shall not do anything inside or outside the college that will interfere with
            the discipline of the college or tarnish the image of the college.
          </li>
          <li>
            Ragging in any form is banned. Any student found indulging in it would be guilty of
            gross indiscipline and an FIR will be filed against him with the police as per the
            decision of the Hon’nble Supreme Court.
          </li>
        </ol>
        <h5 className="rules-discipline">
          Matters not concerned above will be decided at the discretion of the Principal. Violation
          of the rules of discipline mentioned above is liable for one or more punishment as stated
          below.
        </h5>
        <ul className="text-j">
          <li>Warning to the student.</li>
          <li>Warning as well as letter to the parent.</li>
          <li>Imposition of fine.</li>
          <li>Denial of library, NSS or any other facility for a specified period.</li>
          <li>Cancellation of term.</li>
          <li>Cancellation of admission.</li>
          <li>Expulsion from the college for a specific period.</li>
          <li>Rustication.</li>
        </ul>
      </Card>
    </>
  );
}

export default Rules;
