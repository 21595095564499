import { Snackbar, Alert } from "@mui/material";

import PropTypes from "prop-types";

export default function SnackbarAlert({ showSnackBarAlert, setShowSnackBarAlert }) {
  const handleSnackbarClose = () => {
    setShowSnackBarAlert({ status: false, type: null, message: null });
  };
  const vertical = "bottom";
  const horizontal = "right";
  return (
    <>
      {showSnackBarAlert.type ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          key={vertical + horizontal}
          open={showSnackBarAlert.status}
          autoHideDuration={3000}
          severity={showSnackBarAlert.type}
          onClose={handleSnackbarClose}
        >
          <Alert
            variant="filled"
            onClose={handleSnackbarClose}
            severity={showSnackBarAlert.type}
            sx={{ width: "100%" }}
          >
            {showSnackBarAlert.message}
          </Alert>
        </Snackbar>
      ) : null}
    </>
  );
}
SnackbarAlert.propTypes = {
  showSnackBarAlert: PropTypes.instanceOf(SnackbarAlert).isRequired,
  setShowSnackBarAlert: PropTypes.func.isRequired,
};
