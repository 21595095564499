/* eslint-disable import/prefer-default-export */

import { gql } from "@apollo/client";

export const GET_DETAILS_BY_TYPE = gql`
  query GetDetailsByType($type: String!) {
    getDetailsByType(type: $type) {
      id
      title
      file
    }
  }
`;
