/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import innerCover from "assets/images/banners/banner-contact.webp";

// Routes
import { GRIEVANCE_REDRESSAL } from "graphql/mutation/contactUs";
// Image
import { useMutation } from "@apollo/client";
import { useState } from "react";
import SnackbarAlert from "../../common/SnackbarAlert";

function Grievance() {
  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState(false);
  const [getName, setName] = useState("");
  const [getEmail, setEmail] = useState("");
  const [getAdmissionNo, setAdmissionNo] = useState("");
  const [getPhone, setPhone] = useState("");
  const [getCourse, setCourse] = useState("BA LL.B (HONOURS) 5 YEAR COURSE");
  const [getSemester, setSemester] = useState("Semester 1");
  const [getTitle, setTitle] = useState("");
  const [getMessage, setMessage] = useState("");

  const [getNameError, setNameError] = useState("");
  const [getAdmNoError, setAdmNoError] = useState("");
  const [getPhoneError, setPhoneError] = useState("");
  const [getTitleError, setTitleError] = useState("");
  const [getEmailError, setEmailError] = useState("");
  const [getMessageError, setMessageError] = useState("");
  const [showSnackBarAlert, setShowSnackBarAlert] = useState({
    status: false,
    type: "success",
    message: null,
  });
  const [contactForm] = useMutation(GRIEVANCE_REDRESSAL, {
    onCompleted: () => {
      setLoader(false);
      setSuccess(true);
      setShowSnackBarAlert({
        status: true,
        type: "success",
        message: "Message sent, we will get contact you shortly.",
      });
    },
  });
  const handleSubmit = () => {
    if (getName.length === 0) {
      setNameError("Name is Required");
    } else {
      setNameError("");
    }
    if (getAdmissionNo.length === 0) {
      setAdmNoError("Admission Number is Required");
    } else {
      setAdmNoError("");
    }
    if (getEmail.length === 0) {
      setEmailError("Email is Required");
    } else {
      setEmailError("");
    }
    const phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (getPhone.length === 0) {
      setPhoneError("Phone is Required");
    } else if (!getPhone.match(phoneno)) {
      setPhoneError("Please provide valid phone number");
    } else {
      setPhoneError("");
    }
    if (getTitle.length === 0) {
      setTitleError("Title is Required");
    } else {
      setTitleError("");
    }
    if (getMessage.length === 0) {
      setMessageError("Message is Required");
    } else {
      setMessageError("");
    }
    if (
      getName.length !== 0 &&
      getEmail.length !== 0 &&
      getMessage.length !== 0 &&
      getAdmissionNo.length !== 0 &&
      getPhone.length !== 0 &&
      getCourse.length !== 0 &&
      getSemester.length !== 0 &&
      getTitle.length !== 0
    ) {
      setLoader(true);
      contactForm({
        variables: {
          grievanceInput: {
            name: getName,
            admission_no: getAdmissionNo,
            email: getEmail,
            phone: getPhone,
            course: getCourse,
            semester: getSemester,
            title: getTitle,
            message: getMessage,
          },
        },
      });
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleAdmissionNoChange = (e) => {
    setAdmissionNo(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };
  const handleCourseChange = (e) => {
    setCourse(e.target.value);
  };
  const handleSemesterChange = (e) => {
    setSemester(e.target.value);
  };
  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };
  return (
    <>
      <SnackbarAlert
        showSnackBarAlert={showSnackBarAlert}
        setShowSnackBarAlert={setShowSnackBarAlert}
      />
      <MKBox
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${innerCover})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
        className="innerCover"
      >
        <MKTypography
          variant="h1"
          color="white"
          sx={({ breakpoints, typography: { size } }) => ({
            [breakpoints.down("md")]: {
              fontSize: size["2xl"],
            },
          })}
        >
          Grievance Redressal Form
        </MKTypography>
      </MKBox>
      <Card
        sx={{
          p: { xs: 2, lg: 8 },
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Grid container spacing={3} alignItems="center" sx={{ justifyContent: "center" }}>
          <Grid item xs={12} lg={8}>
            <MKBox
              bgColor="white"
              borderRadius="xl"
              shadow="lg"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              mx={3}
            >
              {success === false ? (
                <MKBox p={2}>
                  <MKBox component="form" method="post" autocomplete="off">
                    <Grid container spacing={3} mt={3}>
                      <Grid item xs={12} md={12}>
                        <MKInput
                          type="name"
                          variant="standard"
                          color="warning"
                          label="Your Name"
                          fullWidth
                          onChange={(e) => handleNameChange(e)}
                        />
                        {getNameError.length !== 0 ? (
                          <MKTypography
                            color="error"
                            sx={{
                              fontSize: "14px",
                            }}
                          >
                            {getNameError}
                          </MKTypography>
                        ) : null}
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <MKInput
                          type="text"
                          variant="standard"
                          color="warning"
                          label="Admission Number"
                          fullWidth
                          onChange={(e) => handleAdmissionNoChange(e)}
                        />
                        {getAdmNoError.length !== 0 ? (
                          <MKTypography
                            sx={{
                              fontSize: "14px",
                            }}
                            color="error"
                          >
                            {getAdmNoError}
                          </MKTypography>
                        ) : null}
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <MKInput
                          type="email"
                          variant="standard"
                          label="Your Email"
                          fullWidth
                          onChange={(e) => handleEmailChange(e)}
                        />
                        {getEmailError.length !== 0 ? (
                          <MKTypography
                            sx={{
                              fontSize: "14px",
                            }}
                            color="error"
                          >
                            {getEmailError}
                          </MKTypography>
                        ) : null}
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <MKInput
                          type="text"
                          variant="standard"
                          label="Your Phone"
                          fullWidth
                          onChange={(e) => handlePhoneChange(e)}
                        />
                        {getPhoneError.length !== 0 ? (
                          <MKTypography
                            sx={{
                              fontSize: "14px",
                            }}
                            color="error"
                          >
                            {getPhoneError}
                          </MKTypography>
                        ) : null}
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label" sx={{ marginLeft: -1.5 }}>
                            Select Course
                          </InputLabel>
                          <Select
                            sx={{ height: "40px", marginLeft: -1 }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            variant="standard"
                            value={getCourse}
                            label="Select Course"
                            onChange={(e) => handleCourseChange(e)}
                          >
                            <MenuItem value="BA LL.B (HONOURS) 5 YEAR COURSE">
                              BA LL.B (HONOURS) 5 YEAR COURSE
                            </MenuItem>
                            <MenuItem value="BBA LL.B (HONOURS) 5 YEAR COURSE">
                              BBA LL.B (HONOURS) 5 YEAR COURSE
                            </MenuItem>
                            <MenuItem value="B.COM LL.B (HONOURS) 5 YEAR COURSE">
                              B.COM LL.B (HONOURS) 5 YEAR COURSE
                            </MenuItem>
                            <MenuItem value="UNITARY LLB 3YEAR DEGREE COURSE">
                              UNITARY LLB 3YEAR DEGREE COURSE
                            </MenuItem>
                            <MenuItem value="LL.M (CRIMINAL LAW) 2 YEAR COURSE">
                              LL.M (CRIMINAL LAW) 2 YEAR COURSE
                            </MenuItem>
                            <MenuItem value="LL.M (COMMERCIAL LAW) 2 YEAR COURSE">
                              LL.M (COMMERCIAL LAW) 2 YEAR COURSE
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label" sx={{ marginLeft: -2 }}>
                            Select Semester
                          </InputLabel>
                          <Select
                            sx={{ height: "40px", marginLeft: -1.5 }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            variant="standard"
                            value={getSemester}
                            label="Select Course"
                            onChange={(e) => handleSemesterChange(e)}
                          >
                            <MenuItem value="Semester 1">Semester 1</MenuItem>
                            <MenuItem value="Semester 2">Semester 2</MenuItem>
                            <MenuItem value="Semester 3">Semester 3</MenuItem>
                            <MenuItem value="Semester 4">Semester 4</MenuItem>
                            <MenuItem value="Semester 5">Semester 5</MenuItem>
                            <MenuItem value="Semester 6">Semester 6</MenuItem>
                            <MenuItem value="Semester 7">Semester 7</MenuItem>
                            <MenuItem value="Semester 8">Semester 8</MenuItem>
                            <MenuItem value="Semester 9">Semester 9</MenuItem>
                            <MenuItem value="Semester 10">Semester 10</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <MKInput
                          type="email"
                          variant="standard"
                          label="Title"
                          fullWidth
                          onChange={(e) => handleTitleChange(e)}
                        />
                        {getTitleError.length !== 0 ? (
                          <MKTypography
                            sx={{
                              fontSize: "14px",
                            }}
                            color="error"
                          >
                            {getTitleError}
                          </MKTypography>
                        ) : null}
                      </Grid>
                      <Grid item xs={12}>
                        <MKInput
                          variant="standard"
                          type="message"
                          label="How can we help you?"
                          multiline
                          fullWidth
                          rows={4}
                          onChange={(e) => handleMessageChange(e)}
                        />
                        {getMessageError.length !== 0 ? (
                          <MKTypography
                            sx={{
                              fontSize: "14px",
                            }}
                            color="error"
                          >
                            {getMessageError}
                          </MKTypography>
                        ) : null}
                      </Grid>
                    </Grid>
                    <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                      {loader ? (
                        <CircularProgress />
                      ) : (
                        <MKButton type="button" color="warning" onClick={handleSubmit}>
                          submit
                        </MKButton>
                      )}
                    </Grid>
                  </MKBox>
                </MKBox>
              ) : (
                <MKBox p={2}>
                  <Alert severity="success" variant="filled">
                    <AlertTitle>Success</AlertTitle>
                    Message sent, we will get contact you shortly.
                  </Alert>
                </MKBox>
              )}
            </MKBox>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}

export default Grievance;
