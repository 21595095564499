/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import loader from "assets/images/loader.gif";
// Material Kit 2 React components
import MKBox from "components/MKBox";
// import Box from "@mui/material/Box";
import MKTypography from "components/MKTypography";
// import Icon from "@mui/material/Icon";

// Material Kit 2 React examples

// About Us page sections

// Images
import innerCover from "assets/images/banners/banner-downloads.webp";
import downloadIcon from "assets/images/folder.png";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

import * as React from "react";
// Material Kit 2 React examples
import SearchIcon from "@mui/icons-material/Search";
import { useQuery } from "@apollo/client";
import { DOWNLOADS } from "../../graphql/query/downloads";

const CourseCard = styled("div")({
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  fontSize: 10,
  fontFamily: "Open Sans",
  color: "#FFFFFF",
  display: "flex",
  height: "100px",
  opacity: 0.75,
  "&:hover": {
    opacity: 1,
  },
});

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  border: "1px solid #344767",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    border: "1px solid rgba(253, 202, 8, 1)",
  },
  "&:focus": {
    borderColor: "rgba(253, 202, 8, 1)",
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const Reference = styled("a")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  fontWeight: "400",
  alignItems: "right",
  justifyContent: "right",
}));

function Downloads() {
  const [offset, setOffset] = React.useState(0);
  const [downloadDatas, setDownloadDatas] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalCount, setTotalCount] = React.useState(0);
  const [searchData, setSearchData] = React.useState("");
  // const [getSecondValue, setSecondValue] = React.useState([]);
  // const [getThirdValue, setThirdValue] = React.useState([]);
  const { loading, error, data } = useQuery(DOWNLOADS, {
    variables: {
      filterInput: {
        sortColumn: "title",
        sortOrder: "asc",
        search: searchData,
        offset,
        limit: rowsPerPage,
        status: true,
      },
    },
  });
  React.useEffect(() => {
    if (loading === false) {
      if (error) {
        setDownloadDatas([]);
      } else {
        setDownloadDatas(data.downloadDatas.downloads);
        setTotalCount(data.downloadDatas.totalCount);
        // const getEverySecond = (arr, nth) => {
        //   const result = [];
        //   for (let i = 0; i < arr.length; i += nth) {
        //     result.push(arr[i]);
        //   }
        //   return result;
        // };
        // setSecondValue(getEverySecond(data.downloadDatas.downloads, 3));
        // const getEveryThird = (arr, nth) => {
        //   const result = [];
        //   for (let i = 1; i < arr.length; i += nth) {
        //     result.push(arr[i]);
        //   }
        //   return result;
        // };
        // setThirdValue(getEveryThird(data.downloadDatas.downloads, 3));
      }
    }
  }, [data, loading, error]);
  const handleSearch = (e) => {
    setSearchData(e.target.value);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setOffset(0);
  };
  const handleChangePage = (event, newPage) => {
    setOffset(newPage);
  };
  return (
    <>
      <MKBox
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${innerCover})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
        className="innerCover"
      >
        <MKTypography
          variant="h1"
          color="white"
          sx={({ breakpoints, typography: { size } }) => ({
            [breakpoints.down("md")]: {
              fontSize: size["2xl"],
            },
          })}
        >
          Downloads
        </MKTypography>
      </MKBox>
      <Card
        sx={{
          p: { xs: 4, lg: 8 },
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Container>
          <MKTypography>
            <AppBar position="static" ml={25}>
              <Toolbar>
                <IconButton size="large" edge="start" color="inherit" aria-label="open drawer" />
                <Grid container spacing={2} sx={{ justifyContent: "center" }}>
                  <Grid item xs={12} md={5}>
                    <Search>
                      <SearchIconWrapper>
                        <SearchIcon />
                      </SearchIconWrapper>
                      <StyledInputBase
                        placeholder="Search Here..."
                        value={searchData}
                        onChange={(e) => handleSearch(e)}
                        inputProps={{ "aria-label": "search" }}
                      />
                    </Search>
                  </Grid>
                  {loading ? (
                    <Grid container spacing={2}>
                      <Grid item md={6} sx={{ display: "flex", justifyContent: "center" }}>
                        <img alt="loader" src={loader} className="loader" sx={{ width: "60px" }} />
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              </Toolbar>
            </AppBar>
          </MKTypography>
          {downloadDatas.length === 0 && loading === false ? (
            <Grid container spacing={2}>
              <Grid item md={12} mt={3} sx={{ display: "flex", justifyContent: "center" }}>
                <MKTypography variant="h3">No Result Found</MKTypography>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2} alignItems="center" justifyContent="center" mt={3}>
              {downloadDatas.map((ques) => (
                <Grid
                  item
                  xs={12}
                  lg={2}
                  md={4}
                  m={1}
                  sx={{ borderRadius: 10 }}
                  // minHeight="150px"
                  className="downloadsBg text-center"
                >
                  <Reference
                    sx={{ color: "inherit" }}
                    href={ques.file_type}
                    rel="noopener noreferrer"
                    target="_blank"
                    aria-label="File"
                  >
                    <CourseCard>
                      <MKTypography variant="h6" color="dark">
                        {ques.title}
                        <br />

                        <img src={downloadIcon} width="50" height="50" alt="" />
                      </MKTypography>
                    </CourseCard>
                  </Reference>
                </Grid>
              ))}
            </Grid>
          )}
        </Container>
        {downloadDatas.length ? (
          <MKBox alignItems="center" mr={12}>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30, 40, 50]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={offset}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </MKBox>
        ) : null}
      </Card>
    </>
  );
}

export default Downloads;
