/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-router-dom components
import { Link } from "react-router-dom";
// @mui material components
import Icon from "@mui/material/Icon";
import MuiLink from "@mui/material/Link";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import courseBg from "../../../../assets/images/course-bg.jpg";

function FilledInfoCard({ variant, color, icon, title, description, action }) {
  const buttonStyles = {
    width: "max-content",
    display: "none",
    alignItems: "center",
    justifyContent: "center",

    "& .material-icons-round": {
      fontSize: "1.125rem",
      transform: `translateX(3px)`,
      transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
    },

    "&:hover .material-icons-round, &:focus .material-icons-round": {
      transform: `translateX(6px)`,
    },
  };

  let iconColor = color;

  if (variant === "gradient" && color !== "light") {
    iconColor = "white";
  } else if (variant === "gradient" && color === "light") {
    iconColor = "light";
  }

  return (
    <Link to={action.route}>
      <MKBox
        display={{ xs: "block", md: "flex" }}
        borderRadius="xl"
        pt={3}
        pb={3}
        px={3}
        sx={{
          alignItems: "center",
          justifyContent: "center",
          color: "#fff",
          textAlign: "center",
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.9),
              rgba(gradients.dark.state, 0.6)
            )}, url(${courseBg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
          borderRadius: "40px",
          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",
          transition: "all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)",
          minWidth: "200px",
          "&:hover": {
            boxShadow: "0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22)",
            backgroundColor: "#ffffff",
          },
        }}
        className="filledInfocard"
      >
        <MKTypography
          display="block"
          variant="h5"
          sx={{ fontWeight: "bold", color: "#ffff" }}
          color={iconColor}
          textGradient={variant === "contained"}
        >
          {typeof icon === "string" ? <Icon>{icon}</Icon> : icon}
        </MKTypography>
        <MKBox pt={{ xs: 3, md: 0 }} pl={{ xs: 0, md: 2 }} lineHeight={1}>
          <MKTypography
            display="block"
            sx={{ fontWeight: "bold", color: "#fff" }}
            variant="h5"
            fontWeight="regular"
          >
            {title}
          </MKTypography>
          <MKTypography
            display="block"
            variant="body2"
            color={variant === "contained" || color === "light" ? "text" : "white"}
            mb={2}
          >
            {description}
          </MKTypography>
          {action && action.type === "external" ? (
            <MKTypography
              component={MuiLink}
              href={action.route}
              target="_blank"
              rel="noreferrer"
              variant="body2"
              fontWeight="regular"
              color={variant === "contained" ? color : "white"}
              sx={buttonStyles}
            >
              {action.label} <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
            </MKTypography>
          ) : null}
          {action && action.type === "internal" ? (
            <MKTypography
              component={Link}
              to={action.route}
              variant="body2"
              fontWeight="regular"
              color={variant === "contained" ? color : "white"}
              sx={buttonStyles}
            >
              {action.label} <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
            </MKTypography>
          ) : null}
        </MKBox>
      </MKBox>
    </Link>
  );
}

// Setting default props for the FilledInfoCard
FilledInfoCard.defaultProps = {
  variant: "contained",
  color: "info",
  action: false,
};

// Typechecking props for the FilledInfoCard
FilledInfoCard.propTypes = {
  variant: PropTypes.oneOf(["contained", "gradient"]),
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]).isRequired,
      route: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ]),
};

export default FilledInfoCard;
