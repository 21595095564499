// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Slider from "react-slick";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
import innerCover from "assets/images/banners/infra.webp";
import infra1 from "assets/images/infra-1.webp";
import infra2 from "assets/images/infra-2.webp";
import infra3 from "assets/images/infra-3.webp";
import infra4 from "assets/images/infra-4.webp";
import infra5 from "assets/images/infra-5.webp";
import infra6 from "assets/images/infra-6.webp";
import infra7 from "assets/images/infra-7.webp";
import infra8 from "assets/images/infra-8.webp";
import infra9 from "assets/images/infra-9.webp";
import infra10 from "assets/images/infra-10.webp";
import infra11 from "assets/images/infra-11.webp";
import infra12 from "assets/images/infra-12.webp";
import infra13 from "assets/images/infra-13.webp";
import infra14 from "assets/images/infra-14.webp";
import infra15 from "assets/images/infra-15.webp";
import infra16 from "assets/images/infra-16.webp";
import infra17 from "assets/images/infra-17.webp";

const images = [
  infra1,
  infra2,
  infra3,
  infra4,
  infra5,
  infra6,
  infra7,
  infra8,
  infra9,
  infra10,
  infra11,
  infra12,
  infra13,
  infra14,
  infra15,
  infra16,
  infra17,
];

const settings = {
  dots: true,
  infinite: true,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

function Infrastructure() {
  return (
    <>
      <MKBox
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${innerCover})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
        className="innerCover"
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Infrastructure
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: { xs: 4, lg: 8 },
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <div className="row ">
          <div className="content">
            <Slider {...settings} className="library-slider left-side1">
              {images.slice().reverse().map((src, index) => (
                <img key={index} src={src} className="img-fluid" alt={`Infra ${index + 1}`} />
              ))}
            </Slider>
            {/* <img src={mootCourt} className="img-fluid left-side" alt="" /> */}
            <MKTypography variant="subtitle1" className="text-j">
              Sree Narayana Law College, Poothotta has some of the excellent infrastructure
              facilities available for legal education. Its strategic location is another positive
              which makes accessibility easy. The college is now housed in the new building having
              four floors including the ground floor. The building contains enough number of class
              rooms, Separate Moot Court Room, Legal Aid Clinic, National Service Scheme Unit, Human
              Rights Cell, Women empowerment Cell. Apart from these, spacious room office staff,
              Principal, teaching staff and guest room. Sufficient number of separate toilet
              facility for girls and boy students. The College has a good law library with
              sufficient number of books and enough space for arranging books. Separate area for
              reference books and for reading. Faculty reading area is also provided in the library.
              Free internet facility provided for e-learning for teachers and students. The college
              is differently-abled friendly access and Lift facility in all the floor. The entire
              building is protected against fire. Satisfied all norms of fire and safety. The entire
              building is protected under the rules and regulations of fire and safety.
            </MKTypography>
          </div>
        </div>
      </Card>
    </>
  );
}

export default Infrastructure;
