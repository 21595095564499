/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import loader from "assets/images/loader.gif";
import parse from "html-react-parser";

import PropTypes from "prop-types";

// import courseImg from "assets/images/course.png";
// import MKBox from "components/MKBox";
import Slider from "react-slick";
// import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import { styled } from "@mui/material/styles";
// import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";
// import { useLocation } from "react-router-dom";
import MKTypography from "components/MKTypography";
import * as React from "react";
// import { useQuery } from "@apollo/client";
// import CLUB from "../../graphql/query/club";
// import ClubCard from "examples/Cards/ClubCard";

import logoSnlc from "../../assets/images/logo-snlc.png";

const SingleSlider = styled("div")({
  marginRight: "20px",
});
const LogoContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  height: "150px",
});
function ClubInformation({ clubDatas, logoUrl, dataType }) {
  ClubInformation.propTypes = {
    clubDatas: PropTypes.func.isRequired,
    logoUrl: PropTypes.string.isRequired,
    dataType: PropTypes.string.isRequired,
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Container sx={{ mt: 6, mb: 6 }}>
        {dataType === "club" ? (
          <LogoContainer>
            <img src={logoUrl !== null ? logoUrl : logoSnlc} alt="" />
          </LogoContainer>
        ) : null}
        {clubDatas.length ? (
          <div>
            {clubDatas.map((clubType) => (
              <Grid>
                <Grid container>
                  <Grid item xs={12} md={12} sx={{ mb: 3 }}>
                    <MKTypography sx={{ textAlign: "center" }} variant="h3" color="black">
                      {clubType.title}
                    </MKTypography>
                  </Grid>
                </Grid>

                <Grid container display="flex" justifyContent="center" mb={2}>
                  <Grid item xs={12} md={8}>
                    {/* <iframe
                        className="club-video"
                        title="video"
                        id="video"
                        width="720"
                        height="400"
                        src={`https://www.youtube.com/embed/${clubType.vedio_link}`}
                        frameBorder="0"
                        allow="accelerometer, autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      /> */}
                    {clubType.vedio_link.length !== 0 ? (
                      <div className="wrapper">
                        <div className="devices">
                          <div className="desktop">
                            <div className="desktop-iframe-container">
                              <iframe
                                title="video"
                                src={`https://www.youtube.com/embed/${clubType.vedio_link}`}
                                allow="accelerometer, autoplay; encrypted-media; gyroscope; picture-in-picture"
                                id="iframe-desktop"
                                frameBorder={0}
                              />
                            </div>{" "}
                            {/* desktop-iframe-container */}
                          </div>{" "}
                          {/* desktop */}
                        </div>{" "}
                        {/* devices */}
                      </div>
                    ) : null}
                    {/* wrapper */}
                  </Grid>
                  <Grid item xs={12} md={12} mx={{ xs: "auto", sm: 6, md: 1 }}>
                    <MKTypography variant="subtitle1" className="text-j">
                      {clubType.description ? parse(clubType.description) : null}
                      <br />
                    </MKTypography>
                  </Grid>
                </Grid>
                {clubType.clubImages.length >= 4 ? (
                  <Slider {...settings}>
                    {clubType.length !== 0 && clubType.clubImages
                      ? clubType.clubImages.map((clubImage) => (
                          <SingleSlider>
                            <img
                              className="img-fluid club-slider"
                              src={clubImage.img_link}
                              alt="Apple"
                            />
                          </SingleSlider>
                        ))
                      : null}
                  </Slider>
                ) : (
                  <Grid container spacing={3} sx={{ display: "flex", justifyContent: "center" }}>
                    {clubType.length !== 0 && clubType.clubImages
                      ? clubType.clubImages.map((clubImage) => (
                          // <ClubCard image={clubImage.img_link} />
                          <img className="clubImg" src={clubImage.img_link} alt="" />
                        ))
                      : null}
                  </Grid>
                )}
                <hr />
              </Grid>
            ))}
          </div>
        ) : null}
      </Container>
    </>
  );
}

export default ClubInformation;
