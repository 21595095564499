/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import innerCover from "assets/images/banners/banner-contact.webp";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
// Routes
import { CONTACT_US } from "graphql/mutation/contactUs";
// Image
import { useMutation } from "@apollo/client";
import { useState } from "react";
import SnackbarAlert from "../../common/SnackbarAlert";

function ContactUs() {
  const [success, setSuccess] = useState(false);
  const [getName, setName] = useState("");
  const [getEmail, setEmail] = useState("");
  const [getMessage, setMessage] = useState("");
  const [getNameError, setNameError] = useState("");
  const [getEmailError, setEmailError] = useState("");
  const [getMessageError, setMessageError] = useState("");
  const [showSnackBarAlert, setShowSnackBarAlert] = useState({
    status: false,
    type: "success",
    message: null,
  });
  const [contactForm] = useMutation(CONTACT_US, {
    onCompleted: () => {
      setSuccess(true);
      setShowSnackBarAlert({
        status: true,
        type: "success",
        message: "Message sent, we will get contact you shortly.",
      });
    },
  });
  const handleSubmit = () => {
    if (getName.length === 0) {
      setNameError("Name is Required");
    } else {
      setNameError("");
    }
    if (getEmail.length === 0) {
      setEmailError("Email is Required");
    } else {
      setEmailError("");
    }
    if (getMessage.length === 0) {
      setMessageError("Message is Required");
    } else {
      setMessageError("");
    }
    if (getName.length !== 0 && getEmail.length !== 0 && getMessage.length !== 0) {
      contactForm({
        variables: {
          contactInput: {
            name: getName,
            email: getEmail,
            message: getMessage,
          },
        },
      });
    }
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };
  return (
    <>
      <SnackbarAlert
        showSnackBarAlert={showSnackBarAlert}
        setShowSnackBarAlert={setShowSnackBarAlert}
      />
      <MKBox
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${innerCover})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
        className="innerCover"
      >
        <MKTypography
          variant="h1"
          color="white"
          sx={({ breakpoints, typography: { size } }) => ({
            [breakpoints.down("md")]: {
              fontSize: size["2xl"],
            },
          })}
        >
          Contact
        </MKTypography>
      </MKBox>
      <Card
        sx={{
          p: { xs: 3, lg: 8 },
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} lg={6} mb={5}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15723.914046185004!2d76.3858215!3d9.8521695!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5704270f8d6ea33f!2sSREE%20NARAYANA%20LAW%20COLLEGE%2C%20POOTHOTTA!5e0!3m2!1sen!2sin!4v1648786717495!5m2!1sen!2sin"
              width="100%"
              title="map"
              frameBorder="0"
              className="contactMap"
            />
          </Grid>

          <MKBox
            bgColor="white"
            borderRadius="xl"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mx={1}
          >
            <MKBox bgColor="warning" borderRadius="lg" p={2} mx={2} mt={-3}>
              <MKTypography variant="h3" color="white" sx={{ textAlign: "center" }}>
                Contact Us
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKTypography variant="h6" sx={{ textAlign: "center" }} color="text">
                For further enquiries please email us
              </MKTypography>
              <MKBox component="form" method="post" autocomplete="off">
                <Grid container spacing={3} mt={3}>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      type="name"
                      variant="standard"
                      label="Full Name"
                      // InputLabelProps={{ shrink: true }}
                      fullWidth
                      onChange={(e) => handleNameChange(e)}
                    />
                    {getNameError.length !== 0 ? (
                      <MKTypography
                        color="error"
                        sx={{
                          fontSize: "14px",
                        }}
                      >
                        {getNameError}
                      </MKTypography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      type="email"
                      variant="standard"
                      label="Email"
                      // InputLabelProps={{ shrink: true }}
                      fullWidth
                      onChange={(e) => handleEmailChange(e)}
                    />
                    {getEmailError.length !== 0 ? (
                      <MKTypography
                        sx={{
                          fontSize: "14px",
                        }}
                        color="error"
                      >
                        {getEmailError}
                      </MKTypography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    <MKInput
                      variant="standard"
                      type="message"
                      label="How can we help you?"
                      // InputLabelProps={{ shrink: true }}
                      multiline
                      fullWidth
                      rows={6}
                      onChange={(e) => handleMessageChange(e)}
                    />
                    {getMessageError.length !== 0 ? (
                      <MKTypography
                        sx={{
                          fontSize: "14px",
                        }}
                        color="error"
                      >
                        {getMessageError}
                      </MKTypography>
                    ) : null}
                  </Grid>
                </Grid>
                <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                  {success === false ? (
                    <MKButton type="button" color="warning" onClick={handleSubmit}>
                      submit
                    </MKButton>
                  ) : (
                    <Alert severity="success" variant="filled" fullWidth>
                      <AlertTitle>Success</AlertTitle>
                      Message sent, we will get contact you shortly.
                    </Alert>
                  )}
                </Grid>
              </MKBox>
            </MKBox>
          </MKBox>
        </Grid>
      </Card>
    </>
  );
}

export default ContactUs;
