/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import MKTypography from "components/MKTypography";
// Material Kit 2 React components
import MKBox from "components/MKBox";

// Images
import guru from "../../../assets/images/guru-1.png";
import snlc from "../../../assets/images/bg-college.webp";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container id="profile-container">
        <h1 className="main-head">SREE NARAYANA EDUCATIONAL INSTITUTIONS: A BRIEF HISTORY</h1>
        <div className="row">
          <div className="content">
            <img src={guru} alt="history" className="img-fluid left-side2" />
            <MKTypography variant="subtitle1" className="text-j">
              <p className=" pt-3 text-j">
                Sree Narayana Educational Complex is situated in the Tripunithura-Vaikom road,about
                25kms south from Ernakulam town. Poothotta is blessed with the footprints of Jagath
                Guru Sree Narayana Guru, a great sage, and social reformerwho has put ardent efforts
                to set free humanity from the bondage of social oppression,during the time when
                Keralites were segregated in to different strata ,of which the majority were
                labelled the ‘untouhcables’ as they were born in a minority caste group. The efforts
                by Sree Narayana Guru and other spiritual leaders made vibrations in the minds many
                people which led to revolutionary changes in the approach of those ‘superiors’ over
                the untouchables.
              </p>
              <p className="text-j">
                Guru firmly believed that the one and only tool to lead the humanity towards social
                reformation is by providing education.In order to uplift the downtrodden sections of
                the scoeciety to the main stream,he founded several temples in Kerala and a few on
                the west coast of Karnataka. In those days the temples were governed by orthodox
                theocrats were in accessible to most of the working class people. Even though the
                temples were barred to the so called “low caste “people their offering in money and
                kind were always accepted. Guru strongly believed that such shameless exploitation
                of the poor by the caste superiors could be met by founding ‘counter temples ‘ which
                were open to all.
              </p>
              <p className="text-j">
                Narayana Guru was very particular that the new temples he founded were all spots of
                great scenic beauty. From the first experiment of founding a Siva temple at
                Aruvippuram,in Thiruvanathapuram district, he came to know how temples could become
                instrumental in changing the life of people. It might be the reason to select
                Poothotta, a picturesque village, encompassed with spiritual air blooming from
                different sources: such as Diamper Synod( Udayamperur )Sheik Fareedudheen
                Ralliyallahu Anhu Makhbabara,Kanjiramattom,;Vaikom Mahadeva temple, Sree
                Poornathrayesa temple Tripunithura. Of all the concsecrations done by Guru,
                Poothotta at Puthenkav Sree Narayana Vallabha temple is the third one in the year
                1893.After founding the temple at Puthenkavu, Guru ignited the minds of local
                villagers and proclaimed that “Let this place be enriched with Goddess of Education
                (Saraswathi Mutt) that leads to its sublimity “. Under the inspiring words of Guru,
                the villagers with the support and efforts of educated dignitaries and spiritual
                animators established educational institutions one after another. And thus Poothotta
                gradually entered in to realms of educational scenario in Kerala. At present there
                exists seven well established educational institutions which stand as the seven
                pillars of excellence. Sree Narayana Law College is one of the reverberating
                pillars. The other sister concerns are Kshetha Pravesana Memorial higher Secondary
                School and VHSS, SN ITI, Sree Narayana Public School (CBSE Syllabus-Senior
                secondary), Swamy Saswathikanada Arts and Science College, and Sahodaran Ayyappan
                Memorial College of Education.
              </p>
            </MKTypography>
          </div>
        </div>
        <h1 className="main-head">SREE NARAYANA LAW COLLEGE</h1>
        <div className="row">
          <div className="content">
            <img src={snlc} alt="history" className="img-fluid left-side2" />
            <MKTypography variant="subtitle1" className="text-j">
              <p className=" pt-3 text-j">
                The college is named after the great social reformer, philosopher and saint Sree
                Narayana Guru (1854-1928). Based on the ancient directives of Universal brotherhood
                and Love, Sree Narayana Guru preached the noble message of "One Caste, One Religion,
                and One God for man." The college aims at achieving the high ideals of Sree Narayana
                Guru who exhorted us to 'strive for the welfare of all without any discrimination on
                the basis of caste, creed or religion.'
              </p>
              <p className="text-j">
                The Guru was a Karmayogi who transformed the idealistic Advaitha of his predecessors
                to a practical humanistic one by giving an ethical and social context to it.
                Imbibing the teachings of the great Guru such as "One Caste, One Religion, One God
                for man", "Liberate oneself through education", "Humanness marks humanity", our
                college strives to mould generations in order to free them from casteism, bigotry,
                sectarianism and exploitation. We consider education an instrument of socio-economic
                progress, political development and moral, social and intellectual awakening of each
                student.
              </p>
              <p className="text-j">
                Sree Narayana Law College is one of the constituent units of Mahathma Gandhi
                University,Kottayam and recognized by the Bar Council of India,New Delhi. The
                affiliation is approved by the Bar Council of India through its letter
                NoBCI:D:2003/2012(LE.Affi.) dtd 29.09.2012. The college was inaugurated on 24th
                October 2012, at the hands of
                <b> Hon’ble Mr.Justice &nbsp; K.S.Radhakrishnan, Judge, Supreme Court of India.</b>
              </p>
            </MKTypography>
          </div>
        </div>
      </Container>
    </MKBox>
  );
}

export default Information;
