// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TablePagination from "@mui/material/TablePagination";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import loader from "assets/images/loader.gif";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images

import innerCover from "assets/images/banners/banner-achievements.webp";
import { useQuery } from "@apollo/client";
import * as React from "react";
import { ACHIEVEMENTS } from "../../graphql/query/achievements";

function Achievements() {
  const [offset, setOffset] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [congratulationsDatas, setCongratulationsDatas] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const { loading, error, data } = useQuery(ACHIEVEMENTS, {
    variables: {
      achievementsFilter: {
        type: "Achievements",
        status: true,
        limit: rowsPerPage,
        orderColumn: "sort_date",
        orderDirection: "desc",
      },
    },
  });
  React.useEffect(() => {
    if (loading === false) {
      if (error) {
        setCongratulationsDatas([]);
      } else {
        setCongratulationsDatas(data.achievements.congratulations);
        setTotalCount(data.achievements.totalCount);
      }
    }
  }, [data, loading, error]);
  const handleChangePage = (event, newPage) => {
    setOffset(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setOffset(0);
  };

  return (
    <>
      <MKBox
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${innerCover})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
        className="innerCover"
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Achievements
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 8,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox component="section">
          {loading ? (
            <img alt="loader" src={loader} className="loader" />
          ) : (
            <Grid container spacing={2}>
              {congratulationsDatas.length === 0 ? (
                <Grid container spacing={2}>
                  <Grid item md={12} mt={3} sx={{ display: "flex", justifyContent: "center" }}>
                    <MKTypography variant="h3">No Result Found</MKTypography>
                  </Grid>
                </Grid>
              ) : null}
              {congratulationsDatas.map((congratulations) => (
                <Grid item xs={12} spacing={6} md={6} lg={3} key={congratulations.id}>
                  <Grid item display="flex" justifyContent="center">
                    <img className="achivements" src={congratulations.image} alt="Apple" />
                  </Grid>
                  <MKTypography
                    className="achivements-text"
                    sx={{ textAlign: "center" }}
                    variant="h5"
                  >
                    {congratulations.title}
                  </MKTypography>
                </Grid>
              ))}
            </Grid>
          )}
        </MKBox>
        <Stack spacing={2}>
          {congratulationsDatas.length ? (
            <TablePagination
              rowsPerPageOptions={[8, 16, 24, 32, 40]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={offset}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : null}
        </Stack>
      </Card>
    </>
  );
}

export default Achievements;
