/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
// Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
import innerCover from "assets/images/banners/banner-academics.webp";
import downloadIcon from "assets/images/folder.png";

function Unitaryllb() {
  return (
    <>
      <MKBox
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${innerCover})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
        className="innerCover"
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Unitary LL.B (3 year) degree course
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: { xs: 4, lg: 8 },
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <div className="container">
          <h1 className="text-center main-head">Fee Details: 30-10-2023 ONWARDS</h1>
        </div>
        <div className="row my-4 d-flex justify-content-center">
          <div className="col-md-5 mb-5">
            <Card>
              <CardContent sx={{ xs: 2, lg: 5 }}>
                <h4 className="text-center">Merit Fee</h4>
                <ul className="text-number">
                  <li>Tution Fee - RS 36,750/- (Per Year) </li>
                  <li>Special Fee - RS 5,250/- (Per Year)</li>
                  <li>Refundable Advance - RS 50,000/- (First Year Only)</li>
                  <li>Caution Deposit - RS 5,000/- (First Year Only)</li>
                  <li>PTA - RS 500/- (First Year Only)</li>
                  <li>University Fee - RS 1,313*/- (1 st year), Rs 1,063/- (II to V) </li>
                </ul>
                <h6 className="font-weight-bold">GRAND TOTAL - Rs 98,813/- (First Year Fee)</h6>
                <h6 className="font-weight-bold">II, III Year Fee - RS 43,063/-</h6>
                <p className="text-j">
                  <b>* Fee May change every year.</b>
                </p>
              </CardContent>
            </Card>
          </div>
          <div className="col-md-5">
            <Card>
              <CardContent sx={{ xs: 2, lg: 5 }}>
                <h4 className="text-center">Management Fee</h4>
                <ul className="text-number">
                  <li>Tution Fee - RS 54,600/- (Per Year) </li>
                  <li>Special Fee - RS 5,250/- (Per Year)</li>
                  <li>Refundable Advance - RS 50,000/- (First Year Only)</li>
                  <li>Caution Deposit - RS 5,000/- (First Year Only)</li>
                  <li>PTA - RS 500/- (First Year Only)</li>
                  <li>University Fee - RS 1,313*/-(1 ST year),  Rs 1,063/- (II to V) </li>
                </ul>
                <h6 className="font-weight-bold">GRAND TOTAL - Rs 1,16,663/- (First Year Fee)</h6>
                <h6 className="font-weight-bold">II, III  Year Fee - RS 60,913/-*</h6>
                <p className="text-j">
                  <b>* Fee May change every year.</b>
                </p>
              </CardContent>
            </Card>
          </div>
        </div>
        <div className="container d-none">
          <h1 className="text-center main-head">FEE DETAILS AS ON 18.05.2022</h1>
        </div>
        <div className="row my-4 d-flex justify-content-center d-none">
          <div className="col-md-5 mb-3">
            <Card>
              <CardContent sx={{ xs: 2, lg: 5 }}>
                <h4 className="text-center">Merit Fee</h4>
                <ul className="text-number">
                  <li>TUITION FEE - RS 30,000/- </li>
                  <li>SPECIAL FEE - RS 5000/- </li>
                  <li>REFUNDABLE ADVANCE - RS 50,000/- </li>
                  <li>CAUTION DEPOSIT - RS 5000/- </li>
                  <li>PTA - RS 475/- </li>
                  <li>UNIVERSITY FEE - RS 1225*/-</li>
                </ul>
                <h6 className="font-weight-bold">TOTAL (1ST YEAR) - Rs 91700/-</h6>
                <h6>II,III, YEAR Fee - RS.35,000/-</h6>
                <p className="text-j">
                  <b>* Fee May change every year.</b>
                </p>
              </CardContent>
            </Card>
          </div>
          <div className="col-md-5">
            <Card>
              <CardContent sx={{ xs: 2, lg: 5 }}>
                <h4 className="text-center">Management Fee</h4>
                <ul className="text-number">
                  <li>TUITION FEE - RS 45,000/- (Per year)</li>
                  <li>SPECIAL FEE - RS 5000/- (Per year)</li>
                  <li>REFUNDABLE ADVANCE - RS 50,000/- (First Year only)</li>
                  <li>CAUTION DEPOSIT - RS 5000/- (First Year only)</li>
                  <li>PTA - RS 475/- (First Year only)</li>
                  <li>UNIVERSITY FEE - RS 1225*/-</li>
                </ul>
                <h6 className="font-weight-bold">TOTAL (1ST YEAR) - Rs 106700/-</h6>
                <h6>II,III YEAR Fee - RS.50,000/-</h6>
                <p className="text-j">
                  <b>* Fee May change every year.</b>
                </p>
              </CardContent>
            </Card>
          </div>
        </div>
        <div className="container">
          <div className="row ">
            <div className="col-md-8">
              <div className="wrapper">
                <div className="devices">
                  <div className="desktop">
                    <div className="desktop-iframe-container">
                      <iframe
                        src="https://snlc-files.s3.ap-south-1.amazonaws.com/courses_details/3_yr_unitary_llb.pdf"
                        title="syllubus"
                        id="iframe-desktop"
                        frameBorder={0}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="container">
                <h1 className="text-center main-head mb-4">Syllabus </h1>
              </div>
              <div className="row">
                <div className="col-md-12 mb-4">
                  <a
                    href="https://www.mgu.ac.in/uploads/2018/10/6-UNITARY-LL.B-3-YEAR-DEGREE-COURSE.pdf?x72061"
                    target="_blank"
                    rel="noreferrer"
                    download
                  >
                    <Card className="text-center">
                      <CardContent sx={{ p: 5 }}>
                        <h4>Unitary LL.B 3 year degree course</h4>
                        <img src={downloadIcon} width="50" height="50" alt="" />
                      </CardContent>
                    </Card>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
}
export default Unitaryllb;
