import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import parse from "html-react-parser";

import PropTypes from "prop-types";

export default function AdvisoryDescription({ showModal, managementDetails, closeDeleteModal }) {
  return (
    <>
      <Dialog
        open={showModal}
        keepMounted
        onClose={() => closeDeleteModal()}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <Button sx={{ cursor: "default", textDecoration: "none" }}>
            &nbsp;&nbsp;&nbsp;
            <Typography variant="h6" color="black">
              {managementDetails ? managementDetails.name : null}
            </Typography>
          </Button>
        </DialogTitle>
        <DialogContent>
          {managementDetails ? (
            <DialogContentText id="alert-dialog-slide-description">
              {managementDetails.profile ? parse(managementDetails.profile) : null}
            </DialogContentText>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeDeleteModal()}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
AdvisoryDescription.propTypes = {
  showModal: PropTypes.isRequired,
  managementDetails: PropTypes.isRequired,
  closeDeleteModal: PropTypes.isRequired,
};
