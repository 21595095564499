/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import TablePagination from "@mui/material/TablePagination";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// Material Kit 2 React examples
// Images
import bgImage from "assets/images/banners/banner-academics.webp";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import parse from "html-react-parser";

// import Button from "@mui/material/Button";
import loader from "assets/images/loader.gif";
import * as React from "react";
// Material Kit 2 React examples
import { useQuery } from "@apollo/client";
import { NEWS } from "graphql/query/news";

function News() {
  const [offset, setOffset] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [newsDatas, setNewsDatas] = React.useState([]);
  const { loading, error, data } = useQuery(NEWS, {
    variables: {
      newsFilter: {
        limit: rowsPerPage,
        offset,
        status: true,
        orderColumn: "id",
        type: "Site",
        orderDirection: "desc",
      },
    },
  });
  React.useEffect(() => {
    if (loading === false) {
      if (error) {
        setNewsDatas([]);
      } else {
        setNewsDatas(data.news.news);
        setTotalCount(data.news.totalCount);
      }
    }
  }, [data, loading, error]);
  const handleChangePage = (event, newPage) => {
    setOffset(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setOffset(0);
  };

  return (
    <>
      <MKBox
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
        className="innerCover"
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              News
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          py: { xs: 4, lg: 8 },
          px: { xs: 2, lg: 8 },
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox component="section">
          <Container>
            {loading ? (
              <Grid container spacing={2}>
                <Grid item md={6} sx={{ display: "flex", justifyContent: "center" }}>
                  <img alt="loader" src={loader} className="loader" sx={{ width: "60px" }} />
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2} alignItems="center">
                {newsDatas.map((news) => (
                  <Grid container alignItems="center" key={news.id}>
                    <Grid item md={12} sx={{ ml: { xs: 2, lg: 3 }, mb: { xs: 2, md: 0 } }}>
                      <MKTypography variant="h4">{news.title}</MKTypography>
                      <MKTypography variant="body2" className="text-j" color="text" mb={3}>
                        {news.description ? parse(news.description) : null}
                      </MKTypography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            )}
          </Container>
        </MKBox>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 35, 50]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={offset}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </>
  );
}

export default News;
