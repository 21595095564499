

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// About Us page sections
import Information from "pages/About/vision/Information";

// Images
import innerCover from "assets/images/banners/vision.webp";

function Vision() {
  return (
    <>
      <Card
        sx={{
          // p: 2,
          // mx: { xs: 2, lg: 3 },
          // mt: -8,
          // mb: 4,
          boxShadow: "none",
        }}
      >
        <Information />
      </Card>
    </>
  );
}

export default Vision;
