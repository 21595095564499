/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the
Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import MKTypography from "components/MKTypography";
// Material Kit 2 React components
import MKBox from "components/MKBox";
// Images
import guru from "../../../assets/images/about_guru.jpg";
// import history from "../../../assets/images/history.png";
import snlc from "../../../assets/images/v2/snlc-college.webp";

function ProfileDetails() {
  return (
    <MKBox component="section" py={12}>
      <Container id="profile-container">
        {/* <h1 className="main-head">SREE NARAYANA LAW COLLEGE, POOTHOTTA, ERNAKULAM</h1> */}
        {/* <div className="row">
          <div className="content">
            <img src={snlc} alt="history" className="img-fluid left-side2" />
            <MKTypography variant="subtitle1" className="text-j">
              <p className=" pt-3 text-j">
                Sree Narayana Law College was established in the year 2012 in the name of Swami Sree
                Narayana Guru, a social reformer who fought against untouchability and rational
                discrimination. He is known for his contributions in educational uplift of children
                from the backward communities of state of Kerala. The college has been in the
                forefront of imparting legal education from its inception in Kerala. Its core
                strength lies in the dynamic and stern leadership of the Principal, vibrant and
                dedicated team of faculties, honest supporting staff and an ever-supporting
                Management. These positive aspects are accentuated by the additional elements
                enumerated below.
              </p>
            </MKTypography>
          </div>
        </div> */}

        <div className="row">
          <div className="content">
            <h1 className="main-head">SREE NARAYANA EDUCATIONAL INSTITUTIONS: A BRIEF HISTORY</h1>
            <img src={guru} alt="history" className="img-fluid left-side2" />
            <MKTypography variant="subtitle1" className="text-j">
              <p className=" pt-5 text-j">
                Sree Narayana Educational Complex is situated in the Tripunithura-Vaikom road,about
                25kms south from Ernakulam town. Poothotta is blessed with the footprints of Jagath
                Guru Sree Narayana Guru, a great sage, and social reformer who has put ardent
                efforts to set free humanity from the bondage of social oppression,during the time
                when Keralites were segregated in to different strata ,of which the majority were
                labelled the ‘untouhcables’ as they were born in a minority caste group. The efforts
                by Sree Narayana Guru and other spiritual leaders made vibrations in the minds many
                people which led to revolutionary changes in the approach of those ‘superiors’ over
                the untouchables.
              </p>
              <p className="text-j">
                Guru firmly believed that the one and only tool to lead the humanity towards social
                reformation is by providing education.In order to uplift the downtrodden sections of
                the society to the main stream,he founded several temples in Kerala and a few on the
                west coast of Karnataka. In those days the temples were governed by orthodox
                theocrats were in accessible to most of the working class people. Even though the
                temples were barred to the so called “low caste “people their offering in money and
                kind were always accepted. Guru strongly believed that such shameless exploitation
                of the poor by the caste superiors could be met by founding ‘counter temples ‘ which
                were open to all.
              </p>
              <p className="text-j">
                Narayana Guru was very particular that the new temples he founded were all spots of
                great scenic beauty. From the first experiment of founding a Siva temple at
                Aruvippuram,in Thiruvanathapuram district, he came to know how temples could become
                instrumental in changing the life of people. It might be the reason to select
                Poothotta, a picturesque village, encompassed with spiritual air blooming from
                different sources: such as Diamper Synod( Udayamperur )Sheik Fareedudheen
                Ralliyallahu Anhu Makhbabara,Kanjiramattom,;Vaikom Mahadeva temple, Sree
                Poornathrayesa temple Tripunithura. Of all the concsecrations done by Guru,
                Poothotta at Puthenkav Sree Narayana Vallabha temple is the third one in the year
                1893.After founding the temple at Puthenkavu, Guru ignited the minds of local
                villagers and proclaimed that “Let this place be enriched with Goddess of Education
                (Saraswathi Mutt) that leads to its sublimity “. Under the inspiring words of Guru,
                the villagers with the support and efforts of educated dignitaries and spiritual
                animators established educational institutions one after another. And thus Poothotta
                gradually entered in to realms of educational scenario in Kerala. At present there
                exists seven well established educational institutions which stand as the seven
                pillars of excellence. Sree Narayana Law College is one of the reverberating
                pillars. The other sister concerns are Kshetha Pravesana Memorial higher Secondary
                School and VHSS, SN ITI, Sree Narayana Public School (CBSE Syllabus-Senior
                secondary), Swamy Saswathikanada Arts and Science College, and Sahodaran Ayyappan
                Memorial College of Education.
              </p>
            </MKTypography>
          </div>
        </div>
        <h1 className="main-head">SREE NARAYANA LAW COLLEGE</h1>
        <div className="row">
          <div className="content">
            <img src={snlc} alt="history" className="img-fluid left-side2" />
            <MKTypography variant="subtitle1" className="text-j">
              <p className=" pt-3 text-j">
                The college is named after the great social reformer, philosopher and saint Sree
                Narayana Guru (1854-1928). Based on the ancient directives of Universal brotherhood
                and Love, Sree Narayana Guru preached the noble message of "One Caste, One Religion,
                and One God for man." The college aims at achieving the high ideals of Sree Narayana
                Guru who exhorted us to 'strive for the welfare of all without any discrimination on
                the basis of caste, creed or religion.'
              </p>
              <p className="text-j">
                The Guru was a Karmayogi who transformed the idealistic Advaitha of his predecessors
                to a practical humanistic one by giving an ethical and social context to it.
                Imbibing the teachings of the great Guru such as "One Caste, One Religion, One God
                for man", "Liberate oneself through education", "Humanness marks humanity", our
                college strives to mould generations in order to free them from casteism, bigotry,
                sectarianism and exploitation. We consider education an instrument of socio-economic
                progress, political development and moral, social and intellectual awakening of each
                student.
              </p>
              <p className="text-j">
                Sree Narayana Law College is one of the constituent units of Mahathma Gandhi
                University,Kottayam and recognized by the Bar Council of India,New Delhi. The
                affiliation is approved by the Bar Council of India through its letter
                NoBCI:D:2003/2012(LE.Affi.) dtd 29.09.2012. The college was inaugurated on 24th
                October 2012, at the hands of
                <b> Hon’ble Mr.Justice &nbsp; K.S.Radhakrishnan, Judge, Supreme Court of India.</b>
              </p>
            </MKTypography>
          </div>
        </div>

        {/* <div className="row d-flex align-items-center py-3">
          <div className="col-md-7 order-2 order-lg-1">
            <h4>Infrastructure</h4>
            <p className="  text-j">
              Sree Narayana Law College, Poothotta has some of the excellent infrastructure
              facilities available for legal education. Its strategic location is another positive
              which makes accessibility easy. The college is now housed in the new building having
              four floors including the ground floor. The building contains enough number of class
              rooms, Separate Moot Court Room, Legal Aid Clinic, National Service Scheme Unit, Human
              Rights Cell, Women empowerment Cell. Apart from these, spacious room office staff,
              Principal, teaching staff and guest room. Sufficient number of separate toilet
              facility for girls and boy students. The College has a good law library with
              sufficient number of books and enough space for arranging books. Separate area for
              reference books and for reading. Faculty reading area is also provided in the library.
              Free internet facility provided for e-learning for teachers and students. The college
              is differently-abled friendly access and Lift facility in all the floor. The entire
              building is protected against fire. Satisfied all norms of fire and safety. The entire
              building is protected under the rules and regulations of fire and safety.
            </p>
          </div>
          <div className="col-md-5 order-1 order-lg-2">
            <img src={infrastructure} className="img-fluid" alt="SNLC" />
          </div>
        </div> */}
        {/* <div className="row">
          <div className="content">
            <img src={library} alt="history" className="img-fluid left-side2" />
            <MKTypography variant="subtitle1" className="text-j">
              <h4>Library</h4>
              <p className=" pt-3 text-j">
                The college library is equipped with books on various subjects including Law;
                Management Studies; Political science; Economics; History; English, Malayalam and
                Hindi languages. Our library is full-fledged with Journals, Periodicals, Magazines,
                Reports, Newspapers and fictions. <br />
                Every year, college subscribes eleven Law Journals including Criminal Law Journal,
                Complete Kerala High Court Cases, Kerala & Central Laws, Supreme Court Cases, Indian
                Bar Review, AIR Law Lines, All India Reporter, Practical Lawyer, Indian Law Reports,
                AIR Ready Reckoner and Supreme Court Yearly Digest.
                <br />
                For academic excellence and to equip our students for competitive examinations the
                library subscribes to many periodicals like Economic and Political weekly,
                Competition Success Review, Front Line, The week, Mathrubhumi, Yatra and Thozhil
                vartha. The library contains wide range of Newspapers including The Hindu, Indian
                Express, Times of India, Mathrubhumi, Malayala Manorama, Dheshabhimani, Kerala
                Kaumudi and Mangalam, which will encourage the students to read and also update them
                on current affairs.
                <br />
                The SNLC Library hosts more than 8500 volumes of academic books for the benefit of
                students and teachers. The exit and entry to the library are controlled through
                automatic RFID enabled doors. And as part of the development of learning habit, the
                library is equipped with Ten Computers with internet access for the students. Online
                Journals of Manupatra, SCC Online and Kerala High Court Cases are available. Works
                on digitalisation and automatic issue and return of books are going on. Fully bound
                back volumes of AIR, SCC, Complete Kerala High Court Courses, AIR Law Lines,
                Criminal Law Journal, Indian Law Reports and Kerala and Central Laws are available
                in the library. Annually the college purchases books worth Rs. 3 - 5 lakhs.
              </p>
            </MKTypography>
          </div>
        </div> */}
        {/* <div className="row d-flex align-items-center py-3">
          <div className="col-md-12">
            <h4>Endowments</h4>
            <p className="  text-j">
              Sree Narayana Law College, Poothotta has always encouraged studious students. The
              institution believes that merit should not be eclipsed by extraneous especially
              financial factors. The management has been generous enough to subsidize fees of some
              needy students. To further encourage them the college has instituted following
              endowments:
            </p>
            <ol className="text-j">
              <li>
                Poothotta Kovilakom K S Velayudhan Memorial Scholarship (Highest Mark for 3 year
                LL.B Course)
              </li>
              <li>
                Advocate A K Viswambharan Memorial Scholarship (Highest Mark for 5 Year LL.B
                Courses)
              </li>
              <li>Mohanmanoj Late Captain(DE) Endowment ( Highest mark for BA LL.B Courses)</li>
              <li>P T A Scholarship (Highest mark for all LL.B Courses)</li>
              <li>SNDP Management Scholarship (Rank Holders only)</li>
            </ol>
            <p className="text-j">
              Financially needy students who perform exceptionally well in semester examinations are
              selected to receive these endowments. The institution has separate rules for these
              endowments. The semester results of students of this college is a modicum of the
              larger academic interest housed by this institution. This is built around the
              following planks.
            </p>
          </div>
        </div> */}
        {/* <div className="row d-flex align-items-center py-3"> */}
        {/* <div className="col-md-12">
            <h4>Achievements </h4>
            <p className="  text-j">
              The college is at the top of the academic achievements from the inception. Following
              is the list of students who acclaimed the highest position in M.G University LL.B
              Rank.
            </p>
            <div className="table-responsive">
              <table className="table table-bordered text-j  table-hover mb-4">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th width="20%" scope="col">
                      Course
                    </th>
                    <th width="20%" scope="col">
                      Year
                    </th>
                    <th width="30%" scope="col">
                      Rank
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">SANGEETHA SUNNY</th>
                    <td>3Year LL.B</td>
                    <td>2012-15</td>
                    <td>I</td>
                  </tr>
                  <tr>
                    <th scope="row">MAYA S NAIR </th>
                    <td>3Year LL.B</td>
                    <td>2012-15</td>
                    <td>II</td>
                  </tr>
                  <tr>
                    <th scope="row">NAVYA MENON</th>
                    <td>3Year LL.B</td>
                    <td>2013-16</td>
                    <td>I</td>
                  </tr>
                  <tr>
                    <th scope="row">ARADHANA M D</th>
                    <td>BA LL.B</td>
                    <td>2012-17</td>
                    <td>I</td>
                  </tr>
                  <tr>
                    <th scope="row">SUMILIZA V J</th>
                    <td>BA LL.B</td>
                    <td>2012-17</td>
                    <td>II</td>
                  </tr>
                  <tr>
                    <th scope="row">KRISHNENDU.V.B</th>
                    <td>3Year LL.B</td>
                    <td>2015-18</td>
                    <td>I</td>
                  </tr>
                  <tr>
                    <th scope="row">BEJLA MARY BEJOY </th>
                    <td>BA LL.B</td>
                    <td>2013-18</td>
                    <td>I</td>
                  </tr>
                  <tr>
                    <th scope="row">ASWATHY C </th>
                    <td>BA LL.B</td>
                    <td>2014-19</td>
                    <td>II</td>
                  </tr>
                  <tr>
                    <th scope="row">SANDRA SUNNY</th>
                    <td>BA LL.B</td>
                    <td>2014-19</td>
                    <td>III</td>
                  </tr>
                  <tr>
                    <th scope="row">SHERIN SHAJAHAN </th>
                    <td>3Year LL.B</td>
                    <td>2016-19</td>
                    <td>III</td>
                  </tr>
                  <tr>
                    <th scope="row">ABHIRAMI THIRUMENI.T</th>
                    <td>BBA LL.B Hons.</td>
                    <td>2015-20</td>
                    <td>I</td>
                  </tr>
                  <tr>
                    <th scope="row">SANJANA SAJEEV</th>
                    <td>BBA LL.B Hons.</td>
                    <td>2015-20</td>
                    <td>II</td>
                  </tr>
                  <tr>
                    <th scope="row">R RAJANANDINI MENON</th>
                    <td>BBA LL.B Hons.</td>
                    <td>2015-20</td>
                    <td>III</td>
                  </tr>
                  <tr>
                    <th scope="row">SARANYA T NAIR</th>
                    <td>3Year LL.B</td>
                    <td>2018-21</td>
                    <td>I</td>
                  </tr>
                  <tr>
                    <th scope="row">MANJU LUCKOSE</th>
                    <td>3Year LL.B</td>
                    <td>2018-21</td>
                    <td>III</td>
                  </tr>
                  <tr>
                    <th scope="row">KEERTHY RAJ</th>
                    <td>BBA LL.B Hons.</td>
                    <td>2016-21</td>
                    <td>III</td>
                  </tr>
                  <tr>
                    <th scope="row">HEERA MURALEEDHARAN</th>
                    <td>BA LL.B</td>
                    <td>2016-21</td>
                    <td>University Topper</td>
                  </tr>
                  <tr>
                    <th scope="row">GANGA MENON</th>
                    <td>BALL.B(H)</td>
                    <td>2017-22</td>
                    <td>I</td>
                  </tr>
                  <tr>
                    <th scope="row">NASHLAMOL T A</th>
                    <td>BALL.B(H)</td>
                    <td>2017-22</td>
                    <td>II</td>
                  </tr>
                  <tr>
                    <th scope="row">PARVATHY SRIKUMAR</th>
                    <td>BALL.B(H)</td>
                    <td>2017-22</td>
                    <td>III</td>
                  </tr>
                  <tr>
                    <th scope="row">ALKA WARRIAR</th>
                    <td>BBALL.B(H)</td>
                    <td>2017-22</td>
                    <td>II</td>
                  </tr>
                  <tr>
                    <th scope="row">MUHISEENA V Z</th>
                    <td>BALL.B(H)</td>
                    <td>2017-22</td>
                    <td>V</td>
                  </tr>
                  <tr>
                    <th scope="row">SUDARSANA S</th>
                    <td>BALL.B(H)</td>
                    <td>2017-22</td>
                    <td>VI</td>
                  </tr>
                  <tr>
                    <th scope="row">ANUVINDA M</th>
                    <td>BALL.B(H)</td>
                    <td>2017-22</td>
                    <td>X</td>
                  </tr>
                  <tr>
                    <th scope="row">JAYARANI S</th>
                    <td>3 Year LLB</td>
                    <td>2019-22</td>
                    <td>X</td>
                  </tr>
                  <tr>
                    <th scope="row">POULY P D</th>
                    <td>3 Year LLB</td>
                    <td>2019-22</td>
                    <td>X</td>
                  </tr>
                  <tr>
                    <th scope="row">GOWRI M</th>
                    <td>BALL.B(H)</td>
                    <td>2018-23</td>
                    <td>I</td>
                  </tr>
                  <tr>
                    <th scope="row">AARYA SATHEESAN</th>
                    <td>BALL.B(H)</td>
                    <td>2018-23</td>
                    <td>II</td>
                  </tr>
                  <tr>
                    <th scope="row">LAKSHMI J</th>
                    <td>BALL.B(H)</td>
                    <td>2018-23</td>
                    <td>IV</td>
                  </tr>
                  <tr>
                    <th scope="row">ANN MARY VARGNEESE</th>
                    <td>BALL.B(H)</td>
                    <td>2018-23</td>
                    <td>VI</td>
                  </tr>
                  <tr>
                    <th scope="row">POORNIMA RAJEEV</th>
                    <td>BALL.B(H)</td>
                    <td>2018-23</td>
                    <td>VII</td>
                  </tr>
                  <tr>
                    <th scope="row">VISHAKHA J</th>
                    <td>BBALL.B(H)</td>
                    <td>2018-23</td>
                    <td>IV</td>
                  </tr>
                  <tr>
                    <th scope="row">KAVYA R</th>
                    <td>BBALL.B(H)</td>
                    <td>2018-23</td>
                    <td>VIi</td>
                  </tr>
                  <tr>
                    <th scope="row">ADITHYA CHANDRAN</th>
                    <td>BBALL.B(H)</td>
                    <td>2018-23</td>
                    <td>VIIi</td>
                  </tr>
                  <tr>
                    <th scope="row">VISHNU J</th>
                    <td>3 Year LLB</td>
                    <td>2020-23</td>
                    <td>III</td>
                  </tr>
                  <tr>
                    <th scope="row">USHAKUMARI</th>
                    <td>3 Year LLB</td>
                    <td>2020-23</td>
                    <td>IV</td>
                  </tr>
                  <tr>
                    <th scope="row">VARGHESE THOMAS</th>
                    <td>3 Year LLB</td>
                    <td>2020-23</td>
                    <td>V</td>
                  </tr>
                  <tr>
                    <th scope="row"> NAMITHA N</th>
                    <td>3 Year LLB</td>
                    <td>2020-23</td>
                    <td>VII</td>
                  </tr>
                  <tr>
                    <th scope="row">CISLY GRAHSIOUS</th>
                    <td>3 Year LLB</td>
                    <td>2020-23</td>
                    <td>X</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p className="  text-j">
              The semester results of students of this college is a modicum of the larger academic
              interest housed by the institution. This is built around the following planks.
            </p>
          </div>
          <div className="col-md-12">
            <h4>1. Journal Committee</h4>
            <p className="  text-j">
              Primary objective of the SNLC Journal Committee is to initiate legal research at
              various levels towards bringing about certain desired ends. The SNLC Journal Committee
              is envisaged to spearhead editorial activities on the ‘Sree Narayana Law College Law
              Journal’, the peer-reviewed law journal of Sree Narayana Law College, Poothotta. Sree
              Naryana Law College Law Journal editorial committee is a three tier editorial
              committee. They are- a) The Editorial Advisory Committee; b) The Faculty Editors; c)
              Student Editorial Committee. The Patron of our law journal is Hon’ble Mr. Justice K.T.
              Thomas (Former Judge, Supreme Court of India). Editorial Advisory Committee also
              includes some eminent legal personalities like- 1) Hon’ble Mr. Justice N.N. Mathur
              (Former Judge, High Court of Rajasthan); 2) Hon’ble Mr. Justice Padmanabhan Nair
              (Former Judge, High Court of Kerala); 3) Hon’ble Mr. Justice R. Basant (Former Judge,
              High Court of Kerala); 4) Adv. K. Jayakumar (Senior Advocate); 5) Prof. (Dr.) K.N.
              Chandrasekharan Pillai (Former Director, ILI, Delhi; Former Director, NJA, Bhopal); 6)
              Prof. (Dr.) I.P. Massey (Professor, NLU, Jodhpur); 7) Prof. (Dr.) M.C. Valsan
              (Professor, NUALS, Kochi) etc. Sree Narayana Law College Law Journal is a peer
              reviewed law journal bearing ISSN 2350-0824. The inaugural issue of SNLC Law Journal
              will be released on February 25, 2015 by Hon’ble Mr. Justice K.T. Thomas. The SNLC
              Journal Committee also organizes ‘SNLC Annual Legal Essay Competition’. 1st SNLC Legal
              Essay Writing Competition organized in 2014 received massive response from law
              students all over India.
            </p>
          </div>
          <div className="col-md-12">
            <h4>2. Debate and Moot Court Club</h4>
            <p className="  text-j">
              The SNLC Debate and Moot Court Committee was created with twin purpose of sharpening
              legal oratory skill among students by organising internal legal debate, group
              discussions and moot court competition and also to organize high level legal seminars
              and national moot and debate competition in the institution. Weekly seminars are
              conducted by this committee. Annual internal moot court competition is also organized
              by this committee. However, the high-water mark of this committee is starting of SNLC
              Public Law Lecture Series. This lecture series is aimed to be a congregation various
              legal mind.
              <br />
              Students of the college had actively participated in moot court competitions organized
              by law colleges all over India. From the very inception of the college in 2012, the
              Moot Court Club of the college was formed and it has improved the intellectual and
              reasoning ability of the students so that they imbibe the qualities of a good lawyer.
              A team of dedicated students and faculties were strived to improve the mooting skills
              of students from the establishment of the Moot Club. Discussions on recent and
              relevant cases are done on a regular basis. Students of the club have a whatsapp group
              in which they discuss the developments in law. The club also motivates and imparts
              training to the fresh batches every year. The committed faculty and supporting library
              staff is the backbone of all activities of this club.
            </p>
          </div>
          <div className="col-md-12">
            <h4>3. Legal Aid Clinic</h4>
            <p className="  text-j">
              The SNLC Legal Aid Committee through its path breaking activities has lived up to the
              commitment as envisaged by the Constitution of India. Honourable justice Dr. Kauser
              Edappagath has officially inaugurated legal aid clinic and it started functioning from
              November 2017. It functions on all fourth Wednesday of every month from 2.30 p.m. to
              4.00 p.m. As law school we believe it’s our responsibility to facilitate legal aid and
              spread legal awareness in whatever means possible to improve legal aid policy of the
              state. The clinic exists with soul aim bridging the gap between what law promise to
              offer and the actual reality of law. Legal Awareness Classes were conducted by this
              committee in association with KELSA. Legal surveys were conducted by the students in
              and around Poothotta and Udayamperoor. Appropriate and prompt legal advices are
              rendered to needy persons who knocks at the doors of this institution. Legal aid
              clinic has been a part of Sree Narayana law College since its inception.
              <br />
              We provide legal assistance to those in need of such facilities. The aim of starting
              the clinic was not only to bring future lawyers face to face with the harsh realities
              of law but also by giving opportunity to use and implement the law even before
              entering the professional world we tried our best to make our students understand the
              problems faced by indigent and their responsibility in tackling these issues.
            </p>
          </div>
          <div className="col-md-12">
            <h4>4. National Service Scheme</h4>
            <p className="  text-j">
              The SNLC National Service Scheme is an energetic committee of this institution. Its
              activities, right from its inception has been exemplary. The SNLC National Service
              Scheme organizing week long NSS camp every year. This camp was noted for its full
              cooperation from students, teachers and people from the locality. It organized classes
              on World Health Day, Environmental day, Human rights day and classes stressing on
              importance of meditation in professional life. Rashtriya Ekta Divas was also
              celebrated. It organized a student trip to Maher, an old age home also housing orphans
              and destitute on Independence Day. To learn it first hand, the SNLC National Service
              Scheme organized a study tour to District Jail, Kakkanad in the past years. Students
              were given a rare opportunity to interact with some inmates and host cultural
              programmes for them. Public Awareness Campaign citing increasing litigation and
              measures to reduced it was also launched by this committee in coordination with KELSA.
            </p>
          </div>
          <div className="col-md-12">
            <h4>5. Eco-Club </h4>
            <p className="  text-j">
              Sree Narayana Law College Poothotta has constituted an eco-club in the name of “Sree
              Narayana Law College, Eco-club, Poothotta”. The Club was inaugurated on 22nd December
              2020.The main aim of the Eco Club is to have a clean and green environment. The
              student of Sree Narayana Law College are member of Eco club and are regularly
              participating in the Eco club activities. Objectives of the Eco-club are (1) Spread
              the message of Clean and Healthy environment for development of Human being (2)
              motivate and inculcate the motto of Green environment clean environment (3) support
              student community to take part in vegetable gardening and planting trees in and around
              the campus (4) conduct workshops/seminars/debates/quiz and other programmes to fulfil
              the objectives of the club. The Eco Club is a concept and programme with the aim of
              educating society about the environment ecosystem, environmental protection, spreading
              the idea of a sustainable environment ,and instilling a clean and green mentality
              among students through various creative methods.
              <br />
              The club coordinated programmes to achieve the following goals:
              <ol>
                <li>
                  To spread the message of Clean and Healthy environment for development of Human
                  being
                </li>
                <li>Motivate and inculcate the motto of Green environment clean environment</li>
                <li>
                  To support student community to take part in vegetable gardening and planting
                  trees in and around the campus
                </li>
                <li>
                  Conduct workshops/ seminars/ debates / quiz and other programmes to fulfill the
                  objectives of the club
                </li>
              </ol>
            </p>
          </div> */}
        {/* <div className="col-md-12">
            <h4>6. The Alumni Association </h4>
            <p className="  text-j">
              The objectives of the Sree Narayana Law College, Poothotta Alumni Association are (1)
              To maintain the contacts of the Alumni with the alma-mater (2) To foster the
              relationship between the past and present students of the institution (3) To keep the
              old students informed of the growth and development of the institution (4) To give an
              opportunity to the old students of the institution to take part in the responsible
              tasks of building up and maintaining the traditions of the institution (5)To serve as
              a forum striving to get a proper blending of the theoretical and practical aspects of
              the Law (6) To institute prizes, scholarships, book banks etc. for the students of the
              College (7) To help the students passing out of the College in securing employment
              consistent with their qualifications.
            </p>
          </div> */}
        {/* <div className="col-md-12">
            <h4>7. Arts Club </h4>
            <p className="  text-j">
              The Arts Club of Sree Narayana Law College aims to promote the taste and talent of
              students by organizing various programs and performances in our College. The Club is
              open for any student interested in the arts. This Club is a platform for the students
              to develop their talents and overall personality. In order to inculcate this prime
              mission, the club provides ample opportunities to the students in different programs
              such as college arts festivals, university youth festivals and in other
              intercollegiate programs. The club aims to promote good art and culture all over the
              campus giving each and every student willing to participate equal opportunity.
            </p>
          </div> */}
        {/* <div className="col-md-12">
            <h4>8. Sports Club </h4>
            <p className="  text-j">
              The objective of SNLC Sports Club is to nurture the skills and talents of our students
              and support them in their endeavours. SNLC Sports Club oversees the participation of
              our students in inter-college competitions. Our students have been lauded consistently
              for their achievements in the field of sports and games. The participation of students
              in team events such as inter-college football/cricket tournaments has helped in
              establishing camaraderie among the student community and maintaining the sense of
              unity among students. Every year the college hosted many sports items including
              Cricket, football and other indoor and outdoor games. College introduced ever rolling
              trophies for the winners of the arts and sports.
            </p>
          </div> */}
        {/* <div className="col-md-12">
            <h4>9. Yoga Club </h4>
            <p className="  text-j">
              Sree Narayana Law College Yoga Club is a novel initiative advanced by the SNLC IQAC
              cell to ensure the wholesome development of students. Though imparting quality
              education is the prime aim of every college, our institution is also concerned about
              the all round development of our students. The health benefits of Yoga practices have
              been scientifically proved time and again. Yoga helps in maintaining a balanced body
              metabolism, assists in stress release and tremendously improves physical health. The
              objective of SNLC Yoga Club is to provide a platform for the students of our
              institution to learn and practice Yoga techniques. The training programmes are
              organized under the guidance of experienced and certified Yoga trainers. By
              inculcating Yoga into their daily routine, the students are given an opportunity to
              craft a healthy lifestyle of their own. The training programmes are conducted to
              ensure that our students- the future legal professionals, acquire the values of
              self-awareness and self-care. Such techniques shall aid the students in cultivating
              the habit of self-discipline, which shall not only assist their academic endeavours
              but mould their professional prospects as well.
            </p>
          </div> */}
        {/* <div className="col-md-12">
            <h4>10. Parent Teachers Association </h4>
            <p className="text-j">
              The purpose of PTA is to maintain peace and harmonious atmosphere in the college
              campus. Parents are free to contact teachers and principal at any to discuss the
              problems facing the students of the college. The support of the parents in curriculum
              and extra-curricular activities always an added value to the growth of the
              institution.
            </p>
          </div> */}
        {/* <div className="col-md-12">
            <h4>11. Internal appeal Committee </h4>
            <p className="text-j">
              An Internal Appeal Committee is constituted to solve all issues related to internal
              marks awarded to each student. Senior teachers of the college appointed as members of
              the committee.
            </p>
          </div> */}
        {/* <div className="col-md-12">
            <h4>12. Anti-Ragging Committee </h4>
            <p className="text-j">
              Anti-Ragging Committee constituted under the Chairmanship of the Principal to resolve
              the ragging grievances of students. Senior teachers, parents, social workers, NGO’s.
              police officials, representatives of the local body and media and representatives of
              the student are the other members of the committee.
            </p>
          </div> */}
        {/* <div className="col-md-12">
            <h4>13. Women Grievance Cell </h4>
            <p className="text-j">
              A Womens’ Grievance Cell constituted as per the Guidelines of the Hon’ble Supreme
              Court decision in Visakha v. State of Rajasthan. Representatives of lady teachers,
              students and non-teaching staff are the members of the Grievance cell.
            </p>
          </div>
          <div className="col-md-12">
            <h4>14. Minority Cell </h4>
            <p className="text-j">
              Minority cell of the college was established with the purpose of empowering the
              minority communities, SC, ST, and OBC in the college. Our college is committed to
              nurture an environment to promote the cultural diversity that our country takes pride
              in. College has been very much keen to provide services to the educational and
              cultural needs of the minority community along with other disadvantaged sectors. The
              motto of the Cell in general is to respect every student irrespective of their
              ethnicities, socio-cultural backgrounds, religious faiths, national identities. The
              Cell is dedicated to safeguarding individual rights of everyone including minorities
              and other disadvantaged groups in tandem with the provisions of the Constitution. Aims
              and Objectives
              <br />
              <ul>
                <li>
                  To create and promote a secure environment for the SC, ST, OBC, and Minority
                  students of the College. Ensuring protection and reservation as per the
                  Constitution of India is the prime motto of the Cell.{" "}
                </li>
                <li>
                  To offer guidance and counselling to SC, ST, OBC, and Minority students of the
                  University in various matters.{" "}
                </li>
                <li>
                  To look for special career opportunities for the SC, ST, OBC, and Minority
                  students of the University.{" "}
                </li>
                <li>
                  To enhance the psychological wellbeing of SC/ST/OBC and Minority students of the
                  university.
                </li>
                <li>
                  To enhance equal opportunities for education of minorities and SC/ST OBC category
                  students.{" "}
                </li>
                <li>
                  To facilitate and implement the necessary financial support to students of SC/ST
                  OBC and minority communities from governmental agencies and other sources.
                </li>
              </ul>
            </p>
          </div> */}
        {/* <div className="col-md-12">
            <h4>15. Nirnayam </h4>
            <p className="text-j">
              The college has constituted an internal examination monitoring committee-“ Nirnayam”
              for the smooth conduct of internal examination of various semesters. The main aim of
              this committee is to have a fair and speedy assessment procedure and to keep accurate
              records of the same. An e-mail id is created on behalf of the committee and faculty
              members are directed to send the details of the examination including the question
              papers and names of absentees etc.
            </p>
          </div> */}
        {/* <div className="col-md-12">
            <h4>16. Remedial Coaching Centre </h4>
            <p className="text-j">
              Our college has constituted a remedial coaching centre in the year 2020. The main
              vision of the committee is to improve and enhance the student’s performance and to
              brought up them in flying colours. The centre focused on ‘supply free campus’ with
              100% of pass in University examinations. Normally the classes are conducted just
              before the university exam. The classes were conducted in online mode. In this
              academic year we have already completed 60% sessions for clearing the doubts and to
              give a fine coaching on various subjects.
            </p>
          </div> */}
        {/* <div className="col-md-12">
            <h4>17. Mentoring and Counselling Cell </h4>
            <p className="text-j">
              The college had constituted a Students Mentoring and Counselling cell, with the prime
              objectives of inculcating discipline, personal and Professional/academic development
              of students (mentees). The institution has a Counsellor to provide guidance and to
              help students in their personal problems. The services of the counsellor are available
              to the students. Faculty mentors guide mentees in their personal and academic matters.
              College also maintain a record of their academic and personal profile. The mentor
              directs the mentee to the college counsellor for guidance if needed. One mentor is
              appointed for each semester. The process continued till the complete of graduation.
            </p>
          </div> */}
        {/* <div className="col-md-12">
            <h4>Structure of Mentoring and Counselling Cell</h4>
            <ol className="text-j">
              <li>Chairman (The Principal)</li>
              <li>Head of Department</li>
              <li> Faculty Coordinator</li>
            </ol>
          </div> */}
        {/* <div className="col-md-12">
            <h4>Succession List of Managers </h4>
            <ol className="text-j">
              <li>E N Maniyappan (2012-2018)</li>
              <li>A R Ajimon (2018-2021 )</li>
              <li>Sri. E N Maniyappan (2020-2024 )</li>
              <li>A D Unnikrishnan(2024-)</li>
            </ol>
          </div> */}
        {/* <div className="col-md-12">
            <h4>Succession List of Principal</h4>
            <ol className="text-j">
              <li>Prof. K V Mohanan (2012-2019)</li>
              <li>Dr.Pramodan M C (2019)</li>
              <li>Dr. Reghunathan K R (2020- )</li>
            </ol>
          </div> */}
        {/* <div className="col-md-12">
            <Link to="/faculty/Faculty_members?selected=Vice Principal" className="profileList">
              <h4>
                Vice Principal <ArrowForwardIcon />
              </h4>
            </Link>
          </div> */}
        {/* <div className="col-md-10">
            <Link to="/faculty/Faculty_members" className="profileList">
              <h4>
                Teaching Faculty
                <ArrowForwardIcon />
              </h4>
            </Link> */}
        {/* <div className="table-responsive"> */}
        {/* <table className="table table-bordered text-j  table-hover mb-4">
                <thead>
                  <tr>
                    <th scope="col">Sl.No</th>
                    <th width="46%" scope="col">
                      Name
                    </th>
                    <th width="46%" scope="col">
                      Designation
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>Priyanka Prasad</td>
                    <td>Assistant Professor in Law</td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>Hrudya J</td>
                    <td>Assistant Professor in Law</td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td>Aswany Ujwal</td>
                    <td>Assistant Professor in Law</td>
                  </tr>
                  <tr>
                    <th scope="row">4</th>
                    <td>Sheena Das N M</td>
                    <td>Assistant Professor in Law</td>
                  </tr>
                  <tr>
                    <th scope="row">5</th>
                    <td>Regitha G</td>
                    <td>Assistant Professor in Law</td>
                  </tr>
                  <tr>
                    <th scope="row">6</th>
                    <td>Surya K S </td>
                    <td>Assistant Professor in Law</td>
                  </tr>
                  <tr>
                    <th scope="row">7</th>
                    <td>Aswathy Sukumaran</td>
                    <td>Assistant Professor in Law</td>
                  </tr>
                  <tr>
                    <th scope="row">8</th>
                    <td>Anna John</td>
                    <td>Assistant Professor in Law</td>
                  </tr>
                  <tr>
                    <th scope="row">9</th>
                    <td>Vineetha James</td>
                    <td>Assistant Professor in Law</td>
                  </tr>
                  <tr>
                    <th scope="row">10</th>
                    <td>Nyle Kumar</td>
                    <td>Assistant Professor in Law</td>
                  </tr>
                  <tr>
                    <th scope="row">11</th>
                    <td>Babu Bethovan</td>
                    <td>Assistant Professor in Law</td>
                  </tr>
                  <tr>
                    <th scope="row">12</th>
                    <td>Vijealatchoumy </td>
                    <td>Assistant Professor in Law</td>
                  </tr>
                  <tr>
                    <th scope="row">13</th>
                    <td>Vidhya Kishan</td>
                    <td>Assistant Professor in Law</td>
                  </tr>
                  <tr>
                    <th scope="row">14</th>
                    <td>G. Siji</td>
                    <td>Assistant Professor in Law</td>
                  </tr>
                  <tr>
                    <th scope="row">15</th>
                    <td>Jomol J </td>
                    <td>Assistant Professor in Law</td>
                  </tr>
                  <tr>
                    <th scope="row">16</th>
                    <td>A. Anjali Devi</td>
                    <td>Assistant Professor in Law</td>
                  </tr>
                  <tr>
                    <th scope="row">17</th>
                    <td>Gayathri Devi</td>
                    <td>Assistant Professor in Law</td>
                  </tr>
                  <tr>
                    <th scope="row">18</th>
                    <td>Aparna Sukumaran</td>
                    <td>Assistant Professor in Law</td>
                  </tr>
                  <tr>
                    <th scope="row">19</th>
                    <td>Athira M D</td>
                    <td>Assistant Professor in Law</td>
                  </tr>
                  <tr>
                    <th scope="row">20</th>
                    <td>Nisha Sivadasan </td>
                    <td>Assistant Professor in Management</td>
                  </tr>
                  <tr>
                    <th scope="row">21</th>
                    <td>Ashamol P R</td>
                    <td>Assistant Professor in Management</td>
                  </tr>
                  <tr>
                    <th scope="row">22</th>
                    <td>Sini Scaria </td>
                    <td>Assistant Professor in Management</td>
                  </tr>
                  <tr>
                    <th scope="row">23</th>
                    <td>Greeshma P J </td>
                    <td>Assistant Professor in Commerce</td>
                  </tr>
                  <tr>
                    <th scope="row">24</th>
                    <td>Susy A I</td>
                    <td>Assistant Professor in History</td>
                  </tr>
                  <tr>
                    <th scope="row">25</th>
                    <td>Karthika K</td>
                    <td>Assistant Professor in Economics</td>
                  </tr>

                  <tr>
                    <th scope="row">26</th>
                    <td>Parvathy Nair </td>
                    <td> Assistant Professor in Politics</td>
                  </tr>
                  <tr>
                    <th scope="row">27</th>
                    <td>Sheenamol P N</td>
                    <td> Assistant Professor in Politics</td>
                  </tr>
                  <tr>
                    <th scope="row">28</th>
                    <td>Hanna Merin Varghese </td>
                    <td>Assistant Professor in English</td>
                  </tr>
                  <tr>
                    <th scope="row">29</th>
                    <td>Induja C K</td>
                    <td>Assistant Professor in Hindi</td>
                  </tr>
                  <tr>
                    <th scope="row">30</th>
                    <td>Beena B S</td>
                    <td>Assistant Professor in Malayalam</td>
                  </tr>
                </tbody>
              </table> */}
        {/* </div>
          </div> */}
        {/* <div className="col-md-10">
            <Link to="/faculty/AdministrativeStaff" className="profileList">
              <h4>
                Non-Teaching staff <ArrowForwardIcon />
              </h4>
            </Link> */}
        {/* <table className="table table-bordered text-j  table-hover mb-4">
              <thead>
                <tr>
                  <th scope="col">Sl.No</th>
                  <th width="46%" scope="col">
                    Name
                  </th>
                  <th width="46%" scope="col">
                    Designation
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>Rejeesh C R</td>
                  <td>Jr. Supt.</td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>Savitha P B</td>
                  <td>O.A Sl.Gr.</td>
                </tr>
                <tr>
                  <th scope="row">3</th>
                  <td>Aswathymol P S </td>
                  <td>O.A</td>
                </tr>
                <tr>
                  <th scope="row">4</th>
                  <td>Yavina Babu</td>
                  <td>O.A</td>
                </tr>
                <tr>
                  <th scope="row">5</th>
                  <td>Remya P D</td>
                  <td>Librarian</td>
                </tr>
                <tr>
                  <th scope="row">6</th>
                  <td>Sarija Jayaprakash</td>
                  <td>Asst. Librarian</td>
                </tr>
                <tr>
                  <th scope="row">7</th>
                  <td>Alesh P A</td>
                  <td>Electrician Cum Lift Operator</td>
                </tr>
                <tr>
                  <th scope="row">8</th>
                  <td>Jishamol N G</td>
                  <td>L G S</td>
                </tr>
                <tr>
                  <th scope="row">9</th>
                  <td>Animol T</td>
                  <td>L G S</td>
                </tr>
                <tr>
                  <th scope="row">10</th>
                  <td>Santhi P</td>
                  <td>L G S</td>
                </tr>
                <tr>
                  <th scope="row">11</th>
                  <td>Amrutha Raju</td>
                  <td>L G S</td>
                </tr>
                <tr>
                  <th scope="row">12</th>
                  <td>Aswathy Raj</td>
                  <td>L G S</td>
                </tr>
              </tbody>
            </table> */}
        {/* </div> */}
        {/* <div className="col-md-12">
            <h4>Rules of Discipline</h4>
            <p className="text-j">
              The smooth functioning of the College depends upon the observance of discipline by the
              students. During the stay at the college, all students are required to abide by the
              rules and regulations of the college. Non-adherence to these rules makes the students
              liable to disciplinary action which may range from warning to rustication. Following
              are some of the rules of discipline :-
            </p>
            <ol className="text-j">
              <li>
                Students must attend lectures, moot court, tutorials etc as per the time table.
                Students whose attendance and/or progress in various test and examinations is not
                satisfactory are likely to lose their term. Prolonged absence even on the ground of
                ill health may also lead to loss of term. Defaulters will not be sent up for
                University Examinations.
              </li>
              <li>
                Disruption of teaching, examination, administrative work, curricular or
                extracurricular activities will amount to indiscipline and will be liable for strict
                action and punishment. The conduct of the students in the classes and in the
                premises of the college shall be such as will cause no disturbance to teachers,
                fellow students or other classes.
              </li>
              <li>
                Every student shall wear the uniform while coming to the college. No students
                without wearing the prescribed uniform will be allowed to attend the classes.
              </li>
              <li>
                No society or association shall be formed in the college and no person should be
                invited to the college without the prior permission of the Principal. Strict action
                will be taken against the defaulters.
              </li>
              <li>
                No student is allowed to display any notice / circular / poster / Banner in the
                college premises without the prior permission of the Principal. All types of
                demonstrations, processions or shouting in the college premises are prohibited and
                students involved in them are liable to be expelled from the college.
              </li>
              <li>
                No student is allowed to conduct any political activity in the college premises.
              </li>
              <li>
                Outsiders are not permitted in the college premises without the prior permission of
                the Principal.
              </li>
              <li>
                All meetings, cultural programmes debates etc organized in the college premises must
                be held in presence of faculty members and with the prior permission of the
                Principal.
              </li>
              <li>
                Strict action will be taken against the students damaging College property and will
                be required to compensate the damage.
              </li>
              <li>
                Strict action will be taken against the students involved in mal practices at
                college/university examinations.
              </li>
              <li>Smoking is Strictly Prohibited in the college campus.</li>
              <li>
                Use of Mobile phones is strictly prohibited in the college premises. Defaulters will
                be punished and their instrument confiscated.
              </li>
              <li>
                Students must not loiter on the college premises while the classes are at work.
              </li>
              <li>
                Students shall not do anything inside or outside the college that will interfere
                with the discipline of the college or tarnish the image of the college.
              </li>
              <li>
                Ragging in any form is banned. Any student found indulging in it would be guilty of
                gross indiscipline and an FIR will be filed against him with the police as per the
                decision of the Hon’nble Supreme Court.
              </li>
            </ol>
          </div> */}
        {/* <div className="col-md-12">
            <p className="  text-j">
              Matters not concerned above will be decided at the discretion of the Principal.
              Violation of the rules of discipline mentioned above is liable for one or more
              punishment as stated below.
            </p>
            <ul className="text-j">
              <li>Warning to the student.</li>
              <li>Warning as well as letter to the parent.</li>
              <li>Imposition of fine.</li>
              <li>Denial of library, NSS or any other facility for a specified period.</li>
              <li>Cancellation of term.</li>
              <li>Cancellation of admission.</li>
              <li>Expulsion from the college for a specific period.</li>
              <li>Rustication.</li>
            </ul>
          </div> */}
        {/* </div> */}
      </Container>
    </MKBox>
  );
}

export default ProfileDetails;
