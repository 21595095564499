/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import Logout from "pages/common/Logout";
import loader from "assets/images/loader.gif";
// Images
import bgImage from "assets/images/bg-college.webp";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
// import Button from "@mui/material/Button";

import * as React from "react";
// Material Kit 2 React examples
// import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";
import { useQuery } from "@apollo/client";
import { SUBJECTS } from "../../../graphql/query/subjects";

function QuestionPaper() {
  const [courseDatas, setCourseDatas] = React.useState([]);
  const [singleCourse] = React.useState(
    window.location.pathname.split("/")[2].replace("%20", " ").toString()
  );
  const { loading, error, data } = useQuery(SUBJECTS);
  React.useEffect(() => {
    if (loading === false) {
      if (error) {
        setCourseDatas([]);
      } else {
        setCourseDatas(data.getUniqueSubjects.Subjects);
      }
    }
  }, [data, loading, error]);

  const changeCourseName = (course) => {
    if (course === "BA-LLB") {
      return "BA LL.B (HONOURS) 5 YEAR COURSE";
    }
    if (course === "BBA-LLB") {
      return "BBA LL.B (HONOURS) 5 YEAR COURSE";
    }
    if (course === "B.COM LL.B") {
      return "B.COM LL.B (HONOURS) 5 YEAR COURSE";
    }
    if (course === "LL.M (COMMERCIAL LAW)") {
      return "LL.M (COMMERCIAL LAW) 2YEAR COURSE";
    }
    if (course === "LL.M (CRIMINAL LAW)") {
      return "LL.M (CRIMINAL LAW) 2 YEAR COURSE";
    }
    if (course === "LLB") {
      return "UNITARY LL.B 3YEAR DEGREE COURSE";
    }
    return "N/A";
  };
  // const handleSubmit = (course) => {
  //   setSingleCourse(course);
  //   window.location.href = `/question-papers/${singleCourse}`;
  // };
  return (
    <>
      <MKBox
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
        className="innerCover"
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Select Course
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Logout />

        <MKBox component="section" py={12}>
          <Container>
            {loading ? (
              <img alt="loader" src={loader} className="loader" />
            ) : (
              <Grid container spacing={2} alignItems="center" justifyContent="center">
                {courseDatas.map((courseData) => (
                  <Grid
                    item
                    xs={12}
                    lg={4}
                    md={4}
                    sx={{ borderRadius: 10 }}
                    // minHeight="150px"
                    className="text-center"
                  >
                    <FilledInfoCard
                      title={changeCourseName(courseData.course)}
                      action={{
                        type: "internal",
                        route: `/e-learning-and-question-paper/${singleCourse}+${courseData.course}`,
                      }}
                    />
                    {/* <CenteredBlogCard
                    // image="https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
                    action={{
                      // type: "internal",
                      route: `/question-paper/${courseData}`,
                      color: "info",
                      label: `${courseData}`,
                    }}
                  /> */}
                  </Grid>
                ))}
              </Grid>
            )}
          </Container>
        </MKBox>
      </Card>
    </>
  );
}

export default QuestionPaper;
