/* eslint-disable import/prefer-default-export */

import { gql } from "@apollo/client";

export const ALUMNI = gql`
  query Alumni {
    alumni {
      alumni {
        id
        data
      }
    }
  }
`;
