import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useQuery } from "@apollo/client";
import { HOME_GALLERIES } from "graphql/query/gallery";
import { styled } from "@mui/material/styles";
import Slider from "react-slick";
import DateCard from "examples/Cards/DateCard";
const SingleSlider = styled("div")({
  marginRight: "20px",
});

function Gallery() {
  const [rowsPerPage, setRowsPerPage] = React.useState(7);
  const [totalCount, setTotalCount] = React.useState(0);
  const [galleryDatas, setGalleryDatas] = React.useState([]);
  // const [galleryImages, setGalleryImages] = React.useState([]);
  const [offset, setOffset] = React.useState(0);
  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  const { loading, error, data } = useQuery(HOME_GALLERIES, {
    variables: {
      filterInput: {
        sortColumn: "sort_date",
        sortOrder: "desc",
        offset,
        limit: rowsPerPage,
        status: true,
      },
    },
  });

  React.useEffect(() => {
    if (loading === false) {
      if (error) {
        setGalleryDatas([]);
      } else {
        setGalleryDatas(data.getHomeGalleries.galleries);
        setTotalCount(data.getHomeGalleries.totalCount);
      }
    }
  }, [data, loading, error]);
  return (
    <>
      <div className="gallery-container-v2">
        <div className="container ">
          <div className="row header-area">
            <div className="col-md-3">
              <h2>Gallery</h2>
            </div>
            <div className="col-md-9">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="915"
                height="2"
                viewBox="0 0 915 2"
                fill="none"
              >
                <path d="M0 1L915 0.99993" stroke="#F7E763" />
              </svg>
            </div>
          </div>
        </div>
        <div className="gallery-slider" id="gallery-desktop">
          <div className="container card-container">
            <div className="gallery-container">
              <div className="gallery">
                {galleryDatas.map((gallery) => (
                  <div className="card">
                    <img src={gallery.thumbnail} alt={gallery.title} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="gallery-mob-slider" id="gallery-mobile">
          <div className="container">
            <Slider {...settings}>
              {galleryDatas.map((gallery) => (
                <SingleSlider key={gallery.id}>
                  <img src={gallery.thumbnail} alt={gallery.title} />
                </SingleSlider>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
}

export default Gallery;
