import { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKTypography from "components/MKTypography";
import Card from "@mui/material/Card";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import loader from "assets/images/loader.gif";
import { useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import FACULTY_LIST from "../../graphql/query/faculty";
import FacultyDescription from "./FacultyDescription";

function FacultyTeam() {
  const [facultyDatas, setfacultyDatas] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [managementDetails, setManagementDetails] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const category = queryParams.get("category");

  const { loading, error, data } = useQuery(FACULTY_LIST, {
    variables: {
      facultyFilter: {
        offset: 0,
        limit: 4000,
        status: null,
        orderColumn: "sortOrder",
        orderDirection: "asc",
        resigned_status: category === "resigned_status" ? true : false,
      },
    },
  });
  useEffect(() => {
    if (loading === false) {
      if (error) {
        setfacultyDatas([]);
      } else {
        setfacultyDatas(data.faculty.faculty);

        //Open faculty Modal based on URL Value
        const url_value = queryParams.get("selected");
        if (url_value) {
          const selectedValue = data.faculty.faculty.find(
            (faculty) => faculty.designation === url_value
          );
          setManagementDetails(selectedValue);
          setShowModal(true);
        }
        //#End
      }
    }
  }, [data, loading, error]);

  const showManagementDetails = (management) => {
    setManagementDetails(management);
    setShowModal(true);
  };
  const closeDeleteModal = () => {
    setShowModal(false);
  };
  return (
    <MKBox
      component="section"
      variant="gradient"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      {loading ? (
        <img alt="loader" src={loader} className="loader" />
      ) : (
        <Container>
          <Grid container spacing={2} sx={{ display: "flex", justifyContent: "left" }}>
            {facultyDatas.map((falculty) => (
              <Grid item xs={12} lg={4} onClick={() => showManagementDetails(falculty)}>
                <MKBox mb={{ xs: 2, lg: 2 }}>
                  <Card className="card-1">
                    <Grid container alignItems="center">
                      <Grid item xs={4} md={4} lg={4}>
                        <MKBox width="100%" pt={2} pb={2} px={2}>
                          <MKBox
                            component="img"
                            src={falculty.profile_photo}
                            alt="Img"
                            width="100%"
                            borderRadius="md"
                            shadow="lg"
                            className="staffImg"
                          />
                        </MKBox>
                      </Grid>
                      <Grid item xs={1} md={1} lg={1}>
                        &nbsp;
                      </Grid>
                      <Grid item xs={7} md={7} lg={7} sx={{ my: "auto" }}>
                        <MKBox
                          pt={{ xs: 1, lg: 2.5 }}
                          pb={2.5}
                          pr={4}
                          pl={{ xs: 4, lg: 1 }}
                          lineHeight={1}
                        >
                          <MKTypography variant="h6">{falculty.name}</MKTypography>
                          <MKTypography
                            sx={{ fontSize: "14px", textTransform: "capitalize" }}
                            mb={1}
                          >
                            {falculty.designation}
                          </MKTypography>
                          <MKTypography
                            sx={{ fontSize: "13px", textTransform: "capitalize" }}
                            mb={1}
                          >
                            {falculty.qualification}
                          </MKTypography>
                        </MKBox>
                      </Grid>
                    </Grid>
                  </Card>
                </MKBox>
              </Grid>
            ))}

            <FacultyDescription
              showModal={showModal}
              managementDetails={managementDetails}
              closeDeleteModal={closeDeleteModal}
            />
          </Grid>
        </Container>
      )}
    </MKBox>
  );
}

export default FacultyTeam;
