// @mui material components
import Card from "@mui/material/Card";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Presentation page sections
import Information from "pages/Presentation/sections/Information";
import Testimonials from "pages/Presentation/sections/Testimonials";
// import Section from "pages/Presentation/sections/Section";
import About from "pages/Presentation/sections/About";
import VideoBanner from "./sections/VideoBanner";
import RecentEvents from "./sections/RecentEvents";
import Gallery from "./sections/Gallery";
// Routes
import footerRoutes from "footer.routes";
import NavbarHeader from "examples/Navbars/DefaultNavbar/NavbarHeader";
// Custom CSS
import "../../assets/css/custom.css";
import BackToTop from "./components/BackToTop";
import Congratulations from "./sections/Congratulations";

function Presentation() {
  return (
    <>
      <NavbarHeader />
      <VideoBanner />
      {/* <HomeSlider /> */}
      <About />
      {/* <Anouncements /> */}
      <Card>
        {/* <Counters /> */}
        <Information />
        {/* <Section /> */}
        <Congratulations />
        <Gallery />
        <RecentEvents />
        {/* <Events /> */}
        <Testimonials />
        <BackToTop />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;
