/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Slider from "react-slick";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
import innerCover from "assets/images/banners/library.webp";
import library from "assets/images/library.webp";
import library1 from "assets/images/library-1.webp";
import library2 from "assets/images/library-2.webp";
import library3 from "assets/images/library-3.webp";
import library4 from "assets/images/library-4.webp";
import library5 from "assets/images/library-5.webp";
import library6 from "assets/images/library-6.webp";
import library7 from "assets/images/infra-6.webp";
import library8 from "assets/images/infra-10.webp";
import library9 from "assets/images/infra-11.webp";
import library10 from "assets/images/infra-13.webp";
import library11 from "assets/images/infra-14.webp";
const images = [
  library,
  library1,
  library2,
  library3,
  library4,
  library5,
  library6,
  library7,
  library8,
  library9,
  library10,
  library11,
];

const settings = {
  dots: true,
  infinite: true,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

function Library() {
  return (
    <>
      <MKBox
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${innerCover})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
        className="innerCover"
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Library
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: { xs: 4, lg: 8 },
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <div className="row ">
          <Container id="profile-container" sx={{ mb: 5 }}>
            <div className="row d-flex justify-content-center">
              <div className="col-10">
                <h1 className="main-head ">Hon.Justice VR Krishna Iyer Memorial Library</h1>
              </div>
            </div>
          </Container>
          <div className="content">
            <Slider {...settings} className="library-slider left-side1">
              {images.slice().reverse().map((src, index) => (
                <img key={index} src={src} className="img-fluid" alt={`Library ${index}`} />
              ))}
            </Slider>
            {/* <img src={mootCourt} className="img-fluid left-side" alt="" /> */}
            <MKTypography variant="subtitle1" className="text-j">
              The college library is equipped with books on various subjects including Law;
              Management Studies; Political science; Economics; History; English, Malayalam and
              Hindi languages. Our library is full-fledged with Journals, Periodicals, Magazines,
              Reports, Newspapers and fictions. <br />
              Every year, college subscribes eleven Law Journals including Criminal Law Journal,
              Complete Kerala High Court Cases, Kerala & Central Laws, Supreme Court Cases, Indian
              Bar Review, AIR Law Lines, All India Reporter, Practical Lawyer, Indian Law Reports,
              AIR Ready Reckoner and Supreme Court Yearly Digest.
              <br />
              For academic excellence and to equip our students for competitive examinations the
              library subscribes to many periodicals like Economic and Political weekly, Competition
              Success Review, Front Line, The week, Mathrubhumi, Yatra and Thozhil vartha. The
              library contains wide range of Newspapers including The Hindu, Indian Express, Times
              of India, Mathrubhumi, Malayala Manorama, Dheshabhimani, Kerala Kaumudi and Mangalam,
              which will encourage the students to read and also update them on current affairs.
              <br />
              The SNLC Library hosts more than 8500 volumes of academic books for the benefit of
              students and teachers. The exit and entry to the library are controlled through
              automatic RFID enabled doors. And as part of the development of learning habit, the
              library is equipped with Ten Computers with internet access for the students. Online
              Journals of Manupatra, SCC Online and Kerala High Court Cases are available. Works on
              digitalisation and automatic issue and return of books are going on. Fully bound back
              volumes of AIR, SCC, Complete Kerala High Court Courses, AIR Law Lines, Criminal Law
              Journal, Indian Law Reports and Kerala and Central Laws are available in the library.
              Annually the college purchases books worth Rs. 3 - 5 lakhs. <br />
            </MKTypography>
          </div>
        </div>
      </Card>
    </>
  );
}

export default Library;
