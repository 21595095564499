import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Box,
  Tabs,
  Tab,
} from "@mui/material";
import MKBox from "components/MKBox";
import parse from "html-react-parser";
import PropTypes from "prop-types";
import Link from "@mui/material/Link";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DownloadPdfIcon from "../../../src/assets/images/folder.png";

export default function FacultyDescription({ showModal, managementDetails, closeDeleteModal }) {
  const [tabValue, setTabValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  React.useEffect(() => {
    console.log(tabValue);
  }, [tabValue]);

  const getTypeByTabValue = () => {
    return tabValue === 1
      ? "Appointment Order"
      : tabValue === 2
        ? "Experience Certificate"
        : tabValue === 3
          ? "Publication"
          : null;
  };

  React.useEffect(() => {
    setTabValue(0);
  }, [showModal]);
  return (
    <>
      <Dialog
        open={showModal}
        keepMounted
        onClose={() => closeDeleteModal()}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <Button sx={{ cursor: "default", textDecoration: "none" }}>
            <Typography variant="h5" color="black">
              {managementDetails ? managementDetails.name : null}
            </Typography>
          </Button>
        </DialogTitle>
        <DialogContent>
          {managementDetails ? (
            <DialogContentText id="alert-dialog-slide-description" className="facultyDetails">
              <MKBox
                component="img"
                src={managementDetails.profile_photo}
                alt="test"
                width="100%"
                borderRadius="md"
                shadow="lg"
                className="staffImg"
                sx={{ display: "flex", justifyContent: "center" }}
              />
              <Box mb={3} mt={2}>
                <Tabs
                  value={tabValue}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  className="facultyTabs"
                >
                  <Tab label="Profile" {...a11yProps(0)} />
                  <Tab label="Appointment Order" {...a11yProps(1)} />
                  <Tab label="Experiences" {...a11yProps(2)} />
                  <Tab label="Publications" {...a11yProps(3)} />
                </Tabs>
              </Box>
              {tabValue === 0 ? (
                <>
                  <span>{managementDetails.designation}</span>
                  <br />
                  <span>{managementDetails.qualification}</span>
                  <br />
                  {managementDetails.email}
                  {managementDetails.profile ? parse(managementDetails.profile) : null}
                </>
              ) : (
                <ul className="facultyList">
                  {managementDetails?.teacherDocuments?.map((teacherDocument) =>
                    getTypeByTabValue() === teacherDocument?.document_type ? (
                      <li>
                        <Link nk target="_blank" href={'/file-preview?file=' + teacherDocument?.file_url}>
                          {teacherDocument?.title}
                          {teacherDocument?.title ?
                            <>
                              <ArrowForwardIcon /> <br /> </> : null}
                          {teacherDocument?.title === null || teacherDocument?.title === '' ?
                            <img className="pdfDownload" width={75} height={75} src={DownloadPdfIcon} /> : null}
                        </Link>
                      </li>
                    ) : null
                  )}
                </ul>
              )}
            </DialogContentText>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeDeleteModal()}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

FacultyDescription.propTypes = {
  showModal: PropTypes.isRequired,
  managementDetails: PropTypes.isRequired,
  closeDeleteModal: PropTypes.isRequired,
};
