/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import * as React from "react";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { Link } from "react-router-dom";
// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import Stack from "@mui/material/Stack";

import CircularProgress from "@mui/material/CircularProgress";
import logoImage from "assets/images/snlc-logo.png";
// Images
import bgImage from "assets/images/bg-college-login.webp";

import { useMutation } from "@apollo/client";
import { STUDENT_LOGIN } from "../../../graphql/mutation/user";
import SnackbarAlert from "../../common/SnackbarAlert";

const LogoContainer = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  paddingBottom: "20px",
}));

function SignInBasic() {
  const [showSnackBarAlert, setShowSnackBarAlert] = useState({
    status: false,
    type: "success",
    message: null,
  });

  const [loaders, setLoaders] = React.useState(false);

  const [getPassword, setPassword] = useState("");
  const [getUsername, setUsername] = useState("");
  const [getPasswordError, setPasswordError] = useState("");
  const [getUsernameError, setUsernameError] = useState("");
  const [studentlogin] = useMutation(STUDENT_LOGIN, {
    onCompleted: (data) => {
      setLoaders(false);

      setShowSnackBarAlert({ status: true, type: "success", message: "Login Success" });
      localStorage.setItem("token", data.StudentLogin);
      window.location.href = "/e-learning-and-question-papers";
    },
    onError: (error) => {
      setLoaders(false);
      setShowSnackBarAlert({ status: true, type: "error", message: error.message });
    },
  });
  const handleSubmit = () => {
    if (getUsername.length === 0) {
      setUsernameError("Username is Required");
    } else {
      setLoaders(false);
      setUsernameError("");
    }
    if (getPassword.length === 0) {
      setPasswordError("Password is Required");
    } else {
      setPasswordError("");
    }
    if (getUsername.length !== 0 && getPassword.length !== 0) {
      setLoaders(true);

      studentlogin({
        variables: {
          userInput: {
            username: getUsername,
            password: getPassword,
          },
        },
      });
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };
  const footerRedirect = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <SnackbarAlert
        showSnackBarAlert={showSnackBarAlert}
        setShowSnackBarAlert={setShowSnackBarAlert}
        // handleSnackbarClose={handleSnackbarClose}
      />

      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />

      <MKBox px={1} width="100%" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={4}>
            <Card sx={{ p: 2, backgroundColor: "rgba(255, 255, 255, 0.85)", mt: 10 }}>
              <LogoContainer>
                <img
                  src={logoImage}
                  alt="logo-snlc"
                  className="img-fluid"
                  width="120"
                  height="120"
                />
              </LogoContainer>
              <Link className="footer-links" to="/" onClick={footerRedirect}>
                <CloseIcon className="btn-close" />
              </Link>
              {/* <form onSubmit={formik.handleSubmit}> */}
              <MKBox pt={4} pb={3} px={3}>
                <MKBox>
                  <MKBox mb={4}>
                    <MKInput
                      type="email"
                      label="Username"
                      fullWidth
                      variant="standard"
                      name="username"
                      onChange={(e) => handleUsernameChange(e)}
                    />
                    {getUsernameError.length !== 0 ? (
                      <MKTypography
                        variant="button"
                        color="error"
                        textGradient
                        fontWeight="regular"
                      >
                        {getUsernameError}
                      </MKTypography>
                    ) : null}
                  </MKBox>
                  <MKBox mb={4}>
                    <MKInput
                      type="password"
                      variant="standard"
                      label="Password"
                      fullWidth
                      name="password"
                      onChange={(e) => handlePasswordChange(e)}
                    />
                    {getPasswordError.length !== 0 ? (
                      <MKTypography
                        variant="button"
                        color="error"
                        textGradient
                        fontWeight="regular"
                      >
                        {getPasswordError}
                      </MKTypography>
                    ) : null}
                  </MKBox>
                  <MKBox mt={4} mb={1}>
                    {loaders ? (
                      <Stack
                        className="loaderlogin"
                        sx={{ color: "grey.500" }}
                        spacing={2}
                        direction="row"
                        justify-content="center"
                        align-items="center"
                      >
                        <CircularProgress color="success" />
                      </Stack>
                    ) : (
                      <MKButton color="warning" fullWidth onClick={handleSubmit}>
                        Login
                      </MKButton>
                    )}
                  </MKBox>
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
    </>
  );
}

export default SignInBasic;
