/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import { useState, useEffect } from "react";
import loader from "assets/images/loader.gif";

import Container from "@mui/material/Container";
// import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
// import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
// import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images

import { useQuery } from "@apollo/client";
// import { styled } from "@mui/material/styles";

import SISTER_CONCERN from "../../graphql/query/sisterConcern";

function SisterTeam() {
  const [sisterConcernDatas, setsisterConcernDatas] = useState([]);

  const { loading, error, data } = useQuery(SISTER_CONCERN, {
    variables: {
      sisterConcernFilter: {
        orderColumn: "sortOrder",

        status: true,

        sortOrder: "asc",
      },
    },
  });
  useEffect(() => {
    if (loading === false) {
      if (error) {
        setsisterConcernDatas([]);
      } else {
        setsisterConcernDatas(data.sisterConcern.sisterConcern);
      }
    }
  }, [data, loading, error]);

  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="white"
      position="relative"
      py={2}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      {loading ? (
        <img alt="loader" src={loader} className="loader" />
      ) : (
        <Container>
          {sisterConcernDatas.map((sisterConcern) => (
            // <Grid container spacing={3} mt={6}>
            //   <Grid item xs={4} lg={20}>
            //     <MKBox mb={{ xs: 1, lg: 1 }}>
            //       <HorizontalTeamCard
            //         image={sisterConcern.image}
            //         name={sisterConcern.institution_name}
            //         position={{ color: "info", label: sisterConcern.website }}
            //         description={sisterConcern.description}
            //       />
            //     </MKBox>
            //   </Grid>
            // </Grid>
            <div className="row d-flex align-items-center">
              <div className="col-md-4">
                <img src={sisterConcern.image} alt="" className="img-fluid sister-conern" />
              </div>
              <div className="col-md-8">
                <h3>{sisterConcern.institution_name}</h3>
                <h6>
                  <a
                    rel="noreferrer"
                    className="text-j"
                    style={{ color: "#344767" }}
                    href={sisterConcern.website}
                    target="_blank"
                  >
                    {sisterConcern.website}
                  </a>
                </h6>
                <p className="text-j">{sisterConcern.description}</p>
              </div>
              <hr />
            </div>
          ))}
        </Container>
      )}
    </MKBox>
  );
}

export default SisterTeam;
