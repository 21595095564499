/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useQuery } from "@apollo/client";
import { useState, useEffect } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import loader from "assets/images/loader.gif";
import parse from "html-react-parser";
// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
// import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

import PRINCIPAL from "../../graphql/query/principal";

function FacultyInformation() {
  const [principalDetails, setprincipalDetails] = useState([]);
  const { loading, error, data } = useQuery(PRINCIPAL, {
    variables: {
      status: true,
      designation: ["Principal"],
    },
  });

  useEffect(() => {
    if (loading === false) {
      if (error) {
        setprincipalDetails([]);
      } else {
        const principalArray = [];
        data.getPrincipal.forEach((principal) => {
          if (principal.designation === "Principal") {
            principalArray.push(principal);
          }
        });
        setprincipalDetails(principalArray);
      }
    }
  }, [data, loading, error]);
  return (
    <MKBox component="section" py={2}>
      <Container>
        {loading ? (
          <img alt="loader" src={loader} className="loader" />
        ) : (
          <>
            <Grid container spacing={2} alignItems="center" sx={{ justifyContent: "center" }}>
              {principalDetails.map((getPrincipal) => (
                // <div className="col-md-3 col-sm-6">
                //   <div className="our-team-1">
                //     <div className="team-img">
                //       <img src={getPrincipal.profile_photo} alt="" />
                //     </div>
                //     <div className="team-content">
                //       <span className="post">{getPrincipal.name}</span>
                //       <span className="post">Principal</span>
                //       {getPrincipal.profession ? (
                //         <span className="post">( {getPrincipal.profession} )</span>
                //       ) : null}

                //       <span className="post">{getPrincipal.address}</span>
                //       <span className="post">{getPrincipal.contact_no}</span>
                //       <h4>Profile</h4>
                //       <span className="post">{getPrincipal.profile}</span>
                //     </div>
                //   </div>
                // </div>
                <>
                  <Grid item xs={12} lg={3} sx={{ textAlign: "center" }}>
                    <img
                      src={getPrincipal.profile_photo}
                      className="img-fluid principal-img"
                      alt="Principal"
                    />
                    <h5 className="post" sx={{ fontWeight: "bold" }}>
                      {getPrincipal.name}
                    </h5>
                    <span className="text-j">Principal</span>
                    <br />
                    <span className="text-j">{getPrincipal.address}</span>
                    <br />
                    <span className="text-j">{getPrincipal.contact_no}</span>
                  </Grid>

                  <Grid item xs={12} lg={12}>
                    <span className="text-j">
                      {getPrincipal.profile ? parse(getPrincipal.profile) : null}
                    </span>
                  </Grid>
                </>
              ))}
            </Grid>
          </>
        )}
      </Container>
    </MKBox>
  );
}

export default FacultyInformation;
