import React from "react";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import Slider from "react-slick";
// Material Kit 2 React examples
import { Link } from "react-router-dom";
import Moment from "react-moment";

// import testImg from "assets/images/sample.png";
import DateCard from "examples/Cards/DateCard";
// Images
import { useQuery } from "@apollo/client";
import { RECENT_EVENTS } from "graphql/query/events";

const SingleSlider = styled("div")({
  marginRight: "20px",
});

function RecentEvents() {
  const [offset] = React.useState(0);
  const [eventsDatas, setEventsDatas] = React.useState([]);
  const { loading, error, data } = useQuery(RECENT_EVENTS, {
    variables: {
      eventsFilter: {
        orderColumn: "date",
        status: true,
        limit: 100,
        offset,
        orderDirection: "desc",
      },
    },
  });
  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
        },
      },
    ],
  };
  React.useEffect(() => {
    if (loading === false) {
      if (error) {
        setEventsDatas([]);
      } else {
        setEventsDatas(data.recentEvents.events);
      }
    }
  }, [data, loading, error]);
  return (
    <div className="recentEvents">
      <div className="container">
      <div className="heading-container1">
        <h1 className="heading">recent events</h1>
        <div className="yellow-line"></div>
      </div>
        <MKBox component="section" py={12}>
          {eventsDatas.length > 0 ? (
            <Container id="testimonial">
              {eventsDatas.length >= 4 ? (
                <Slider {...settings}>
                  {eventsDatas.map((events) => (
                    <div className="image-card" key={events.id}>
                      <Link to={`/event-videos/${events.id}`} className="event-list">
                        <img src={events.thumbnail_image} alt={events.event_name} />
                        <div className="overlay">
                          <div className="text">
                            <h2>{events.event_name}</h2>
                            <p><Moment format="D-MMM-YYYY">{events.date}</Moment></p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </Slider>
              ) : (
                <Grid container spacing={3}>
                  {eventsDatas.map((events) => (
                    <SingleSlider key={events.id}>
                      <Link to={`/event-videos/${events.id}`}>
                        <DateCard
                          image={events.thumbnail_image}
                          name={events.event_name}
                          date={events.date}
                        />
                      </Link>
                    </SingleSlider>
                  ))}
                </Grid>
              )}
            </Container>
          ) : null}
        </MKBox>
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1354"
          height="2"
          viewBox="0 0 1354 2"
          fill="none"
        >
          <path d="M1 1L1353 1.00012" stroke="#03AAE9" stroke-linecap="round" />
        </svg> */}
      </div>
    </div>
  );
}

export default RecentEvents;
