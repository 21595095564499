/* eslint-disable import/prefer-default-export */

import { gql } from "@apollo/client";

export const ONE_WEBINAR = gql`
  query OneWebinar($oneWebinarId: Float!) {
    oneWebinar(id: $oneWebinarId) {
      id
      title
      thumbnail_image
      description
      date
      status
      createdAt
      updatedAt
      createdBy
      content_type
      webinarVideos {
        id
        video_link
        webinarId
      }
    }
  }
`;
