// src/examples/Cards/VideoCard.js
import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

function VideoCard({ video, title }) {
  // State to toggle video visibility
  const [showVideo, setShowVideo] = useState(false);

  // Function to generate YouTube embed URL
  const getEmbedUrl = (videoId) => {
    return `https://www.youtube.com/embed/${videoId}?autoplay=1`;
  };

  // Extract video ID from video link
  const videoId = video; // Assuming video is the video ID

  const embedUrl = getEmbedUrl(videoId);

  // Function to handle click event on the thumbnail
  const handleClick = () => {
    setShowVideo(true);
  };

  return (
    <Card className="videocard">
      <div style={{ position: "relative", paddingBottom: "56.25%", height: 300 }}>
        <iframe
          title={title}
          src={embedUrl}
          frameBorder="0"
          allowFullScreen
          style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
        />
      </div>

      <CardContent className="video-details">
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default VideoCard;
