/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples

// About Us page sections
import { Link as RouterLink } from "react-router-dom";
import Logout from "pages/common/Logout";

// Images
import bgImage from "assets/images/bg-college.webp";
import eLearning from "assets/images/e-learning.png";
import questionPapers from "assets/images/question-papers.png";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import MKButton from "components/MKButton";
// import Icon from "@mui/material/Icon";
// import { styled } from "@mui/material/styles";

// Material Kit 2 React examples

function ELearningAndQuestionPaper() {
  return (
    <>
      <MKBox
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
        className="innerCover"
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              E-Learning & Question Papers
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Logout />
        <MKBox component="section" py={12}>
          <Container>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} lg={6}>
                <MKBox
                  className="elearningCover"
                  m={1}
                  sx={{
                    backgroundImage: ({
                      functions: { linearGradient, rgba },
                      palette: { gradients },
                    }) =>
                      `${linearGradient(
                        rgba(gradients.dark.main, 0.6),
                        rgba(gradients.dark.state, 0.6)
                      )}, url(${eLearning})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                    boxShadow: "lg",
                    borderRadius: "5%",
                  }}
                >
                  <RouterLink to="/e-learning-and-question-papers/Study-Material">
                    <MKButton color="warning">E-Learning</MKButton>
                  </RouterLink>
                </MKBox>
              </Grid>
              <Grid item xs={12} lg={6}>
                <MKBox
                  className="elearningCover"
                  m={1}
                  sx={{
                    backgroundImage: ({
                      functions: { linearGradient, rgba },
                      palette: { gradients },
                    }) =>
                      `${linearGradient(
                        rgba(gradients.dark.main, 0.6),
                        rgba(gradients.dark.state, 0.6)
                      )}, url(${questionPapers})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                    boxShadow: "lg",
                    borderRadius: "5%",
                  }}
                >
                  <RouterLink to="/e-learning-and-question-papers/Question-Paper">
                    <MKButton color="warning">Question Papers</MKButton>
                  </RouterLink>
                </MKBox>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
    </>
  );
}

export default ELearningAndQuestionPaper;
