// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import loader from "assets/images/loader.gif";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
// Images
import innerCover from "assets/images/banners/banner-management.webp";
import * as React from "react";
import ADMINISTRATIVE_STAFF from "../../graphql/query/administrativeStaff";
import ManagementDescription from "./ManagementDescription";

function Management() {
  const [collegeManagement, setCollegeManagement] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [managementDetails, setManagementDetails] = useState(null);
  const { loading, error, data } = useQuery(ADMINISTRATIVE_STAFF, {
    variables: {
      status: true,
      type: "College Management",
    },
  });
  useEffect(() => {
    if (loading === false) {
      if (error) {
        setCollegeManagement([]);
      } else {
        setCollegeManagement(data.getAdministrativeStaff);
      }
    }
  }, [data, loading, error]);

  const showManagementDetails = (management) => {
    setManagementDetails(management);
    setShowModal(true);
  };
  const closeDeleteModal = () => {
    setShowModal(false);
  };
  return (
    <>
      <MKBox
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${innerCover})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
        className="innerCover"
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              College Management
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox
          component="section"
          variant="gradient"
          bgColor="white"
          position="relative"
          py={2}
          px={{ xs: 1, lg: 0 }}
          mx={-2}
        >
          {loading ? (
            <img alt="loader" src={loader} className="loader" />
          ) : (
            <Container>
              <Grid container spacing={3}>
                {collegeManagement.map((management) => (
                  <Grid item xs={12} lg={4} onClick={() => showManagementDetails(management)}>
                    <MKBox mb={{ xs: 2, lg: 2 }}>
                      <Card className="card-1">
                        <Grid container alignItems="center">
                          <Grid item xs={4} md={4} lg={4}>
                            <MKBox width="100%" pt={2} pb={2} px={2}>
                              <MKBox
                                component="img"
                                src={management.profile_photo}
                                alt="test"
                                width="100%"
                                borderRadius="md"
                                shadow="lg"
                                className="staffImg"
                              />
                            </MKBox>
                          </Grid>
                          <Grid item xs={1} md={1} lg={1}>
                            &nbsp;
                          </Grid>
                          <Grid item xs={7} md={7} lg={7} sx={{ my: "auto" }}>
                            <MKBox
                              pt={{ xs: 1, lg: 2.5 }}
                              pb={2.5}
                              pr={4}
                              pl={{ xs: 4, lg: 1 }}
                              lineHeight={1}
                            >
                              <MKTypography variant="h6">{management.name}</MKTypography>
                              <MKTypography
                                sx={{ fontSize: "14px", textTransform: "capitalize" }}
                                // color={position.color}
                                mb={1}
                              >
                                {management.designation}
                              </MKTypography>
                            </MKBox>
                          </Grid>
                        </Grid>
                      </Card>
                    </MKBox>
                  </Grid>
                ))}
                <ManagementDescription
                  showModal={showModal}
                  managementDetails={managementDetails}
                  closeDeleteModal={closeDeleteModal}
                />
              </Grid>
            </Container>
          )}
        </MKBox>
      </Card>
    </>
  );
}

export default Management;
