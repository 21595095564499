/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
import innerCover from "assets/images/banners/banner-resources.webp";

function Endowments() {
  return (
    <>
      <MKBox
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${innerCover})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
        className="innerCover"
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Endowments
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: { xs: 4, lg: 8 },
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <div className="row ">
          <MKTypography variant="subtitle1" className="text-j">
            Sree Narayana Law College, Poothotta has always encouraged studious students. The
            institution believes that merit should not be eclipsed by extraneous especially
            financial factors. The management has been generous enough to subsidize fees of some
            needy students. To further encourage them the college has instituted following
            endowments:
          </MKTypography>
          <ol className="text-j" style={{ color: "#344767" }} variant="subtitle1">
            <li>
              Poothotta Kovilakom K S Velayudhan Memorial Scholarship (Highest Mark for 3 year LL.B
              Course)
            </li>
            <li>
              Advocate A K Viswambharan Memorial Scholarship (Highest Mark for 5 Year LL.B Courses)
            </li>
            <li>Mohanmanoj Late Captain(DE) Endowment ( Highest mark for BA LL.B Courses)</li>
            <li>P T A Scholarship (Highest mark for all LL.B Courses)</li>
            <li>SNDP Management Scholarship (Rank Holders only)</li>
          </ol>
          <MKTypography variant="subtitle1" className="text-j">
            Financially needy students who perform exceptionally well in semester examinations are
            selected to receive these endowments. The institution has separate rules for these
            endowments. The semester results of students of this college is a modicum of the larger
            academic interest housed by this institution. This is built around the following planks.
          </MKTypography>
        </div>
      </Card>
    </>
  );
}

export default Endowments;
