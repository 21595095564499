// import AboutUs from "layouts/pages/landing-pages/about-us";
import Presentation from "layouts/pages/presentation";
import About from "pages/About/about";
import Profile from "pages/About/profile";
import Vision from "pages/About/vision";
// import Quality from "pages/About/quality";
import Courses from "pages/Academics/courses";
import Grievance from "pages/Academics/grievance";
import Rules from "pages/Academics/rules-discipline";
import Library from "pages/Resources/library";
import Infrastructure from "pages/Resources/infrastructure";
import Endowments from "pages/Resources/endowments";
import MootCourt from "pages/Resources/moot-court";
import ContactUs from "layouts/pages/landing-pages/contact-us";
import SignIn from "layouts/pages/authentication/sign-in";
import Management from "layouts/pages/management";
// import ExecutiveCounsil from "layouts/pages/ExecutiveCounsil";
import AdvisoryBoard from "layouts/pages/advisoryBoard";
import Achievements from "layouts/pages/achievements";
import Alumni from "layouts/pages/alumni";
import Webinar from "layouts/pages/webinar";
import FacultyMember from "layouts/pages/faculty";
import Principal from "layouts/pages/faculty/principals";
import VicePrincipal from "layouts/pages/faculty/viceprincipal";
import AluminiForm from "pages/Alumni/Register";

// import Manager from "layouts/pages/faculty/manager";

import SisterConcerns from "layouts/pages/sisterConcern";
import AdministrativeStaffView from "layouts/pages/administrativeStaff";
import Downloads from "layouts/pages/downloads";
import ELearningAndQuestionPaper from "layouts/pages/elearningandquestionpapers/elearningandquestionpaper";
import Gallery from "layouts/pages/gallery";
// import IQACAnnualReports from "./pages/iqac_v2/IQACAnnualReports";
// import SSR from "./pages/iqac_v2/SSR";
// import ActionReports from "pages/iqac_v2/ActionReports";
// import NaacReports from "pages/iqac_v2/NaacReports";
// import FeedbackForm from "pages/iqac_v2/FeedbackForm";
// import StudentSurvey from "pages/iqac_v2/StudentSurvey";
import DynamicPages from "./pages/DynamicPages";
import Committees from "./pages/Committees";
import VisitingFaculty from "./pages/Faculty/VisitingFaculty";
// import NaacReportPage from "layouts/pages/iqac/report/NaacReport";

// import NaacSteeringCommitteePage from "layouts/pages/iqac/naac-steering-committee";
// import IQACMembersPage from "layouts/pages/iqac/iqac-members";
// import CriteriaWiseInChargesPage from "layouts/pages/iqac/criteria-wise-in-charges";
// import NaacPage from "layouts/pages/iqac/naac/Naac";

let loginArray = [];
if (localStorage.getItem("token")) {
  loginArray = {
    name: "E-Learning",
    route: "/e-learning-and-question-papers",
    component: <ELearningAndQuestionPaper />,
  };
} else {
  loginArray = {
    name: "Login",
    route: "/student/sign-in",
    component: <SignIn />,
  };
}
const routes = [
  {
    name: "Home",
    columns: 1,
    rowsPerColumn: 2,
    route: "/",
    component: <Presentation />,
  },
  {
    name: "About",
    columns: 1,
    rowsPerColumn: 2,
    collapse: [
      {
        collapse: [
          {
            name: "About",
            route: "/about/about",
            component: <About />,
          },
          {
            name: "SNLC-Profile",
            route: "/about/profile",
            component: <Profile />,
          },
          {
            name: "Vision & Mission",
            route: "/about/vision",
            component: <Vision />,
          },
          // {
          //   name: "Quality Policy",
          //   route: "/about/quality-policy",
          //   component: <Quality />,
          // },
          {
            name: "Sister Concern",
            route: "/about/sister-concerns",
            component: <SisterConcerns />,
          },
        ],
      },
    ],
  },
  {
    name: "Academics",
    columns: 1,
    rowsPerColumn: 2,
    collapse: [
      {
        collapse: [
          {
            name: "Programmes offered",
            route: "/academics/courses",
            component: <Courses />,
          },
          {
            name: "Rules of Discipline",
            route: "/academics/rules-discipline",
            component: <Rules />,
          },
          {
            name: "Webinars",
            route: "/webinars",
            component: <Webinar />,
          },
          {
            name: "Grievance Redressal Form",
            route: "/grievance",
            component: <Grievance />,
          },
          {
            name: "Committees",
            route: "/academics/committees",
            component: <Committees />,
          },
          {
            name: "Outcome Based Education",
            route: "/obe/slug=outcome_based_education",
            component: <DynamicPages />,
          },
          {
            name: "Academic Calendar",
            route: "/academic-calendar/slug=academic_calendar",
            component: <DynamicPages />,
          },
        ],
      },
    ],
  },
  {
    name: "Management",
    columns: 1,
    rowsPerColumn: 2,
    collapse: [
      {
        collapse: [
          // {
          //   name: "Managers",
          //   route: "/faculty/managers",
          //   component: <Manager />,
          // },
          {
            name: "College Management",
            route: "/management/college-management",
            component: <Management />,
          },

          {
            name: "Governing Council",
            route: "/management/advisory-board",
            component: <AdvisoryBoard />,
          },
          // {
          //   name: "Executive Council",
          //   route: "/management/executive-council",
          //   component: <ExecutiveCounsil />,
          // },
        ],
      },
    ],
  },
  {
    name: "Faculty",
    columns: 1,
    rowsPerColumn: 2,
    collapse: [
      {
        collapse: [
          {
            name: "Principal",
            route: "/faculty/principal",
            component: <Principal />,
          },
          {
            name: "Vice-Principal",
            route: "/faculty/vice-principal",
            component: <VicePrincipal />,
          },
          {
            name: "Teaching Faculty",
            route: "/faculty/Faculty_members",
            component: <FacultyMember />,
          },
          {
            name: "Visiting Faculties",
            route: "/faculty/visiting-faculties",
            component: <VisitingFaculty />,
          },
          {
            name: "Non-Teaching staff",
            route: "/faculty/AdministrativeStaff",
            component: <AdministrativeStaffView />,
          },
        ],
      },
    ],
  },
  {
    name: "Resources",
    columns: 1,
    rowsPerColumn: 2,
    collapse: [
      {
        collapse: [
          {
            name: "Endowments",
            route: "/resources/endowments",
            component: <Endowments />,
          },
          {
            name: "Infrastructure",
            route: "/resources/infra",
            component: <Infrastructure />,
          },
          {
            name: "Library",
            route: "/resources/library",
            component: <Library />,
          },
          {
            name: "MOOT Court",
            route: "/resources/moot-court",
            component: <MootCourt />,
          },

          // {
          //   name: "Multipurpose auditorium",
          //   route: "/resources/auditorium",
          //   component: <AboutUs />,
          // },

          // {
          //   name: "Seminar hall",
          //   route: "/resources/seminar-hall",
          //   component: <AboutUs />,
          // },

          // {
          //   name: "Legal AID Clinic",
          //   route: "/resources/legal-aid",
          //   component: <AboutUs />,
          // },
        ],
      },
    ],
  },
  {
    name: "Clubs",
    columns: 1,
    rowsPerColumn: 2,
    collapse: [
      {
        collapse: [],
      },
    ],
  },
  // {
  //   name: "Committees",
  //   columns: 1,
  //   rowsPerColumn: 2,
  //   collapse: [
  //     {
  //       collapse: [],
  //     },
  //   ],
  // },
  {
    name: "Alumni",
    columns: 1,
    rowsPerColumn: 2,
    route: "/alumni",
    component: <Alumni />,
  },
  {
    name: "Achivements",
    columns: 1,
    rowsPerColumn: 2,
    route: "/achievements",
    component: <Achievements />,
  },
  {
    name: "IQAC",
    columns: 1,
    rowsPerColumn: 2,
    route: "/iqac/slug=about_iqac",
    component: <DynamicPages />,
  },
  {
    name: "NAAC",
    columns: 1,
    rowsPerColumn: 2,
    route: "/naac/slug=ssr_1714921678278",
    component: <DynamicPages />,
  },
  {
    name: "Dynamic Page",
    columns: 1,
    rowsPerColumn: 2,
    route: "/page/*",
    component: <DynamicPages />,
  },
  // {
  //   name: "IAQC Annual Reports",
  //   columns: 1,
  //   rowsPerColumn: 2,
  //   route: "/iqac/annual-report",
  //   component: <IQACAnnualReports />,
  // },
  // {
  //   name: "SSR",
  //   columns: 1,
  //   rowsPerColumn: 2,
  //   route: "/iqac/ssr",
  //   component: <SSR />,
  // },
  // {
  //   name: "Action Reports",
  //   columns: 1,
  //   rowsPerColumn: 2,
  //   route: "/iqac/action-reports",
  //   component: <ActionReports />,
  // },
  // {
  //   name: "NAAC Reports",
  //   columns: 1,
  //   rowsPerColumn: 2,
  //   route: "/iqac/naac-reports",
  //   component: <NaacReports />,
  // },
  // {
  //   name: "Online Feedback Forms",
  //   columns: 1,
  //   rowsPerColumn: 2,
  //   route: "/iqac/feedback-forms",
  //   component: <FeedbackForm />,
  // },
  // {
  //   name: "Students Satisfaction Survey",
  //   columns: 1,
  //   rowsPerColumn: 2,
  //   route: "/iqac/student-survey",
  //   component: <StudentSurvey />,
  // },

  // {
  //   name: "IQAC",
  //   columns: 1,
  //   rowsPerColumn: 1,
  //   collapse: [
  //     {
  //       collapse: [
  //         {
  //           name: "IQAC",
  //           route: "/iqac/iqac-info",
  //           component: <IQAC />,
  //         },
  //         {
  //           name: "NAAC Reports",
  //           route: "/iqac/naac-report",
  //           component: <NaacPage />,
  //         },
  //         {
  //           name: "NAAC steering committee",
  //           route: "/iqac/Naac-steering-committee",
  //           component: <NaacSteeringCommitteePage />,
  //         },
  //         {
  //           name: "IQAC members",
  //           route: "/iqac/iqac-members",
  //           component: <IQACMembersPage />,
  //         },
  //         {
  //           name: "Criteria wise incharges",
  //           route: "/iqac/criteria-wise-in-charge",
  //           component: <CriteriaWiseInChargesPage />,
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    name: "Downloads",
    columns: 1,
    rowsPerColumn: 2,
    route: "/downloads",
    component: <Downloads />,
  },
  {
    name: "Gallery",
    columns: 1,
    rowsPerColumn: 2,
    route: "/gallery",
    component: <Gallery />,
  },
  {
    name: "Contact",
    columns: 1,
    rowsPerColumn: 2,
    route: "/contact-us",
    component: <ContactUs />,
  },
  {
    name: "Alumini Form",
    columns: 1,
    rowsPerColumn: 2,
    route: "/alumini-form",
    component: <AluminiForm />,
  },
  loginArray,
];

export default routes;
