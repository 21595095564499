/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as React from "react";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import loader from "assets/images/loader.gif";
import downloadIcon from "assets/images/external-link.png";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { useQuery } from "@apollo/client";

// Material Kit 2 React examples

// Images
import innerCover from "assets/images/banners/banner-iqac.webp";
import Container from "@mui/material/Container";
import { GET_DETAILS_BY_TYPE } from "../../../graphql/query/naac";

// Material Kit 2 React examples

function PdfReports() {
  const [reportDatas, setReportDatas] = React.useState([]);
  const params = new URLSearchParams(window.location.search);

  const { loading, error, data } = useQuery(GET_DETAILS_BY_TYPE, {
    variables: {
      type: params.get("category"),
    },
  });
  React.useEffect(() => {
    if (loading === false) {
      if (error) {
        setReportDatas([]);
      } else {
        setReportDatas(data.getDetailsByType);
      }
    }
  }, [data, loading, error]);

  const handleDocViewModal = (fileUrl) => {
    window.open(fileUrl);
  };
  return (
    <>
      <MKBox
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${innerCover})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
        className="innerCover"
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              NAAC Reports
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: { xs: 4, lg: 8 },
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Container>
          {loading ? (
            <img alt="loader" src={loader} className="loader" />
          ) : (
            <Grid container spacing={3} alignItems="center">
              {reportDatas?.map((reports) => (
                <Grid item xs={12} lg={4} md={6}>
                  <div className="card naac-card">
                    <Button onClick={() => handleDocViewModal(reports.file)}>
                      <MKBox display="flex" alignItems="center" p={1}>
                        <MKBox
                          variant="gradient"
                          bgColor="warning"
                          color="white"
                          coloredShadow="warning"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          borderRadius="xl"
                          sx={{ p: 0.5 }}
                        >
                          <HistoryEduIcon />
                        </MKBox>

                        <MKTypography
                          pl={1}
                          variant="h5"
                          sx={({ breakpoints, typography: { size } }) => ({
                            [breakpoints.down("md")]: {
                              fontSize: size["2xl"],
                            },
                          })}
                        >
                          {reports?.title}
                        </MKTypography>
                      </MKBox>
                      <Grid container spacing={2} alignItems="center" justifyContent="center">
                        <Grid item={2}>
                          <img
                            src={downloadIcon}
                            className="img-fluid"
                            alt="Download"
                            width="32"
                            height="32"
                          />
                        </Grid>
                      </Grid>
                    </Button>
                  </div>
                </Grid>
              ))}
            </Grid>
          )}
        </Container>
      </Card>
    </>
  );
}

export default PdfReports;
