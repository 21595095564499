// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
// Images
import parse from "html-react-parser";
import innerCover from "assets/images/banners/alumni.webp";
import * as React from "react";
import { ALUMNI } from "../../graphql/query/alumni";

function AlumniPage() {
  const [alumniData, setAlumniData] = useState(null);
  const { loading, error, data } = useQuery(ALUMNI);

  useEffect(() => {
    if (loading === false) {
      if (error) {
        setAlumniData(null);
      } else {
        setAlumniData(data.alumni.alumni[0].data);
      }
    }
  }, [data, loading, error]);
  return (
    <>
      <MKBox
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${innerCover})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
        className="innerCover"
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Alumni
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: { xs: 4, lg: 8 },
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox component="section">
          <Grid container spacing={2}>
            {alumniData ? parse(alumniData) : null}
          </Grid>
        </MKBox>
      </Card>
    </>
  );
}

export default AlumniPage;
