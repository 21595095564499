import Container from "assets/theme/components/container";
import SNLC from "../../../assets/images/logo-snlc.png";
import Vision from "../../../assets/images/v2/vision.png";
import Mission from "../../../assets/images/v2/mission.png";
import Motto from "../../../assets/images/v2/motto.png";

function Information() {
  return (
    <div className="vision-bg">
      <h1 className="main-titles">Vision & Mission</h1>
      <div className="container">
        <div className="hr-with-image">
          <hr />
          <div className="image-container">
            <img src={SNLC} className="img-fluid" />
          </div>
          <hr />
        </div>
        <div className="row justify-content-between">
          <div className="col-md-3 d-flex">
            <div className="content-card">
              <img src={Vision} className="img-fluid" alt="" />
              <h1>vision</h1>
              <p>
                To build and sustain a unique law institution of international standards, capable of
                churning out a new breed of lawyers and scholars in law, kindled with fire and
                endowed with skill of lawyering to serve the nation.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="content-card">
              <img src={Mission} className="img-fluid" alt="" />
              <h1>mission</h1>
              <p>
                Mould a new breed of globally competent and socially committed lawyers, capable of
                operating legal matters effectively, by imparting value-based education.
                <br />
                Enhancing the personality by fostering moral and ethical values and to produce
                dynamic and able minded youth.
                <br />
                Encourage meticulous learning of law and its principles.
                <br />
                Link contemporary learning practices like ICT with the traditional approach of
                classroom teaching.
                <br />
                Maintain an active meaningful synergy with teaching community and students.
                <br />
                Devoted to attain the eternal principles of justice, equality and fraternity as
                enshrined in the Preamble of Indian Constitution.
                <br />
                The institutional leadership and the well-established system of governance and
                management enabled the institution to demonstrate high academic standards and
                excellence.
                <br />
                <b>“We meet here not to argue and win, but to know and be known.”</b>
              </p>
            </div>
          </div>
          <div className="col-md-3 middle-column d-flex">
            <div className="content-card motto-card">
              <img src={Motto} className="img-fluid" alt="" />
              <h1>motto</h1>
              <p>“AWAKEN BY ENLIGHTENMENT’’ -Sree Narayana Guru</p>
            </div>
          </div>
        </div>
        <hr className="hr-text" data-content="SREE NARAYANA LAW COLLEGE POOTHOTTA" />
      </div>
    </div>
  );
}

export default Information;
