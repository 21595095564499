/* eslint-disable import/prefer-default-export */

import { gql } from "@apollo/client";

const PRINCIPAL = gql`
  query GetPrincipal($status: Boolean!, $designation: [String!]!) {
    getPrincipal(status: $status, designation: $designation) {
      id
      name
      designation
      address
      contact_no
      contact_time
      profession
      profile
      profile_photo
    }
  }
`;

export default PRINCIPAL;
