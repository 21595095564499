/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MKBox from "components/MKBox";
import theme from "assets/theme";
import Presentation from "layouts/pages/presentation";
import ELearningAndQuestionPaper from "layouts/pages/elearningandquestionpapers/elearningandquestionpaper";
import Courses from "layouts/pages/elearningandquestionpapers/courses";
import QuestionPapers from "layouts/pages/elearningandquestionpapers/question-papers";
import Semesters from "layouts/pages/elearningandquestionpapers/semesters";
import WebinarVideos from "layouts/pages/webinarVideos";
import EventVideos from "layouts/pages/eventVideos";
import News from "layouts/pages/news";
import Clubs from "layouts/pages/clubs/index";
import DynamicPages from "pages/DynamicPages";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import NavbarHeader from "examples/Navbars/DefaultNavbar/NavbarHeader";
import routes from "routes";
import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";
import "assets/css/index.css";
import Ballb from "pages/Academics/courses/ba-llb";
import Unitaryllb from "pages/Academics/courses/unitary-llb";
import Coursellm from "pages/Academics/courses/llm";
import PdfReports from "pages/Iqac/Naac/pdfReports";
import FilePreview from "pages/FilePreview/"
import "assets/sass/index.scss"

export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);
  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }
      return null;
    });
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {pathname !== "/" && pathname !== "/student/sign-in" && pathname !== '/file-preview' ? (
        // <MKBox className="inner-menu"  shadow="sm">
        <NavbarHeader transparent light />
        // </MKBox>
      ) : null}
      <Routes>
        {getRoutes(routes)}
        <Route path="/" element={<Presentation />} />
        {localStorage.getItem("token") ? (
          <>
            <Route path="/e-learning-and-question-papers" element={<ELearningAndQuestionPaper />} />
            <Route path="/e-learning-and-question-papers/*" element={<Courses />} />
            <Route path="/e-learning-and-question-paper/*" element={<Semesters />} />
            <Route path="/e-learning-and-question-paper+type/*" element={<QuestionPapers />} />
          </>
        ) : (
          <Route path="/" element={<Presentation />} />
        )}
        <Route path="/file-preview" element={<FilePreview />} />
        <Route path="/news" element={<News />} />
        <Route path="/iqac/pdf-reports" element={<PdfReports />} />
        <Route path="/academics/courses/ba-llb" element={<Ballb />} />
        <Route path="/academics/courses/unitary-llb" element={<Unitaryllb />} />
        <Route path="/academics/courses/llm" element={<Coursellm />} />
        <Route path="/functional-committee/*" element={<Clubs />} />
        <Route path="/club/*" element={<Clubs />} />
        <Route path="/event-videos/*" element={<EventVideos />} />
        <Route path="/webinar-videos/*" element={<WebinarVideos />} />
        <Route path="/iqac/*" element={<DynamicPages />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      {pathname !== "/" && pathname !== "/student/sign-in" && pathname !== '/file-preview' ? (
        <MKBox pt={6} px={1} mt={6}>
          <DefaultFooter content={footerRoutes} />
        </MKBox>
      ) : null}
    </ThemeProvider>
  );
}
