/* eslint-disable import/prefer-default-export */

import { gql } from "@apollo/client";

export const NEWS = gql`
  query News($newsFilter: NewsFilterInput!) {
    news(newsFilter: $newsFilter) {
      news {
        status
        id
        title
        description
        homeNews
        createdAt
      }
      totalCount
    }
  }
`;

export const HOME_NEWS = gql`
  query GetHomeNews($newsFilter: NewsFilterInput!) {
    getHomeNews(newsFilter: $newsFilter) {
      totalCount
      news {
        id
        title
        description
      }
    }
  }
`;
