import { Link } from "react-router-dom";
import SnlcLogo from "assets/images/snlc-logo.png";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";

function DefaultNavbar({ websiteMenus }) {
  const navigate = useNavigate();
  const handleLogoClick = () => {
    navigate("/");
  };

  return (
    <div className="custom-header">
      <nav>
        <label htmlFor="drop" className="toggle">
          ≡ Menu{" "}
          <img
            onClick={handleLogoClick}
            style={{ cursor: "pointer" }}
            src={SnlcLogo}
            className="img-fluid"
            alt=""
          />
        </label>
        <input type="checkbox" id="drop" />

        <ul className="menu">
          {websiteMenus?.menus?.map((websiteMenu) => (
            <li>
              {websiteMenu?.page_url.includes("http") ? ( //Check http- If exist, redirect to another page
                <a href={websiteMenu?.page_url} target="_blank">
                  {parse(websiteMenu?.menu_title)}
                </a>
              ) : (
                <Link to={websiteMenu?.page_url}>{parse(websiteMenu?.menu_title)}</Link>
              )}
              {websiteMenu?.childMenu.length > 0 ? (
                <>
                  <input type="checkbox" id="drop-2" />
                  <ul id={`menu_list_${websiteMenu?.id}`} className={`${websiteMenu.childMenu?.length > 10 ? 'menu-auto-scroll' : ''}`}>
                    {websiteMenu?.childMenu.map((firstChild) => (
                      <li onMouseEnter={() => {
                        //Remove class when mouse hover
                        if (firstChild?.childMenu.length > 0) {
                          var element = document.getElementById(`menu_list_${websiteMenu?.id}`);
                          if (element) {
                            element.classList.remove('menu-auto-scroll');
                          }
                        }
                        //#End
                      }}
                        onMouseLeave={() => {
                          //add class when mouse hover
                          if (firstChild?.childMenu.length > 0) {
                            var element = document.getElementById(`menu_list_${websiteMenu?.id}`);
                            if (element) {
                              element.classList.add('menu-auto-scroll');
                            }
                          }
                          //#End
                        }}
                      >
                        {firstChild?.childMenu.length > 0 ? (
                          <label htmlFor="drop-3" className="toggle">
                            {parse(firstChild?.menu_title)}
                          </label>
                        ) : null}
                        {firstChild?.page_url.includes("http") ? ( //Check http- If exist, redirect to another page
                          <a href={firstChild?.page_url} target="_blank">
                            {parse(firstChild?.menu_title)}
                          </a>
                        ) : (
                          <Link to={firstChild?.page_url}>{parse(firstChild?.menu_title)}</Link>
                        )}
                        {firstChild?.childMenu.length > 0 ? (
                          <>
                            <input type="checkbox" id="drop-3" />
                            <ul className="sub-menu">
                              {firstChild?.childMenu.map((secondChild) => (
                                <li>
                                  {secondChild?.page_url.includes("http") ? ( //Check http- If exist, redirect to another page
                                    <a href={secondChild?.page_url} target="_blank">
                                      {parse(secondChild?.menu_title)}
                                    </a>
                                  ) : (
                                    <Link to={secondChild?.page_url}>
                                      {parse(secondChild?.menu_title)}
                                    </Link>
                                  )}
                                </li>
                              ))}
                            </ul>
                          </>
                        ) : null}
                      </li>
                    ))}
                  </ul>
                </>
              ) : null}
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
}
export default DefaultNavbar;
