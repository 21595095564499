/* eslint-disable import/prefer-default-export */

import { gql } from "@apollo/client";

export const GET_QUICK_LINKS = gql`
  query GetAllchildById($slug: String!) {
    getAllchildById(slug: $slug) {
      id
      category_title
      level
      slug
      lock_status
      upload_type
      document_link
      childPages {
        id
        category_title
        upload_type
        document_link
        slug
      }
      parent {
        id
        category_title
      }
    }
  }
`;

export const GET_PAGE_DATAS = gql`
query GetDynamicPageContents($slug: String!) {
  getDynamicPageContents(slug: $slug) {
    id
    category_title
    file
    document_link
    childPages {
        id
        category_title
        parent_category_id
        document_link
        file
        page_content
        page_type
        slug
        sort_order
        sub_title
        title
        upload_type
        lock_status
      childPages {
        id
        category_title
        parent_category_id
        document_link
        file
        page_content
        page_type
        slug
        sort_order
        sub_title
        title
        upload_type
        lock_status
      }
    }
    lock_status
    page_content
    page_type
    slug
    sort_order
    sub_title
    title
    upload_type
  }
}
`;

