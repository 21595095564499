/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Images
import MKButton from "components/MKButton";
import Icon from "@mui/material/Icon";
// @mui material components
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
// Material Kit 2 React components
// import MKBox from "components/MKBox";

const WelcomeCard = styled("div")({
  textAlign: "left",
  justifyContent: "left",
  width: "100%",
  fontSize: 16,
  color: "#0A0A0A",
});
const LogoutCard = styled("div")({
  textAlign: "right",
  justifyContent: "right",
  width: "100%",
  fontSize: 16,
  color: "#fcc907",
  fontWeight: "100",
});

function Logout() {
  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/";
  };
  return (
    <Grid container alignItems="center" sx={{ ml: "1rem", mr: "3rem" }}>
      <Grid item xs={8} lg={8} sx={{ mx: "auto" }}>
        <WelcomeCard>&nbsp;</WelcomeCard>
      </Grid>
      <Grid item xs={4} lg={4} sx={{ mx: "auto" }}>
        <LogoutCard>
          <MKButton color="warning" sx={{ mr: { xs: 20, lg: 5 } }} onClick={() => handleLogout()}>
            Logout<Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKButton>
        </LogoutCard>
      </Grid>
    </Grid>
  );
}

export default Logout;
