import React from "react";
import Marquee from "react-easy-marquee";
import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { BOTTOM_MENUS } from "../../../graphql/query/menu";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { HOME_NEWS } from "../../../graphql/query/news";

function VideoBanner() {
  const [bottomMenus, setBottomMenus] = useState([]);
  const [offset] = React.useState(0);
  const [getNewsDatas, setNewsDatas] = React.useState([]);
  const { loading, error, data } = useQuery(BOTTOM_MENUS, {});

  useEffect(() => {
    if (loading === false) {
      if (error) {
        setBottomMenus([]);
      } else {
        setBottomMenus(data.getBottomMenus);
      }
    }
  }, [data, loading, error]);
  const {
    loading: newsloading,
    error: newserror,
    data: newsdata,
  } = useQuery(HOME_NEWS, {
    variables: {
      newsFilter: {
        orderColumn: "id",
        status: true,
        limit: 100,
        offset,
        orderDirection: "desc",
      },
    },
  });
  React.useEffect(() => {
    if (newsloading === false) {
      if (newserror) {
        setNewsDatas([]);
      } else {
        setNewsDatas(newsdata.getHomeNews.news);
      }
    }
  }, [newsdata, newsloading, newserror]);
  console.log("newsData", getNewsDatas);
  return (
    <div className="bg-video-wrap">
      <video
        src="https://snlc-files.s3.ap-south-1.amazonaws.com/videos/Sn+Law+College+Abc+1.webm"
        loop="true"
        muted="false"
        autoPlay="true"
      ></video>
      {/* <div className="overlay"></div> */}
      <div className="row  banner-links">
        <div className="col-md-4">
          <div className="row">
            {bottomMenus?.map((bottomMenu) => (
              <div className="col-md-4">
                {bottomMenu?.page_url.includes("http") ? ( //Check http- If exist, redirect to another page
                  <a href={bottomMenu?.page_url} target="_blank">
                    {parse(bottomMenu?.menu_title)}
                  </a>
                ) : (
                  <Link to={bottomMenu?.page_url}>{parse(bottomMenu?.menu_title)}</Link>
                )}
              </div>
            ))}
            {/* <div className="col-md-4">
              <a href="">online ERP registration</a>
              <a href="">Admission 2024-25</a>
            </div>
            <div className="col-md-4">
              <a href="">online fee payment</a>
            </div> */}
          </div>
        </div>
        <div className="col-md-8">
          <div className="announcement-wrapper">
            <div className="header">
              <h2>Announcements</h2>
            </div>
            <div className="announcement-container">
              {getNewsDatas?.length >= 1 ? (
                <Marquee
                  duration={2000}
                  height="240px"
                  width="100%"
                  axis="Y"
                  align="center"
                  pauseOnHover={true}
                  reverse={true}
                >
                  {getNewsDatas?.map((news) => (
                    <div className="scroll-text">
                      <p>{news.title}</p>
                      <Link to="/news">...Read More</Link>
                    </div>
                  ))}
                </Marquee>
              ) : (
                getNewsDatas.map((news) => (
                  <div className="scroll-text">
                    <p>{news.title}</p>
                    <Link to="/news">...Read More</Link>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoBanner;
