/* eslint-disable import/prefer-default-export */

import { gql } from "@apollo/client";

export const CONTACT_US = gql`
  mutation ContactUs($contactInput: ContactInput!) {
    contactUs(ContactInput: $contactInput)
  }
`;

export const GRIEVANCE_REDRESSAL = gql`
  mutation GrievanceRedressal($grievanceInput: GrievanceInput!) {
    grievanceRedressal(GrievanceInput: $grievanceInput)
  }
`;
