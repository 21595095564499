/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import Card from "@mui/material/Card";
// prop-types is library for typechecking of props
import PropTypes from "prop-types";
import Moment from "react-moment";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function DateCard({ image, name, date }) {
  return (
    <Card sx={{ margin: "10px" }}>
      <MKBox position="relative" borderRadius="lg" mx={2} mt={0}>
        <MKBox
          component="img"
          src={image}
          alt={name}
          borderRadius="lg"
          width="100%"
          position="relative"
          zIndex={1}
          className="events-img-transition"
        />
        <MKBox
          borderRadius="lg"
          width="100%"
          height="100%"
          position="absolute"
          left={0}
          top={0}
          sx={{
            backgroundImage: `url(${image})`,
            transform: "scale(0.94)",
            filter: "blur(12px)",
            backgroundSize: "cover",
          }}
        />
      </MKBox>
      <MKBox p={1} mt={-1} textAlign="center" alignItems="center" sx={{ minHeight: "80px" }}>
        <MKTypography display="inline" variant="h6" textTransform="capitalize" fontWeight="bold">
          {name}
        </MKTypography>
        <div className="events-date">
          <i className="fas fa-calendar" mr={4} /> <Moment format="D-MMM-YYYY">{date}</Moment>
        </div>
      </MKBox>
    </Card>
  );
}

// Typechecking props for the DefaultReviewCard
DateCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

export default DateCard;
