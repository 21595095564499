/* eslint-disable import/prefer-default-export */

import { gql } from "@apollo/client";

export const ADVISORY_BOARD = gql`
  query advisoryBoard($advisoryBoardFilter: AdvisoryFilterInput!) {
    advisoryBoard(advisoryBoardFilter: $advisoryBoardFilter) {
      totalCount
      advisoryBoard {
        id
        name
        profile
        profilePicture
        sortOrder
        createdBy
        createdAt
        status
      }
    }
  }
`;
