/* eslint-disable import/prefer-default-export */

import { gql } from "@apollo/client";

export const GALLERIES = gql`
  query GetGalleries($filterInput: FilterGalleryInput!) {
    getGalleries(filterInput: $filterInput) {
      totalCount
      galleries {
        id
        title
        thumbnail
        created_by
        status
        galleryImages {
          id
          img_link
          galleryId
        }
      }
    }
  }
`;
export const HOME_GALLERIES = gql`
  query GetHomeGalleries {
    getHomeGalleries {
      galleries {
        id
        status
        title
        thumbnail
        set_as_home
        sort_date
        created_by
        galleryImages {
          id
          img_link
          galleryId
        }
      }
      totalCount
    }
  }
`;
