import React from "react";
import { useQuery } from "@apollo/client";
import { CONGRATULATIONS } from "../../../graphql/query/congratulations";
import Slider from "react-slick";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

function Congratulations() {
  const [offset] = React.useState(0);
  const [congratulationsDatas, setCongratulationsDatas] = React.useState([]);
  const { loading, error, data } = useQuery(CONGRATULATIONS, {
    variables: {
      congratulationsFilter: {
        offset,
        limit: 60000,
        status: true,
        setAsHome: true,
        orderColumn: "id",
        orderDirection: "desc",
      },
    },
  });

  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows:false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows:false,
        },
      },
    ],
  };

  React.useEffect(() => {
    if (!loading) {
      if (error) {
        setCongratulationsDatas([]);
      } else if (data) {
        setCongratulationsDatas(data.congratulations.congratulations);
      }
    }
  }, [data, loading, error]);
  return (
    <div>
      <div className="congrats-slider-v2">
        <div className="container">
          <div className="row header-area">
            <div className="col-md-4">
              <h2>congratulations</h2>
            </div>
            <div className="col-md-8">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="915"
                height="2"
                viewBox="0 0 915 2"
                fill="none"
              >
                <path d="M0 1L915 0.99993" stroke="#F7E763" />
              </svg>
            </div>
          </div>
        </div>
        {congratulationsDatas.length > 0 ? (
          <Container sx={{ mt: 0.5, mb: 2 }}>
            {congratulationsDatas.length > 3 ? (
              <Slider {...settings}>
                {congratulationsDatas.map((congratulations) => (
                  <div key={congratulations.id}>
                    <Grid display="flex" justifyContent="center">
                      <img className="congrats-slider" src={congratulations.image} alt="Logo" />
                    </Grid>
                  </div>
                ))}
              </Slider>
            ) : (
              <Grid container spacing={3}>
                {congratulationsDatas.map((congratulations) => (
                  <Grid item xs={3} key={congratulations.id}>
                    <img className="congrats-slider" src={congratulations.image} alt="Logo" />
                  </Grid>
                ))}
              </Grid>
            )}
          </Container>
        ) : null}
      </div>
    </div>
  );
}

export default Congratulations;
