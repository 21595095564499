// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { withStyles } from "@material-ui/core/styles";
import TablePagination from "@mui/material/TablePagination";
import Card from "@mui/material/Card";
import { useLocation } from "react-router-dom";
// Material Kit 2 React components
import loader from "assets/images/loader.gif";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import WebinarCard from "examples/Cards/WebinarCard";
import VideoCard from "examples/Cards/VideoCard";
// Images
import innerCover from "assets/images/banners/webinars.webp";
import { useQuery } from "@apollo/client";
import * as React from "react";
import { WEBINAR } from "../../graphql/query/webinar";
import PPTCard from "examples/Cards/PPTCard";

const styles = (theme) => ({
  card: {
    display: "flex",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 151,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  playIcon: {
    height: 38,
    width: 38,
  },
});
function Webinar() {
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [totalCount, setTotalCount] = React.useState(0);
  const [webinarDatas, setWebinarDatas] = React.useState([]);

  const [offset, setOffset] = React.useState(0);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");
  // console.log("Type:", type);
  const { loading, error, data } = useQuery(WEBINAR, {
    variables: {
      webinarFilter: {
        offset,
        limit: rowsPerPage,
        status: true,
        orderColumn: "date",
        orderDirection: "desc",
        content_type: type,
      },
    },
  });

  React.useEffect(() => {
    if (loading === false) {
      if (error) {
        setWebinarDatas([]);
      } else {
        setWebinarDatas(data.webinar.webinar);

        setTotalCount(data.webinar.totalCount);
      }
    }
  }, [data, loading, error]);

  console.log("webinarDatas:", webinarDatas);
  const handleChangePage = (event, newPage) => {
    setOffset(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setOffset(0);
  };
  return (
    <>
      <MKBox
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${innerCover})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
        className="innerCover"
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              {type}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      {loading ? (
        <img alt="loader" src={loader} className="loader" />
      ) : (
        <Card
          sx={{
            p: { xs: 4, lg: 8 },
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <Grid container spacing={4}>
            {(type === "Webinar" || type === "Workshop" || type === "Seminar" || type==="Certificate Course" || type==="Orientation Programme" || type==='Ethnic Club Reports') &&
              webinarDatas.map((webinar) => (
                <Grid item xs={12} md={3} lg={3} xl={3}>
                  <WebinarCard
                    image={webinar.thumbnail_image}
                    action={{
                      type: "internal",
                      route: `/webinar-videos/${webinar.id}`,
                      name: `${webinar.title}`,
                    }}
                    date={webinar.date}
                  />
                </Grid>
              ))}
            {(type === "Video Lecture" || type === "E-Contents By Student") &&
              type !== "Webinar" &&
              type !== "Workshop" &&
              type !== "Seminar" &&
              type !== "Power Point" &&
              webinarDatas.map((webinar) => (
                <Grid item xs={12} md={3} lg={3} key={webinar.id}>
                  <VideoCard video={webinar?.webinarVideos[0]?.video_link} title={webinar.title} />
                </Grid>
              ))}

            {type === "Power Point" &&
              webinarDatas.map((webinar) => (
                <Grid item xs={12} md={3} lg={3}>
                  <PPTCard
                    image={webinar.thumbnail_image}
                    action={{
                      type: "internal",
                      route: ``,
                      name: `${webinar.title}`,
                    }}
                    pptUrl={webinar.upload_ppt}
                  />
                </Grid>
              ))}
          </Grid>
          {webinarDatas.length ? (
            <TablePagination
              rowsPerPageOptions={[8, 16, 24, 32, 40]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={offset}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : (
            <Grid container spacing={2}>
              <Grid item md={12} mt={3} sx={{ display: "flex", justifyContent: "center" }}>
                <MKTypography variant="h3">No Result Found</MKTypography>
              </Grid>
            </Grid>
          )}
        </Card>
      )}
    </>
  );
}

export default withStyles(styles)(Webinar);
