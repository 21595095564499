/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
// import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

// Material Kit 2 React examples
import Logout from "pages/common/Logout";

// Routes
import TablePagination from "@mui/material/TablePagination";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
// import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
// import InputBase from "@mui/material/InputBase";
import Tooltip from "@mui/material/Tooltip";
// import SearchIcon from "@mui/icons-material/Search";
import * as React from "react";
import * as moment from "moment";
// Material Kit 2 React examples
import loader from "assets/images/loader.gif";
import { useQuery } from "@apollo/client";
import MKTypography from "components/MKTypography";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import bgImage from "assets/images/bg-college.webp";
import Card from "@mui/material/Card";

import { QUESTION_PAPER } from "../../../graphql/query/question-papers";

// const Search = styled("div")(({ theme }) => ({
//   position: "relative",
//   border: "1px solid #ced4da",
//   borderRadius: "10px",
//   height: "40px",
//   backgroundColor: alpha(theme.palette.common.white, 0.15),
//   "&:hover": {
//     backgroundColor: alpha(theme.palette.common.white, 0.25),
//   },
//   marginLeft: 0,
//   width: "100%",
//   [theme.breakpoints.up("sm")]: {
//     marginLeft: theme.spacing(1),
//     width: "auto",
//   },
// }));

// const SearchIconWrapper = styled("div")(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: "100%",
//   position: "absolute",
//   pointerEvents: "none",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
// }));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: "inherit",
//   "& .MuiInputBase-input": {
//     padding: theme.spacing(1, 1, 1, 0),
//     // vertical padding + font size from searchIcon
//     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//     transition: theme.transitions.create("width"),
//     width: "100%",
//     [theme.breakpoints.up("sm")]: {
//       width: "12ch",
//       "&:focus": {
//         width: "20ch",
//       },
//     },
//   },
// }));

function QuestionPapers() {
  const [typeName] = React.useState(
    window.location.pathname.split("/")[2].replace("-", " ").toString()
  );
  const [singleCourse] = React.useState(
    window.location.pathname.split("/")[3].replace(/%20/g, " ").toString()
  );
  const [singleSemester] = React.useState(window.location.pathname.split("/")[4].toString());
  const [offset, setOffset] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(12);
  const [totalCount, setTotalCount] = React.useState(0);
  const [questionPaperData, setQuestionPaperData] = React.useState([]);
  const [valueDate, setValue] = React.useState(null);
  // const [searchData, setSearchData] = React.useState("");
  const [monthData, setMonthData] = React.useState(null);
  const { loading, error, data } = useQuery(QUESTION_PAPER, {
    variables: {
      questionFilter: {
        search: null,
        orderColumn: "id",
        limit: rowsPerPage,
        offset,
        orderDirection: "asc",
        type: typeName,
        semester: singleSemester,
        course: singleCourse,
        subjectId: null,
        year: valueDate,
        month: monthData,
        status: true,
      },
    },
  });

  React.useEffect(() => {
    if (loading === false) {
      if (error) {
        setQuestionPaperData([]);
      } else {
        setQuestionPaperData(data.questionPapers.questionPaper);
        setTotalCount(data.questionPapers.totalCount);
      }
    }
  }, [data, loading, error]);

  const handleChangePage = (event, newPage) => {
    setOffset(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setOffset(0);
  };
  // const handleSearch = (e) => {
  //   setSearchData(e.target.value);
  // };
  const handleMonthChange = (e) => {
    setMonthData(e.target.value);
  };
  const handleValueData = (newValue) => {
    setValue(moment(newValue).format("YYYY"));
  };
  const handleReset = () => {
    setValue(null);
    // setSearchData("");
    setMonthData(null);
  };
  return (
    <>
      <MKBox
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
        className="innerCover"
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Downloads ({singleCourse} | Semester {singleSemester})
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Logout />
        <MKBox component="section" py={{ xs: 3, md: 12 }}>
          <Container>
            <Box sx={{ flexGrow: 1 }} mb={3}>
              <AppBar position="static">
                <Toolbar>
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    sx={{ mr: 2 }}
                  />

                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                      {/* <Search>
                        <SearchIconWrapper>
                          <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                          placeholder="Search..."
                          value={searchData}
                          onChange={(e) => handleSearch(e)}
                          inputProps={{ "aria-label": "search" }}
                        />
                      </Search> */}
                    </Grid>
                    <Grid item xs={12} lg={3} textAlign="center">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Month</InputLabel>
                        <Select
                          sx={{ minHeight: 40 }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={monthData}
                          fullWidth
                          label="Age"
                          onChange={(e) => handleMonthChange(e)}
                        >
                          <MenuItem value="January">January</MenuItem>
                          <MenuItem value="February">February</MenuItem>
                          <MenuItem value="March">March</MenuItem>
                          <MenuItem value="April">April</MenuItem>
                          <MenuItem value="May">May</MenuItem>
                          <MenuItem value="June">June</MenuItem>
                          <MenuItem value="July">July</MenuItem>
                          <MenuItem value="August">August</MenuItem>
                          <MenuItem value="September">September</MenuItem>
                          <MenuItem value="October">October</MenuItem>
                          <MenuItem value="November">November</MenuItem>
                          <MenuItem value="December">December</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          views={["year"]}
                          maxDate={new Date()}
                          label="Year only"
                          value={valueDate}
                          name="year"
                          onChange={(newValue) => {
                            handleValueData(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              style={{ width: "100%" }}
                              size="small"
                              {...params}
                              helperText={null}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} lg={1}>
                      <MKBox
                        width="2rem"
                        height="2rem"
                        variant="gradient"
                        bgColor="info"
                        color="white"
                        coloredShadow="info"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="xl"
                      >
                        <Tooltip title="Display all">
                          <i onClick={handleReset} className="fa fa-refresh" aria-hidden="true" />
                        </Tooltip>
                      </MKBox>
                    </Grid>
                  </Grid>
                </Toolbar>
              </AppBar>
            </Box>

            <Grid container spacing={3} alignItems="center">
              {loading ? (
                <img alt="loader" src={loader} className="loader" />
              ) : (
                <>
                  {questionPaperData.length === 0 ? (
                    <Grid container spacing={2}>
                      <Grid item md={12} mt={3} sx={{ display: "flex", justifyContent: "center" }}>
                        <MKTypography variant="h3">No Result Found</MKTypography>
                      </Grid>
                    </Grid>
                  ) : (
                    questionPaperData.map((ques) => (
                      <Grid item xs={12} lg={4}>
                        <div className="questions-card">
                          <MKTypography
                            component="a"
                            href={'/file-preview?file=' + ques.file}
                            rel="noopener noreferrer"
                            target="_blank"
                            aria-label="File"
                            variant="body2"
                            color="info"
                            fontWeight="regular"
                            sx={{
                              display: "flex",
                              alignItems: "center",

                              "& .material-icons-round": {
                                fontSize: "1.125rem",
                                transform: "translateX(3px)",
                                transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                              },

                              "&:hover .material-icons-round, &:focus .material-icons-round": {
                                transform: "translateX(6px)",
                              },
                            }}
                          >
                            <MKBox display="flex" alignItems="center" p={2}>
                              <MKBox
                                width="2rem"
                                height="2rem"
                                variant="gradient"
                                bgColor="info"
                                color="white"
                                coloredShadow="info"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                borderRadius="xl"
                              >
                                <FileDownloadIcon />
                              </MKBox>
                              <MKTypography
                                variant="body2"
                                color="white"
                                fontWeight="bold"
                                pl={2}
                                sx={{ textAlign: "left" }}
                              >
                                {ques.subjects.code} - {ques.subjects.name}
                                <br />
                                {/* <a
                                href={ques.file}
                                rel="noopener noreferrer"
                                target="_blank"
                                aria-label="File"
                              >
                                {ques.file}
                              </a> */}
                              </MKTypography>
                            </MKBox>
                          </MKTypography>
                        </div>
                      </Grid>
                    ))
                  )}
                </>
              )}
            </Grid>
          </Container>
        </MKBox>
        <MKBox mr={12}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 35, 50]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={offset}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </MKBox>
      </Card>
    </>
  );
}

export default QuestionPapers;
