import * as React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_QUICK_LINKS } from "graphql/query/dynamicPages";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

function QuickLinks() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const [quickLinks, setQuickLinks] = React.useState([]);
  const [currentSlug, setCurrentSlug] = React.useState(null);

  const {
    loading,
    error,
    data,
    refetch: oneRefetch,
  } = useQuery(GET_QUICK_LINKS, {
    variables: {
      slug: null,
    },
  });

  React.useEffect(() => {
    if (loading === false) {
      if (error) {
        // setQuickLinks([]);
      } else {
        setQuickLinks(data?.getAllchildById);
      }
    }
  }, [data, loading, error]);

  const location = useLocation();

  React.useEffect(() => {
    const { pathname } = location;
    const splitArray = pathname.split("slug=");
    setCurrentSlug(splitArray[1]);
    oneRefetch({
      slug: splitArray[1],
    });
  }, [location.pathname]);

  return (
    quickLinks.length > 0 ?
      <div className="col-md-3">
        <div className="right-side-bar iqacQuicklinks">
          {loading === false ? (
            <>
              <h3 className="iqac-header">
                {quickLinks?.length > 0 ? quickLinks[0]?.parent?.category_title : "SNLC Poothotta"}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="285"
                  height="1"
                  viewBox="0 0 285 1"
                  fill="none"
                >
                  <path d="M0 1H285" stroke="#35BBED" />
                </svg>
              </h3>

              <ul className="iqac-right-sidebar-links">
                {quickLinks?.map((quickLink) => (
                  <>
                    {quickLink?.level === 0 ? (
                      <li>
                        <Link
                          exact
                          to={quickLink?.level === 0 && (quickLink?.lock_status === false || quickLink?.lock_status === null) ? quickLink?.upload_type === 'Redirect to another page' ? quickLink?.document_link : `/iqac/slug=${quickLink?.slug}` : "#"}
                          className={currentSlug === quickLink?.slug ? "quick-link-active" : ""}
                        >
                          {quickLink?.category_title}
                        </Link>
                      </li>
                    ) : null}
                    {quickLink?.level === 1 ? (
                      <div className="iqac-expand-menu">
                        <button onClick={toggleMenu}>
                          {quickLink?.category_title}
                          {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </button>
                        {isOpen && (
                          <ul>
                            {quickLink?.childPages?.map((childLink) => (
                              <li>
                                <Link
                                  exact
                                  to={childLink?.upload_type === 'Redirect to another page' ? childLink?.document_link : `/iqac/slug=${childLink?.slug}`}
                                  className={
                                    currentSlug === childLink?.slug ? "quick-link-active" : ""
                                  }
                                >
                                  {childLink?.category_title}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    ) : null}
                  </>
                ))}
              </ul>
            </>
          ) : null}
        </div>
      </div> : null
  );
}
export default QuickLinks;
