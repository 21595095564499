/* eslint-disable import/prefer-default-export */

import { gql } from "@apollo/client";

export const ACHIEVEMENTS = gql`
  query Achievements($achievementsFilter: AchievementsFilterInput!) {
    achievements(achievementsFilter: $achievementsFilter) {
      totalCount
      congratulations {
        id
        title
        type
        image
        status
        createdBy
        createdAt
      }
    }
  }
`;
