/* eslint-disable import/prefer-default-export */

import { gql } from "@apollo/client";

const FACULTY_LIST = gql`
  query Faculty($facultyFilter: FacultyFilterInput!) {
    faculty(facultyFilter: $facultyFilter) {
      totalCount
      faculty {
        id
        name
        designation
        qualification
        contactNo
        email
        profile
        profile_photo

        sortOrder
        status
        teacherDocuments {
          id
          file_url
          document_type
          title
        }
      }
    }
  }
`;

export default FACULTY_LIST;
