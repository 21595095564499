/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import * as React from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useQuery } from "@apollo/client";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { useLocation } from "react-router-dom";

// Material Kit 2 React examples

// About Us page sections

// Images
import innerCover from "assets/images/banners/banner-committees.webp";
import ClubInformation from "./ClubInformation";
import { CLUB, ONE_CLUB } from "../../graphql/query/club";

function Club() {
  const [clubName] = React.useState(window.location.pathname.split("/")[2]);
  const [clubDatas, setClubDatas] = React.useState([]);
  const [bannerHeading, setBannerHeading] = React.useState(null);
  const [logoUrl, setLogoUrl] = React.useState(null);
  const [dataType, setDataType] = React.useState(null);
  const { loading, error, data, refetch } = useQuery(CLUB, {
    variables: {
      type: parseFloat(clubName),
      clubFilter: {
        orderColumn: "date",
        orderDirection: "desc",
        status: true,
        limit: 400000,
      },
    },
  });

  const {
    loading: oneLoading,
    error: oneErr,
    data: oneData,
    refetch: oneRefetch,
  } = useQuery(ONE_CLUB, {
    variables: {
      oneCommitteeId: parseFloat(clubName),
    },
  });

  const location = useLocation();
  React.useEffect(() => {
    const { pathname } = location;
    const splitArray = pathname.split("/");
    setDataType(splitArray[1]);
    // setBannerHeading(splitArray[2].replaceAll("%20", " "));
    refetch({
      type: parseFloat(splitArray[2]),
      clubFilter: {
        orderColumn: "date",
        orderDirection: "desc",
        status: true,
        limit: 400000,
      },
    });
    oneRefetch({
      oneCommitteeId: parseFloat(splitArray[2]),
    });
  }, [location.pathname]);

  React.useEffect(() => {
    if (loading === false) {
      if (error) {
        setClubDatas([]);
      } else {
        setClubDatas(data.clubType.club);
      }
    }
  }, [data, loading, error]);

  React.useEffect(() => {
    if (oneLoading === false) {
      if (oneErr) {
        setClubDatas([]);
      } else {
        setLogoUrl(oneData.oneCommittee.logo);
        setBannerHeading(oneData.oneCommittee.committeeName);
      }
    }
  }, [oneData, oneLoading, oneErr]);

  return (
    <>
      <MKBox
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${innerCover})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
        className="innerCover"
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              {bannerHeading}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>

      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <ClubInformation clubDatas={clubDatas} logoUrl={logoUrl} dataType={dataType} />
      </Card>
    </>
  );
}

export default Club;
