/* eslint-disable import/prefer-default-export */

import { gql } from "@apollo/client";

const ADMINISTRATIVE_STAFF = gql`
  query GetAdministrativeStaff($status: Boolean!, $type: String!) {
    getAdministrativeStaff(status: $status, type: $type) {
      id
      name
      designation
      type
      address
      contact_no
      contact_time
      profession
      profile
      profile_photo
      sort_order
      status
    }
  }
`;

export default ADMINISTRATIVE_STAFF;
