/* eslint-disable import/prefer-default-export */

import { gql } from "@apollo/client";

export const EVENTS = gql`
  query Events($eventsFilter: EventsFilterInput!) {
    events(eventsFilter: $eventsFilter) {
      totalCount
      events {
        id
        event_name
        date
        time
        description
        thumbnail_image
        vedio_link
        status
        createdBy
        createdAt
      }
    }
  }
`;

export const ONE_EVENT = gql`
  query OneEvent($oneEventId: Float!) {
    oneEvent(id: $oneEventId) {
      id
      event_name
      date
      time
      description
      thumbnail_image
      vedio_link
      createdBy
      createdAt
      status
    }
  }
`;

export const RECENT_EVENTS = gql`
  query RecentEvents($eventsFilter: EventsFilterInput!) {
    recentEvents(eventsFilter: $eventsFilter) {
      totalCount
      events {
        event_name
        date
        time
        description
        thumbnail_image
        vedio_link
        status
        id
      }
    }
  }
`;

export const UPCOMING_EVENTS = gql`
  query UpcomingEvents($eventsFilter: EventsFilterInput!) {
    upcomingEvents(eventsFilter: $eventsFilter) {
      totalCount
      events {
        id
        event_name
        date
        time
        description
        thumbnail_image
        vedio_link
        status
      }
    }
  }
`;
