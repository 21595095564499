/* eslint-disable import/prefer-default-export */

import { gql } from "@apollo/client";

export const SUBJECTS = gql`
  query Subjects {
    getUniqueSubjects {
      Subjects {
        course
      }
    }
  }
`;
export const SEMESTERS = gql`
  query GetUniqueSemesters($semesterInput: SemesterInput!) {
    getUniqueSemesters(SemesterInput: $semesterInput) {
      Subjects {
        semester
      }
    }
  }
`;
