// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import loader from "assets/images/loader.gif";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import TablePagination from "@mui/material/TablePagination";
// import ManagementCard from "examples/Cards/ManagementCard";
import { useState } from "react";
// Images

import innerCover from "assets/images/banners/banner-management.webp";
import { useQuery } from "@apollo/client";
import * as React from "react";
import { ADVISORY_BOARD } from "../../graphql/query/advisoryBoard";
import AdvisoryDescription from "./AdvisoryDescription";

function AdvisoryBoard() {
  // const [offset, setOffset] = React.useState(0);
  const [advisoryBoardDatas, setAdvisoryBoardDatas] = React.useState([]);
  const [showModal, setShowModal] = useState(false);
  const [managementDetails, setManagementDetails] = useState(null);
  // const [rowsPerPage, setRowsPerPage] = React.useState(1000);
  // const [totalCount, setTotalCount] = React.useState(0);
  const { loading, error, data } = useQuery(ADVISORY_BOARD, {
    variables: {
      advisoryBoardFilter: {
        orderColumn: "sortOrder",
        status: true,
        limit: 10000,
        offset: 0,
        sortOrder: "Asc",
      },
    },
  });

  React.useEffect(() => {
    if (loading === false) {
      if (error) {
        setAdvisoryBoardDatas([]);
      } else {
        setAdvisoryBoardDatas(data.advisoryBoard.advisoryBoard);
        // setTotalCount(data.advisoryBoard.totalCount);
      }
    }
  }, [data, loading, error]);

  const showManagementDetails = (management) => {
    setManagementDetails(management);
    setShowModal(true);
  };
  const closeDeleteModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <MKBox
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${innerCover})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
        className="innerCover"
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Governing Council
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox component="section" py={12}>
          <Container>
            {loading ? (
              <img alt="loader" src={loader} className="loader" />
            ) : (
              <Grid container spacing={3}>
                {advisoryBoardDatas.map((advisoryBoard) => (
                  <Grid item xs={12} lg={4} onClick={() => showManagementDetails(advisoryBoard)}>
                    <MKBox mb={{ xs: 2, lg: 2 }}>
                      <Card className="card-1">
                        <Grid container alignItems="center">
                          <Grid item xs={4} md={4} lg={4}>
                            <MKBox width="100%" pt={2} pb={2} px={2}>
                              <MKBox
                                component="img"
                                src={advisoryBoard.profilePicture || 'https://snlc-files.s3.amazonaws.com/naacReports/Test-Image.jpg'}
                                alt="Img"
                                width="100%"
                                borderRadius="md"
                                shadow="lg"
                                className="staffImg"
                              />
                            </MKBox>
                          </Grid>
                          <Grid item xs={1} md={1} lg={1}>
                            &nbsp;
                          </Grid>
                          <Grid item xs={7} md={7} lg={7} sx={{ my: "auto" }}>
                            <MKBox
                              pt={{ xs: 1, lg: 2.5 }}
                              pb={2.5}
                              pr={4}
                              pl={{ xs: 4, lg: 1 }}
                              lineHeight={1}
                            >
                              <MKTypography variant="h6">{advisoryBoard.name}</MKTypography>
                            </MKBox>
                          </Grid>
                        </Grid>
                      </Card>
                    </MKBox>
                  </Grid>
                  // <Grid item xs={12} lg={4} onClick={() => showManagementDetails(advisoryBoard)}>
                  //   <MKBox mb={1}>
                  //     <ManagementCard
                  //       image={advisoryBoard.profilePicture}
                  //       name={advisoryBoard.name}
                  //       position={advisoryBoard.sortOrder}
                  //       description={advisoryBoard.profile}
                  //     />
                  //   </MKBox>
                  // </Grid>
                ))}
                <AdvisoryDescription
                  showModal={showModal}
                  managementDetails={managementDetails}
                  closeDeleteModal={closeDeleteModal}
                />
              </Grid>
            )}
          </Container>
          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25, 35, 50]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={offset}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </MKBox>
      </Card>
    </>
  );
}

export default AdvisoryBoard;
