/* eslint-disable import/prefer-default-export */

import { gql } from "@apollo/client";

export const CLUB = gql`
  query ClubType($type: Float!, $clubFilter: ClubFilterInput!) {
    clubType(type: $type, clubFilter: $clubFilter) {
      totalCount
      club {
        id
        title
        description

        vedio_link
        status
        createdAt
        createdBy
        clubImages {
          id
          img_link
          clubId
        }
        functionalCommittee {
          id
          committeeName
          type
          logo
          status
        }
        type
      }
    }
  }
`;

export const ONE_CLUB = gql`
  query OneCommittee($oneCommitteeId: Float!) {
    oneCommittee(id: $oneCommitteeId) {
      id
      committeeName
      type
      logo
      createdAt
    }
  }
`;
