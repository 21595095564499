/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples

// Images
// import llb from "assets/images/graduation-hat.png";

function Courses() {
  return (
    <MKBox component="section" variant="gradient" position="relative">
      <Grid container spacing={3}>
        <Grid item xs={12} lg={4}>
          <Link className="footer-links" to="/academics/courses/ba-llb">
            <div className="course-card">
              <div className="row  d-flex align-items-center">
                <div className="col-md-12 text-center">
                  <h4 className="course-desktop">
                    B.A LL.B (Honours)
                    <br /> 5 Year Course
                  </h4>
                  <h4 className="course-mob">B.A LL.B (Honours) 5 Year Course</h4>
                </div>
              </div>
            </div>
          </Link>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Link className="footer-links" to="/academics/courses/ba-llb">
            <div className="course-card">
              <div className="row  d-flex align-items-center">
                <div className="col-md-12 text-center">
                  <h4 className="course-desktop">
                    BBA LL.B (Honours)
                    <br /> 5 Year Course
                  </h4>
                  <h4 className="course-mob">BBA LL.B (Honours) 5 Year Course</h4>
                </div>
              </div>
            </div>
          </Link>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Link className="footer-links" to="/academics/courses/ba-llb">
            <div className="course-card">
              <div className="row  d-flex align-items-center">
                <div className="col-md-12 text-center">
                  <h4 className="course-desktop">
                    B.COM LL.B (Honours)
                    <br /> 5 Year Course
                  </h4>
                  <h4 className="course-mob">
                    B.COM LL.B (Honours)
                    <br /> 5 Year Course
                  </h4>
                </div>
              </div>
            </div>
          </Link>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Link className="footer-links" to="/academics/courses/unitary-llb">
            <div className="course-card">
              <div className="row  d-flex align-items-center">
                <div className="col-md-12 text-center">
                  <h4 className="course-desktop">
                    UNITARY LL.B
                    <br /> 3 Year Degree Course
                  </h4>
                  <h4 className="course-mob">UNITARY LL.B 3 Year Degree Course</h4>
                </div>
              </div>
            </div>
          </Link>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Link className="footer-links" to="/academics/courses/llm">
            <div className="course-card">
              <div className="row  d-flex align-items-center">
                <div className="col-md-12 text-center">
                  <h4 className="course-desktop">
                    LL.M (CRIMINAL LAW)
                    <br /> 2 Year Course
                  </h4>
                  <h4 className="course-mob">LL.M (CRIMINAL LAW) 2 Year Course</h4>
                </div>
              </div>
            </div>
          </Link>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Link className="footer-links" to="/academics/courses/llm">
            <div className="course-card">
              <div className="row  d-flex align-items-center">
                <div className="col-md-12 text-center">
                  <h4 className="course-desktop">
                    LL.M (COMMERCIAL LAW)
                    <br /> 2 Year Course
                  </h4>
                  <h4 className="course-mob">LL.M (COMMERCIAL LAW) 2 Year Course</h4>
                </div>
              </div>
            </div>
          </Link>
        </Grid>
      </Grid>
    </MKBox>
  );
}

export default Courses;
